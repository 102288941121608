(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File === 'function' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[94m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.fA.Q === region.dN.Q)
	{
		return 'on line ' + region.fA.Q;
	}
	return 'on lines ' + region.fA.Q + ' through ' + region.dN.Q;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return word
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.es,
		impl.f1,
		impl.fJ,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_dispatchEffects(managers, result.b, subscriptions(model));
	}

	_Platform_dispatchEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				p: bag.n,
				q: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.q)
		{
			x = temp.p(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		r: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		r: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		bc: func(record.bc),
		bF: record.bF,
		bp: record.bp
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.bc;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.bF;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.bp) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.es,
		impl.f1,
		impl.fJ,
		function(sendToApp, initialModel) {
			var view = impl.f4;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.es,
		impl.f1,
		impl.fJ,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.bD && impl.bD(sendToApp)
			var view = impl.f4;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.bV);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.cU) && (_VirtualDom_doc.title = title = doc.cU);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.e2;
	var onUrlRequest = impl.e3;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		bD: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.cA === next.cA
							&& curr.ce === next.ce
							&& curr.cv.a === next.cv.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		es: function(flags)
		{
			return A3(impl.es, flags, _Browser_getUrl(), key);
		},
		f4: impl.f4,
		f1: impl.f1,
		fJ: impl.fJ
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { el: 'hidden', df: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { el: 'mozHidden', df: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { el: 'msHidden', df: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { el: 'webkitHidden', df: 'webkitvisibilitychange' }
		: { el: 'hidden', df: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		cJ: _Browser_getScene(),
		f5: {
			cX: _Browser_window.pageXOffset,
			cY: _Browser_window.pageYOffset,
			f6: _Browser_doc.documentElement.clientWidth,
			ej: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		f6: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		ej: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			cJ: {
				f6: node.scrollWidth,
				ej: node.scrollHeight
			},
			f5: {
				cX: node.scrollLeft,
				cY: node.scrollTop,
				f6: node.clientWidth,
				ej: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			cJ: _Browser_getScene(),
			f5: {
				cX: x,
				cY: y,
				f6: _Browser_doc.documentElement.clientWidth,
				ej: _Browser_doc.documentElement.clientHeight
			},
			dM: {
				cX: x + rect.left,
				cY: y + rect.top,
				f6: rect.width,
				ej: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.af.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.af.b, xhr)); });
		$elm$core$Maybe$isJust(request.cV) && _Http_track(router, xhr, request.cV.a);

		try {
			xhr.open(request.be, request.aG, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.aG));
		}

		_Http_configureRequest(xhr, request);

		request.bV.a && xhr.setRequestHeader('Content-Type', request.bV.a);
		xhr.send(request.bV.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.a6; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.bL.a || 0;
	xhr.responseType = request.af.d;
	xhr.withCredentials = request.c0;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		aG: xhr.responseURL,
		cO: xhr.status,
		fC: xhr.statusText,
		a6: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			ft: event.loaded,
			cM: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			fk: event.loaded,
			cM: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}


function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}




// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $author$project$Translation$KeyWithDefault = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Blurbs$blurbs = {
	aJ: A2($author$project$Translation$KeyWithDefault, 727334, 'Study Target completed!'),
	aK: A2($author$project$Translation$KeyWithDefault, 727447, 'At your current pace of study, you\'re <b>{{studentStatus}}</b> to complete your course by the {{courseEndDate}} end date.'),
	aL: A2($author$project$Translation$KeyWithDefault, 727467, 'Book a class'),
	aM: A2($author$project$Translation$KeyWithDefault, 727952, 'Book a private class, you have <b>{{privateClassesRemaining}} credits left</b>.'),
	aN: A2($author$project$Translation$KeyWithDefault, 727953, 'Book a private class here.'),
	aO: A2($author$project$Translation$KeyWithDefault, 727469, 'Book a private class, you have <b>{{privateClassesRemaining}} left</b> in your Study Target.'),
	aP: A2($author$project$Translation$KeyWithDefault, 639509, 'Book your Unit Review'),
	aQ: A2($author$project$Translation$KeyWithDefault, 719620, 'Change course'),
	aR: A2($author$project$Translation$KeyWithDefault, 726246, 'You have completed this part of your Study Target. You can take more classes but they will not contribute to your Study Target.'),
	aS: A2($author$project$Translation$KeyWithDefault, 726247, 'You can take classes but they will not contribute towards your Study Target.'),
	aT: A2($author$project$Translation$KeyWithDefault, 717581, 'Close'),
	aU: A2($author$project$Translation$KeyWithDefault, 727686, 'You have completed this unit, please select what you would like to study next.'),
	aW: A2($author$project$Translation$KeyWithDefault, 727679, 'Continue Studying'),
	aX: A2($author$project$Translation$KeyWithDefault, 727667, '<b>Completing your Study Target</b> means you have reached your goal for this course. You can continue to study until your course end date {{courseEndDate}}.'),
	aY: A2($author$project$Translation$KeyWithDefault, 727668, 'Course ends on {{courseEndDate}}'),
	a$: A2($author$project$Translation$KeyWithDefault, 727453, 'Enter class'),
	a0: A2($author$project$Translation$KeyWithDefault, 728344, 'Unable to enter class'),
	a1: A2($author$project$Translation$KeyWithDefault, 728349, 'Error!'),
	a2: A2($author$project$Translation$KeyWithDefault, 727452, 'Good work in {{groupClassTopic}}! Check tomorrow for another topic.'),
	a3: A2($author$project$Translation$KeyWithDefault, 727451, 'This class, {{groupClassTopic}}, is now <b>OPEN</b>. Enter within {{countdownToClassClosing}}.'),
	a4: A2($author$project$Translation$KeyWithDefault, 727450, 'The next class, {{groupClassTopic}}, opens in <b>{{classStartTimeCountdown}}</b>.'),
	ee: A2($author$project$Translation$KeyWithDefault, 644405, 'Group classes'),
	a5: A2($author$project$Translation$KeyWithDefault, 727454, 'You have <b>completed</b> your Group Classes Study Target.'),
	a7: A2($author$project$Translation$KeyWithDefault, 728336, 'Unable to load home page data'),
	ba: A2($author$project$Translation$KeyWithDefault, 718699, 'Level test'),
	bh: A2($author$project$Translation$KeyWithDefault, 728345, 'Unable to load the next group class'),
	bi: A2($author$project$Translation$KeyWithDefault, 727456, 'No classes are currently scheduled.'),
	bj: A2($author$project$Translation$KeyWithDefault, 727670, 'You have no more Group Classes on your course.'),
	bk: A2($author$project$Translation$KeyWithDefault, 727671, 'You have no more Private Classes on your course.'),
	bl: A2($author$project$Translation$KeyWithDefault, 725111, 'Not on Track'),
	bm: A2($author$project$Translation$KeyWithDefault, 725161, 'Being <b>not on track</b> means that at your current study pace, you are not going to complete your Study Target by your course end date {{courseEndDate}}. Try increasing the number of hours you are studying a week to change your status.'),
	e1: A2($author$project$Translation$KeyWithDefault, 718715, 'On track'),
	bo: A2($author$project$Translation$KeyWithDefault, 725160, 'Being <b>on track</b> means that at your current study pace, you are set to complete your Study Targets by your course end date {{courseEndDate}}.'),
	e7: A2($author$project$Translation$KeyWithDefault, 725110, 'Overall progress'),
	bq: A2($author$project$Translation$KeyWithDefault, 727471, 'Unable to show Private Classes information at this time.'),
	br: A2($author$project$Translation$KeyWithDefault, 727468, '{{privateClassTopicName}} has <b>now started</b>. Enter within {{countdownToClassClosing}}.'),
	bs: A2($author$project$Translation$KeyWithDefault, 727470, 'Your private class, {{privateClassTopicName}}, starts in <b>{{classStartTimeCountdown}}</b>.'),
	bt: A2($author$project$Translation$KeyWithDefault, 727665, 'Your private class, {{privateClassTopicName}}, starts on <b>{{classStartTimeCountdown}}</b>.'),
	cw: A2($author$project$Translation$KeyWithDefault, 693310, 'Private classes'),
	bu: A2($author$project$Translation$KeyWithDefault, 727472, 'You have <b>completed</b> your Private Classes Study Target!'),
	bw: A2($author$project$Translation$KeyWithDefault, 718686, 'My progress and goals'),
	bx: A2($author$project$Translation$KeyWithDefault, 735036, 'Go to progress and goals'),
	by: A2($author$project$Translation$KeyWithDefault, 735035, 'View detailed information about your study progress'),
	bB: A2($author$project$Translation$KeyWithDefault, 727666, 'Self Study units'),
	bC: A2($author$project$Translation$KeyWithDefault, 728350, 'Unable to load server time'),
	bE: A2($author$project$Translation$KeyWithDefault, 727678, 'Step {{currentStep}} of {{totalNumberSteps}}'),
	bH: A2($author$project$Translation$KeyWithDefault, 727680, 'Take and pass the level test to advance to the next level!'),
	bI: A2($author$project$Translation$KeyWithDefault, 727681, 'Take test'),
	bJ: A2($author$project$Translation$KeyWithDefault, 727448, 'Your course ends on {{courseEndDate}}. You can continue studying until this date.'),
	f_: A2($author$project$Translation$KeyWithDefault, 639494, 'Unit Review'),
	bM: A2($author$project$Translation$KeyWithDefault, 727683, 'Your Unit Review has now started. Enter within {{countdownToClassClose}}.'),
	bN: A2($author$project$Translation$KeyWithDefault, 727684, 'Your Unit Review starts in {{countdownToReviewStart}}.'),
	bO: A2($author$project$Translation$KeyWithDefault, 727687, 'Your Unit Review starts on {{dateAndHourOfUnitReview}}'),
	bP: A2($author$project$Translation$KeyWithDefault, 727449, 'View detailed progress'),
	bQ: A2($author$project$Translation$KeyWithDefault, 734401, 'Your current course')
};
var $author$project$Blurbs$allBlurbs = _List_fromArray(
	[$author$project$Blurbs$blurbs.e1, $author$project$Blurbs$blurbs.bl, $author$project$Blurbs$blurbs.aY, $author$project$Blurbs$blurbs.e7, $author$project$Blurbs$blurbs.bB, $author$project$Blurbs$blurbs.cw, $author$project$Blurbs$blurbs.ee, $author$project$Blurbs$blurbs.bw, $author$project$Blurbs$blurbs.by, $author$project$Blurbs$blurbs.bx, $author$project$Blurbs$blurbs.bQ, $author$project$Blurbs$blurbs.bP, $author$project$Blurbs$blurbs.bE, $author$project$Blurbs$blurbs.aW, $author$project$Blurbs$blurbs.aO, $author$project$Blurbs$blurbs.aM, $author$project$Blurbs$blurbs.aN, $author$project$Blurbs$blurbs.aL, $author$project$Blurbs$blurbs.br, $author$project$Blurbs$blurbs.a$, $author$project$Blurbs$blurbs.bs, $author$project$Blurbs$blurbs.bt, $author$project$Blurbs$blurbs.bq, $author$project$Blurbs$blurbs.bu, $author$project$Blurbs$blurbs.aK, $author$project$Blurbs$blurbs.bJ, $author$project$Blurbs$blurbs.aJ, $author$project$Blurbs$blurbs.bo, $author$project$Blurbs$blurbs.bm, $author$project$Blurbs$blurbs.aX, $author$project$Blurbs$blurbs.aT, $author$project$Blurbs$blurbs.a3, $author$project$Blurbs$blurbs.a4, $author$project$Blurbs$blurbs.a5, $author$project$Blurbs$blurbs.bi, $author$project$Blurbs$blurbs.a2, $author$project$Blurbs$blurbs.bj, $author$project$Blurbs$blurbs.bk, $author$project$Blurbs$blurbs.aR, $author$project$Blurbs$blurbs.aS, $author$project$Blurbs$blurbs.bM, $author$project$Blurbs$blurbs.bN, $author$project$Blurbs$blurbs.bO, $author$project$Blurbs$blurbs.aP, $author$project$Blurbs$blurbs.f_, $author$project$Blurbs$blurbs.bH, $author$project$Blurbs$blurbs.bI, $author$project$Blurbs$blurbs.ba, $author$project$Blurbs$blurbs.aQ, $author$project$Blurbs$blurbs.aU, $author$project$Blurbs$blurbs.bC, $author$project$Blurbs$blurbs.bh, $author$project$Blurbs$blurbs.a0, $author$project$Blurbs$blurbs.a7, $author$project$Blurbs$blurbs.a1]);
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.e) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.g),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.g);
		} else {
			var treeLen = builder.e * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.h) : builder.h;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.e);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.g) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.g);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{h: nodeList, e: (len / $elm$core$Array$branchFactor) | 0, g: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {ca: fragment, ce: host, cs: path, cv: port_, cA: protocol, cB: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$element = _Browser_element;
var $author$project$Scaffold$InitialisationFailed = {$: 2};
var $author$project$Scaffold$ReceiveViewport = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Scaffold$Uninitialised = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Maybe$destruct = F3(
	function (_default, func, maybe) {
		if (!maybe.$) {
			var a = maybe.a;
			return func(a);
		} else {
			return _default;
		}
	});
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Ports$bubbleError = _Platform_outgoingPort(
	'bubbleError',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'display',
					function ($) {
						return A3(
							$elm$core$Maybe$destruct,
							$elm$json$Json$Encode$null,
							function ($) {
								return $elm$json$Json$Encode$object(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'message',
											$elm$json$Json$Encode$string($.bc)),
											_Utils_Tuple2(
											'title',
											$elm$json$Json$Encode$string($.cU))
										]));
							},
							$);
					}($.ae)),
					_Utils_Tuple2(
					'log',
					function ($) {
						return A3(
							$elm$core$Maybe$destruct,
							$elm$json$Json$Encode$null,
							function ($) {
								return $elm$json$Json$Encode$object(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'context',
											$elm$json$Json$Encode$string($.dp)),
											_Utils_Tuple2(
											'message',
											$elm$json$Json$Encode$string($.bc))
										]));
							},
							$);
					}($.ap))
				]));
	});
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $author$project$Messages$LogMessage = F2(
	function (context, message) {
		return {dp: context, bc: message};
	});
var $author$project$Messages$MessageDisplay = F2(
	function (title, message) {
		return {bc: message, cU: title};
	});
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Messages$decodingErrorMessage = F4(
	function (displayTitle, displayMessage, context, err) {
		return {
			ae: A2(
				$elm$core$Maybe$map,
				$author$project$Messages$MessageDisplay(displayTitle),
				displayMessage),
			ap: $elm$core$Maybe$Just(
				A2(
					$author$project$Messages$LogMessage,
					context,
					$elm$json$Json$Decode$errorToString(err)))
		};
	});
var $author$project$ScaffoldData$Flags = function (student) {
	return function (lang) {
		return function (config) {
			return function (url) {
				return function (fullUrl) {
					return function (urlParams) {
						return function (cachedBlurbs) {
							return function (externalBlurbs) {
								return function (externalLogo) {
									return function (isMarketCN) {
										return {O: cachedBlurbs, G: config, dW: externalBlurbs, ag: externalLogo, d5: fullUrl, ak: isMarketCN, B: lang, fF: student, aG: url, aH: urlParams};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$JsonUtils$apply = F2(
	function (da, fAtoB) {
		return A3(
			$elm$json$Json$Decode$map2,
			F2(
				function (f, a) {
					return f(a);
				}),
			fAtoB,
			da);
	});
var $author$project$ScaffoldData$CachedBlurb = F3(
	function (loadedAt, maxAge, value) {
		return {eI: loadedAt, eQ: maxAge, f3: value};
	});
var $elm$json$Json$Decode$field = _Json_decodeField;
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$json$Json$Decode$string = _Json_decodeString;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$JsonUtils$withDefault = F2(
	function (def, dec) {
		return A2(
			$elm$json$Json$Decode$map,
			$elm$core$Maybe$withDefault(def),
			$elm$json$Json$Decode$maybe(dec));
	});
var $author$project$ScaffoldData$cachedBlurbDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$string),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'maxAge', $elm$json$Json$Decode$int),
		A2(
			$author$project$JsonUtils$apply,
			A2(
				$author$project$JsonUtils$withDefault,
				$elm$time$Time$millisToPosix(0),
				A2(
					$elm$json$Json$Decode$field,
					'loadedAtPosix',
					A2($elm$json$Json$Decode$map, $elm$time$Time$millisToPosix, $elm$json$Json$Decode$int))),
			$elm$json$Json$Decode$succeed($author$project$ScaffoldData$CachedBlurb))));
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Dict$fromList,
		$elm$json$Json$Decode$keyValuePairs(decoder));
};
var $author$project$ScaffoldData$blurbCacheDecoder = $elm$json$Json$Decode$dict(
	$elm$json$Json$Decode$dict($author$project$ScaffoldData$cachedBlurbDecoder));
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $author$project$ScaffoldData$externalBlurbDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'defaultVal', $elm$json$Json$Decode$string),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'blurbId', $elm$json$Json$Decode$int),
		$elm$json$Json$Decode$succeed($author$project$Translation$KeyWithDefault)));
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$ScaffoldData$externalBlurbsDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$values,
	$elm$json$Json$Decode$dict($author$project$ScaffoldData$externalBlurbDecoder));
var $author$project$ScaffoldData$GeneralAppConfig = F6(
	function (languages, countries, genders, departments, seniorities, paths) {
		return {ab: countries, ad: departments, aj: genders, ao: languages, e9: paths, az: seniorities};
	});
var $author$project$ScaffoldData$EfConstant = F3(
	function (value, blurb, defaultLabel) {
		return {c6: blurb, dE: defaultLabel, f3: value};
	});
var $author$project$ScaffoldData$efConstantDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'defaultLabel', $elm$json$Json$Decode$string),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'blurb', $elm$json$Json$Decode$int),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$string),
			$elm$json$Json$Decode$succeed($author$project$ScaffoldData$EfConstant))));
var $author$project$ScaffoldData$Language = F2(
	function (value, label) {
		return {ez: label, f3: value};
	});
var $author$project$ScaffoldData$languageDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'label', $elm$json$Json$Decode$string),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$string),
		$elm$json$Json$Decode$succeed($author$project$ScaffoldData$Language)));
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$Paths$Paths = function (currentCourse) {
	return function (changeCourse) {
		return function (grammarLab) {
			return function (appsAndTools) {
				return function (privateClass) {
					return function (currentBookings) {
						return function (groupClass) {
							return function (helpCenter) {
								return function (onboarding) {
									return function (accountSettings) {
										return function (progressAndGoals) {
											return function (efset30) {
												return function (efset30Retake) {
													return function (levelTest) {
														return function (home) {
															return {c_: accountSettings, c2: appsAndTools, aQ: changeCourse, dx: currentBookings, b$: currentCourse, dI: efset30, dJ: efset30Retake, eb: grammarLab, ed: groupClass, ek: helpCenter, em: home, ba: levelTest, e4: onboarding, ff: privateClass, bw: progressAndGoals};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Paths$PathObject = F3(
	function (url, external, blank) {
		return {bU: blank, b7: external, aG: url};
	});
var $author$project$ScaffoldData$pathObjectDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'blank', $elm$json$Json$Decode$bool),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'external', $elm$json$Json$Decode$bool),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
			$elm$json$Json$Decode$succeed($author$project$Paths$PathObject))));
var $author$project$ScaffoldData$pathsDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'home', $author$project$ScaffoldData$pathObjectDecoder),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'levelTest', $author$project$ScaffoldData$pathObjectDecoder),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'efset30Retake', $author$project$ScaffoldData$pathObjectDecoder),
			A2(
				$author$project$JsonUtils$apply,
				A2($elm$json$Json$Decode$field, 'efset30', $author$project$ScaffoldData$pathObjectDecoder),
				A2(
					$author$project$JsonUtils$apply,
					A2($elm$json$Json$Decode$field, 'progressAndGoals', $author$project$ScaffoldData$pathObjectDecoder),
					A2(
						$author$project$JsonUtils$apply,
						A2($elm$json$Json$Decode$field, 'accountSettings', $author$project$ScaffoldData$pathObjectDecoder),
						A2(
							$author$project$JsonUtils$apply,
							A2($elm$json$Json$Decode$field, 'onboarding', $author$project$ScaffoldData$pathObjectDecoder),
							A2(
								$author$project$JsonUtils$apply,
								A2($elm$json$Json$Decode$field, 'helpCenter', $author$project$ScaffoldData$pathObjectDecoder),
								A2(
									$author$project$JsonUtils$apply,
									A2($elm$json$Json$Decode$field, 'groupClass', $author$project$ScaffoldData$pathObjectDecoder),
									A2(
										$author$project$JsonUtils$apply,
										A2($elm$json$Json$Decode$field, 'currentBookings', $author$project$ScaffoldData$pathObjectDecoder),
										A2(
											$author$project$JsonUtils$apply,
											A2($elm$json$Json$Decode$field, 'privateClass', $author$project$ScaffoldData$pathObjectDecoder),
											A2(
												$author$project$JsonUtils$apply,
												A2($elm$json$Json$Decode$field, 'appsAndTools', $author$project$ScaffoldData$pathObjectDecoder),
												A2(
													$author$project$JsonUtils$apply,
													A2($elm$json$Json$Decode$field, 'grammarLab', $author$project$ScaffoldData$pathObjectDecoder),
													A2(
														$author$project$JsonUtils$apply,
														A2($elm$json$Json$Decode$field, 'changeCourse', $author$project$ScaffoldData$pathObjectDecoder),
														A2(
															$author$project$JsonUtils$apply,
															A2($elm$json$Json$Decode$field, 'currentCourse', $author$project$ScaffoldData$pathObjectDecoder),
															$elm$json$Json$Decode$succeed($author$project$Paths$Paths))))))))))))))));
var $author$project$ScaffoldData$generalAppConfigDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'paths', $author$project$ScaffoldData$pathsDecoder),
	A2(
		$author$project$JsonUtils$apply,
		A2(
			$elm$json$Json$Decode$field,
			'seniorities',
			$elm$json$Json$Decode$list($author$project$ScaffoldData$efConstantDecoder)),
		A2(
			$author$project$JsonUtils$apply,
			A2(
				$elm$json$Json$Decode$field,
				'departments',
				$elm$json$Json$Decode$list($author$project$ScaffoldData$efConstantDecoder)),
			A2(
				$author$project$JsonUtils$apply,
				A2(
					$elm$json$Json$Decode$field,
					'genders',
					$elm$json$Json$Decode$list($author$project$ScaffoldData$efConstantDecoder)),
				A2(
					$author$project$JsonUtils$apply,
					A2(
						$elm$json$Json$Decode$field,
						'countries',
						$elm$json$Json$Decode$list($author$project$ScaffoldData$efConstantDecoder)),
					A2(
						$author$project$JsonUtils$apply,
						A2(
							$elm$json$Json$Decode$field,
							'languages',
							$elm$json$Json$Decode$list($author$project$ScaffoldData$languageDecoder)),
						$elm$json$Json$Decode$succeed($author$project$ScaffoldData$GeneralAppConfig)))))));
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$fail = _Json_fail;
var $author$project$ScaffoldData$listToTupleDecoder = function (decoder) {
	return A2(
		$elm$json$Json$Decode$andThen,
		function (things) {
			if (things.b && things.b.b) {
				var a = things.a;
				var _v1 = things.b;
				var b = _v1.a;
				return $elm$json$Json$Decode$succeed(
					_Utils_Tuple2(a, b));
			} else {
				return $elm$json$Json$Decode$fail('Expected a list of things with at least two values to create a tuple from');
			}
		},
		$elm$json$Json$Decode$list(decoder));
};
var $author$project$ApiTypes$Student$Index$Student = function (firstName) {
	return function (lastName) {
		return function (country) {
			return function (gender) {
				return function (emailAddress) {
					return function (phoneNumber) {
						return function (emailLanguage) {
							return function (seniority) {
								return function (department) {
									return function (bookingConfirmationEmail) {
										return function (id) {
											return function (userId) {
												return function (companyId) {
													return function (grantsAndCredits) {
														return function (reportType) {
															return function (courseStart) {
																return function (courseExpiry) {
																	return function (studyTargets) {
																		return function (timezone) {
																			return function (clientTimezone) {
																				return {bW: bookingConfirmationEmail, dk: clientTimezone, dm: companyId, b_: country, ds: courseExpiry, dt: courseStart, b2: department, b3: emailAddress, b4: emailLanguage, b8: firstName, cb: gender, ec: grantsAndCredits, cf: id, cl: lastName, ct: phoneNumber, cD: reportType, cK: seniority, fH: studyTargets, cT: timezone, f2: userId};
																			};
																		};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$ApiTypes$GrantsAndCredits$GrantsAndCreditFeatures = function (faceToFaceStudy) {
	return function (selfStudy) {
		return function (privateLesson) {
			return function (groupDiscussion) {
				return function (writingClass) {
					return function (personalCoaching) {
						return function (spinCourse) {
							return function (unitReview) {
								return function (efsetSpeaking) {
									return function (efset50) {
										return function (liveChat) {
											return function (retakePlacementTest) {
												return function (hultEfPro) {
													return function (groupScheduledLesson) {
														return function (privateScheduledLesson) {
															return function (catalyst) {
																return function (generalEnglish) {
																	return function (businessEnglish) {
																		return function (industryEnglish) {
																			return function (travelEnglish) {
																				return function (german) {
																					return function (french) {
																						return function (spanish) {
																							return function (swedish) {
																								return function (portuguese) {
																									return function (dubaiAirports) {
																										return function (customRocheSAG) {
																											return function (customRwanda) {
																												return function (customMcDonalds) {
																													return function (schoolEnglish) {
																														return function (mango) {
																															return {da: businessEnglish, de: catalyst, dz: customMcDonalds, dA: customRocheSAG, dB: customRwanda, dH: dubaiAirports, dK: efset50, dL: efsetSpeaking, dY: faceToFaceStudy, d4: french, d6: generalEnglish, d8: german, ef: groupDiscussion, eh: groupScheduledLesson, en: hultEfPro, eq: industryEnglish, eH: liveChat, eN: mango, fc: personalCoaching, fd: portuguese, fg: privateLesson, fi: privateScheduledLesson, fo: retakePlacementTest, fr: schoolEnglish, fs: selfStudy, fx: spanish, fy: spinCourse, fK: swedish, fY: travelEnglish, f_: unitReview, f8: writingClass};
																														};
																													};
																												};
																											};
																										};
																									};
																								};
																							};
																						};
																					};
																				};
																			};
																		};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$ApiTypes$GrantsAndCredits$GrantsAndCreditsEntry = F3(
	function (expired, expirationDate, quota) {
		return {dT: expirationDate, dU: expired, fj: quota};
	});
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $author$project$ApiTypes$GrantsAndCredits$QuotaLimited = function (a) {
	return {$: 1, a: a};
};
var $author$project$ApiTypes$GrantsAndCredits$QuotaUnlimited = {$: 0};
var $author$project$ApiTypes$GrantsAndCredits$Limited = F2(
	function (amount, remaining) {
		return {c1: amount, fm: remaining};
	});
var $author$project$Student$limitedDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'remaining', $elm$json$Json$Decode$float),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'amount', $elm$json$Json$Decode$float),
		$elm$json$Json$Decode$succeed($author$project$ApiTypes$GrantsAndCredits$Limited)));
var $author$project$Student$quotaDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$andThen,
			function (s) {
				return (s === 'unlimited') ? $elm$json$Json$Decode$succeed($author$project$ApiTypes$GrantsAndCredits$QuotaUnlimited) : $elm$json$Json$Decode$fail('unexpected string');
			},
			$elm$json$Json$Decode$string),
			A2($elm$json$Json$Decode$map, $author$project$ApiTypes$GrantsAndCredits$QuotaLimited, $author$project$Student$limitedDecoder)
		]));
var $author$project$Student$grantsAndCreditsEntryDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'quota', $author$project$Student$quotaDecoder),
	A2(
		$author$project$JsonUtils$apply,
		A2(
			$elm$json$Json$Decode$field,
			'expirationDate',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$float)),
		A2(
			$author$project$JsonUtils$apply,
			A2(
				$elm$json$Json$Decode$field,
				'expired',
				$elm$json$Json$Decode$maybe($elm$json$Json$Decode$bool)),
			$elm$json$Json$Decode$succeed($author$project$ApiTypes$GrantsAndCredits$GrantsAndCreditsEntry))));
var $author$project$Student$grantsAndCreditsDecoder = A2(
	$author$project$JsonUtils$apply,
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'mango', $author$project$Student$grantsAndCreditsEntryDecoder)),
	A2(
		$author$project$JsonUtils$apply,
		$elm$json$Json$Decode$maybe(
			A2($elm$json$Json$Decode$field, 'schoolEnglish', $author$project$Student$grantsAndCreditsEntryDecoder)),
		A2(
			$author$project$JsonUtils$apply,
			$elm$json$Json$Decode$maybe(
				A2($elm$json$Json$Decode$field, 'customMcDonalds', $author$project$Student$grantsAndCreditsEntryDecoder)),
			A2(
				$author$project$JsonUtils$apply,
				$elm$json$Json$Decode$maybe(
					A2($elm$json$Json$Decode$field, 'customRwanda', $author$project$Student$grantsAndCreditsEntryDecoder)),
				A2(
					$author$project$JsonUtils$apply,
					$elm$json$Json$Decode$maybe(
						A2($elm$json$Json$Decode$field, 'customRocheSAG', $author$project$Student$grantsAndCreditsEntryDecoder)),
					A2(
						$author$project$JsonUtils$apply,
						$elm$json$Json$Decode$maybe(
							A2($elm$json$Json$Decode$field, 'dubaiAirports', $author$project$Student$grantsAndCreditsEntryDecoder)),
						A2(
							$author$project$JsonUtils$apply,
							$elm$json$Json$Decode$maybe(
								A2($elm$json$Json$Decode$field, 'portuguese', $author$project$Student$grantsAndCreditsEntryDecoder)),
							A2(
								$author$project$JsonUtils$apply,
								$elm$json$Json$Decode$maybe(
									A2($elm$json$Json$Decode$field, 'swedish', $author$project$Student$grantsAndCreditsEntryDecoder)),
								A2(
									$author$project$JsonUtils$apply,
									$elm$json$Json$Decode$maybe(
										A2($elm$json$Json$Decode$field, 'spanish', $author$project$Student$grantsAndCreditsEntryDecoder)),
									A2(
										$author$project$JsonUtils$apply,
										$elm$json$Json$Decode$maybe(
											A2($elm$json$Json$Decode$field, 'french', $author$project$Student$grantsAndCreditsEntryDecoder)),
										A2(
											$author$project$JsonUtils$apply,
											$elm$json$Json$Decode$maybe(
												A2($elm$json$Json$Decode$field, 'german', $author$project$Student$grantsAndCreditsEntryDecoder)),
											A2(
												$author$project$JsonUtils$apply,
												$elm$json$Json$Decode$maybe(
													A2($elm$json$Json$Decode$field, 'travelEnglish', $author$project$Student$grantsAndCreditsEntryDecoder)),
												A2(
													$author$project$JsonUtils$apply,
													$elm$json$Json$Decode$maybe(
														A2($elm$json$Json$Decode$field, 'industryEnglish', $author$project$Student$grantsAndCreditsEntryDecoder)),
													A2(
														$author$project$JsonUtils$apply,
														$elm$json$Json$Decode$maybe(
															A2($elm$json$Json$Decode$field, 'businessEnglish', $author$project$Student$grantsAndCreditsEntryDecoder)),
														A2(
															$author$project$JsonUtils$apply,
															$elm$json$Json$Decode$maybe(
																A2($elm$json$Json$Decode$field, 'generalEnglish', $author$project$Student$grantsAndCreditsEntryDecoder)),
															A2(
																$author$project$JsonUtils$apply,
																$elm$json$Json$Decode$maybe(
																	A2($elm$json$Json$Decode$field, 'catalyst', $author$project$Student$grantsAndCreditsEntryDecoder)),
																A2(
																	$author$project$JsonUtils$apply,
																	$elm$json$Json$Decode$maybe(
																		A2($elm$json$Json$Decode$field, 'privateScheduledLesson', $author$project$Student$grantsAndCreditsEntryDecoder)),
																	A2(
																		$author$project$JsonUtils$apply,
																		$elm$json$Json$Decode$maybe(
																			A2($elm$json$Json$Decode$field, 'groupScheduledLesson', $author$project$Student$grantsAndCreditsEntryDecoder)),
																		A2(
																			$author$project$JsonUtils$apply,
																			$elm$json$Json$Decode$maybe(
																				A2($elm$json$Json$Decode$field, 'hultEfPro', $author$project$Student$grantsAndCreditsEntryDecoder)),
																			A2(
																				$author$project$JsonUtils$apply,
																				$elm$json$Json$Decode$maybe(
																					A2($elm$json$Json$Decode$field, 'retakePlacementTest', $author$project$Student$grantsAndCreditsEntryDecoder)),
																				A2(
																					$author$project$JsonUtils$apply,
																					$elm$json$Json$Decode$maybe(
																						A2($elm$json$Json$Decode$field, 'liveChat', $author$project$Student$grantsAndCreditsEntryDecoder)),
																					A2(
																						$author$project$JsonUtils$apply,
																						$elm$json$Json$Decode$maybe(
																							A2($elm$json$Json$Decode$field, 'efset50', $author$project$Student$grantsAndCreditsEntryDecoder)),
																						A2(
																							$author$project$JsonUtils$apply,
																							$elm$json$Json$Decode$maybe(
																								A2($elm$json$Json$Decode$field, 'efsetSpeaking', $author$project$Student$grantsAndCreditsEntryDecoder)),
																							A2(
																								$author$project$JsonUtils$apply,
																								$elm$json$Json$Decode$maybe(
																									A2($elm$json$Json$Decode$field, 'unitReview', $author$project$Student$grantsAndCreditsEntryDecoder)),
																								A2(
																									$author$project$JsonUtils$apply,
																									$elm$json$Json$Decode$maybe(
																										A2($elm$json$Json$Decode$field, 'spinCourse', $author$project$Student$grantsAndCreditsEntryDecoder)),
																									A2(
																										$author$project$JsonUtils$apply,
																										$elm$json$Json$Decode$maybe(
																											A2($elm$json$Json$Decode$field, 'personalCoaching', $author$project$Student$grantsAndCreditsEntryDecoder)),
																										A2(
																											$author$project$JsonUtils$apply,
																											$elm$json$Json$Decode$maybe(
																												A2($elm$json$Json$Decode$field, 'writingClass', $author$project$Student$grantsAndCreditsEntryDecoder)),
																											A2(
																												$author$project$JsonUtils$apply,
																												$elm$json$Json$Decode$maybe(
																													A2($elm$json$Json$Decode$field, 'groupDiscussion', $author$project$Student$grantsAndCreditsEntryDecoder)),
																												A2(
																													$author$project$JsonUtils$apply,
																													$elm$json$Json$Decode$maybe(
																														A2($elm$json$Json$Decode$field, 'privateLesson', $author$project$Student$grantsAndCreditsEntryDecoder)),
																													A2(
																														$author$project$JsonUtils$apply,
																														$elm$json$Json$Decode$maybe(
																															A2($elm$json$Json$Decode$field, 'selfStudy', $author$project$Student$grantsAndCreditsEntryDecoder)),
																														A2(
																															$author$project$JsonUtils$apply,
																															$elm$json$Json$Decode$maybe(
																																A2($elm$json$Json$Decode$field, 'faceToFaceStudy', $author$project$Student$grantsAndCreditsEntryDecoder)),
																															$elm$json$Json$Decode$succeed($author$project$ApiTypes$GrantsAndCredits$GrantsAndCreditFeatures))))))))))))))))))))))))))))))));
var $author$project$ApiTypes$Student$Index$StudentTimezone = F2(
	function (key, displayName) {
		return {dF: displayName, ey: key};
	});
var $author$project$Student$studentTimezoneDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'displayName', $elm$json$Json$Decode$string),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string),
		$elm$json$Json$Decode$succeed($author$project$ApiTypes$Student$Index$StudentTimezone)));
var $author$project$ApiTypes$Student$Index$StudyTargets = F8(
	function (generalEnglishUnits, industryEnglishUnits, businessEnglishUnits, privateLessons, groupLessons, mandatoryIndustryTopics, mandatoryBusinessTopics, initialTopic) {
		return {db: businessEnglishUnits, d7: generalEnglishUnits, eg: groupLessons, er: industryEnglishUnits, et: initialTopic, eL: mandatoryBusinessTopics, eM: mandatoryIndustryTopics, fh: privateLessons};
	});
var $author$project$ApiTypes$Student$Index$Course = $elm$core$Basics$identity;
var $author$project$ApiTypes$Student$Index$Topic = F2(
	function (id, course) {
		return {dr: course, cf: id};
	});
var $author$project$Student$topicDecoder = A2(
	$author$project$JsonUtils$apply,
	A2(
		$elm$json$Json$Decode$andThen,
		function (str) {
			switch (str) {
				case 'BE':
					return $elm$json$Json$Decode$succeed('BE');
				case 'IND':
					return $elm$json$Json$Decode$succeed('IND');
				case 'INDB2B':
					return $elm$json$Json$Decode$succeed('INDB2B');
				default:
					var course = str;
					return $elm$json$Json$Decode$fail('Unexpected topic course: ' + course);
			}
		},
		A2($elm$json$Json$Decode$field, 'course', $elm$json$Json$Decode$string)),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string),
		$elm$json$Json$Decode$succeed($author$project$ApiTypes$Student$Index$Topic)));
var $author$project$Student$studyTargetsDecoder = A2(
	$author$project$JsonUtils$apply,
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'initialTopic', $author$project$Student$topicDecoder)),
	A2(
		$author$project$JsonUtils$apply,
		A2(
			$elm$json$Json$Decode$field,
			'mandatoryBusinessTopics',
			$elm$json$Json$Decode$list($elm$json$Json$Decode$string)),
		A2(
			$author$project$JsonUtils$apply,
			A2(
				$elm$json$Json$Decode$field,
				'mandatoryIndustryTopics',
				$elm$json$Json$Decode$list($elm$json$Json$Decode$string)),
			A2(
				$author$project$JsonUtils$apply,
				A2($elm$json$Json$Decode$field, 'groupLessons', $elm$json$Json$Decode$float),
				A2(
					$author$project$JsonUtils$apply,
					A2($elm$json$Json$Decode$field, 'privateLessons', $elm$json$Json$Decode$float),
					A2(
						$author$project$JsonUtils$apply,
						A2($elm$json$Json$Decode$field, 'businessEnglishUnits', $elm$json$Json$Decode$float),
						A2(
							$author$project$JsonUtils$apply,
							A2($elm$json$Json$Decode$field, 'industryEnglishUnits', $elm$json$Json$Decode$float),
							A2(
								$author$project$JsonUtils$apply,
								A2($elm$json$Json$Decode$field, 'generalEnglishUnits', $elm$json$Json$Decode$float),
								$elm$json$Json$Decode$succeed($author$project$ApiTypes$Student$Index$StudyTargets)))))))));
var $author$project$Student$studentDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'clientTimezone', $author$project$Student$studentTimezoneDecoder),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'timezone', $author$project$Student$studentTimezoneDecoder),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'studyTargets', $author$project$Student$studyTargetsDecoder),
			A2(
				$author$project$JsonUtils$apply,
				A2($elm$json$Json$Decode$field, 'courseExpiry', $elm$json$Json$Decode$string),
				A2(
					$author$project$JsonUtils$apply,
					A2($elm$json$Json$Decode$field, 'courseStart', $elm$json$Json$Decode$string),
					A2(
						$author$project$JsonUtils$apply,
						A2($elm$json$Json$Decode$field, 'reportType', $elm$json$Json$Decode$string),
						A2(
							$author$project$JsonUtils$apply,
							A2($elm$json$Json$Decode$field, 'grantsAndCredits', $author$project$Student$grantsAndCreditsDecoder),
							A2(
								$author$project$JsonUtils$apply,
								A2($elm$json$Json$Decode$field, 'companyId', $elm$json$Json$Decode$string),
								A2(
									$author$project$JsonUtils$apply,
									A2($elm$json$Json$Decode$field, 'userId', $elm$json$Json$Decode$string),
									A2(
										$author$project$JsonUtils$apply,
										A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string),
										A2(
											$author$project$JsonUtils$apply,
											A2($elm$json$Json$Decode$field, 'bookingConfirmationEmail', $elm$json$Json$Decode$bool),
											A2(
												$author$project$JsonUtils$apply,
												$elm$json$Json$Decode$maybe(
													A2($elm$json$Json$Decode$field, 'department', $elm$json$Json$Decode$string)),
												A2(
													$author$project$JsonUtils$apply,
													$elm$json$Json$Decode$maybe(
														A2($elm$json$Json$Decode$field, 'seniority', $elm$json$Json$Decode$string)),
													A2(
														$author$project$JsonUtils$apply,
														A2($elm$json$Json$Decode$field, 'emailLanguage', $elm$json$Json$Decode$string),
														A2(
															$author$project$JsonUtils$apply,
															$elm$json$Json$Decode$maybe(
																A2($elm$json$Json$Decode$field, 'phoneNumber', $elm$json$Json$Decode$string)),
															A2(
																$author$project$JsonUtils$apply,
																A2($elm$json$Json$Decode$field, 'emailAddress', $elm$json$Json$Decode$string),
																A2(
																	$author$project$JsonUtils$apply,
																	A2($elm$json$Json$Decode$field, 'gender', $elm$json$Json$Decode$string),
																	A2(
																		$author$project$JsonUtils$apply,
																		A2($elm$json$Json$Decode$field, 'country', $elm$json$Json$Decode$string),
																		A2(
																			$author$project$JsonUtils$apply,
																			A2($elm$json$Json$Decode$field, 'lastName', $elm$json$Json$Decode$string),
																			A2(
																				$author$project$JsonUtils$apply,
																				A2($elm$json$Json$Decode$field, 'firstName', $elm$json$Json$Decode$string),
																				$elm$json$Json$Decode$succeed($author$project$ApiTypes$Student$Index$Student)))))))))))))))))))));
var $author$project$ScaffoldData$flagsDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'isMarketCN', $elm$json$Json$Decode$bool),
	A2(
		$author$project$JsonUtils$apply,
		$elm$json$Json$Decode$maybe(
			A2($elm$json$Json$Decode$field, 'externalLogo', $elm$json$Json$Decode$string)),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'externalBlurbs', $author$project$ScaffoldData$externalBlurbsDecoder),
			A2(
				$author$project$JsonUtils$apply,
				A2($elm$json$Json$Decode$field, 'cachedBlurbs', $author$project$ScaffoldData$blurbCacheDecoder),
				A2(
					$author$project$JsonUtils$apply,
					A2(
						$elm$json$Json$Decode$field,
						'urlParams',
						$elm$json$Json$Decode$list(
							$author$project$ScaffoldData$listToTupleDecoder($elm$json$Json$Decode$string))),
					A2(
						$author$project$JsonUtils$apply,
						A2($elm$json$Json$Decode$field, 'fullUrl', $elm$json$Json$Decode$string),
						A2(
							$author$project$JsonUtils$apply,
							A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
							A2(
								$author$project$JsonUtils$apply,
								A2($elm$json$Json$Decode$field, 'config', $author$project$ScaffoldData$generalAppConfigDecoder),
								A2(
									$author$project$JsonUtils$apply,
									A2($elm$json$Json$Decode$field, 'lang', $elm$json$Json$Decode$string),
									A2(
										$author$project$JsonUtils$apply,
										$elm$json$Json$Decode$maybe(
											A2($elm$json$Json$Decode$field, 'student', $author$project$Student$studentDecoder)),
										$elm$json$Json$Decode$succeed($author$project$ScaffoldData$Flags)))))))))));
var $elm$browser$Browser$Dom$getViewport = _Browser_withWindow(_Browser_getViewport);
var $author$project$Scaffold$init = F2(
	function (resources, encodedFlags) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$ScaffoldData$flagsDecoder, encodedFlags);
		if (_v0.$ === 1) {
			var err = _v0.a;
			return _Utils_Tuple2(
				$author$project$Scaffold$InitialisationFailed,
				$author$project$Ports$bubbleError(
					A4(
						$author$project$Messages$decodingErrorMessage,
						'Error!',
						$elm$core$Maybe$Just('Unable to initialise the school'),
						'Decoding intial data',
						err)));
		} else {
			var flags = _v0.a;
			return _Utils_Tuple2(
				A2($author$project$Scaffold$Uninitialised, flags, $elm$core$Maybe$Nothing),
				A2(
					$elm$core$Task$perform,
					$author$project$Scaffold$ReceiveViewport(resources),
					$elm$browser$Browser$Dom$getViewport));
		}
	});
var $author$project$Scaffold$AppMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Scaffold$LanguageChanged = function (a) {
	return {$: 0, a: a};
};
var $author$project$Scaffold$ModalMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Scaffold$StudentChanged = function (a) {
	return {$: 2, a: a};
};
var $author$project$Scaffold$UrlChanged = function (a) {
	return {$: 3, a: a};
};
var $author$project$Scaffold$WindowResize = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$core$Platform$Sub$map = _Platform_map;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $author$project$Ports$onLanguageChange = _Platform_incomingPort('onLanguageChange', $elm$json$Json$Decode$string);
var $elm$browser$Browser$Events$Window = 1;
var $elm$browser$Browser$Events$MySub = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$browser$Browser$Events$State = F2(
	function (subs, pids) {
		return {cu: pids, cR: subs};
	});
var $elm$browser$Browser$Events$init = $elm$core$Task$succeed(
	A2($elm$browser$Browser$Events$State, _List_Nil, $elm$core$Dict$empty));
var $elm$browser$Browser$Events$nodeToKey = function (node) {
	if (!node) {
		return 'd_';
	} else {
		return 'w_';
	}
};
var $elm$browser$Browser$Events$addKey = function (sub) {
	var node = sub.a;
	var name = sub.b;
	return _Utils_Tuple2(
		_Utils_ap(
			$elm$browser$Browser$Events$nodeToKey(node),
			name),
		sub);
};
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$browser$Browser$Events$Event = F2(
	function (key, event) {
		return {b5: event, ey: key};
	});
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$browser$Browser$Events$spawn = F3(
	function (router, key, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var actualNode = function () {
			if (!node) {
				return _Browser_doc;
			} else {
				return _Browser_window;
			}
		}();
		return A2(
			$elm$core$Task$map,
			function (value) {
				return _Utils_Tuple2(key, value);
			},
			A3(
				_Browser_on,
				actualNode,
				name,
				function (event) {
					return A2(
						$elm$core$Platform$sendToSelf,
						router,
						A2($elm$browser$Browser$Events$Event, key, event));
				}));
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $elm$browser$Browser$Events$onEffects = F3(
	function (router, subs, state) {
		var stepRight = F3(
			function (key, sub, _v6) {
				var deads = _v6.a;
				var lives = _v6.b;
				var news = _v6.c;
				return _Utils_Tuple3(
					deads,
					lives,
					A2(
						$elm$core$List$cons,
						A3($elm$browser$Browser$Events$spawn, router, key, sub),
						news));
			});
		var stepLeft = F3(
			function (_v4, pid, _v5) {
				var deads = _v5.a;
				var lives = _v5.b;
				var news = _v5.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, pid, deads),
					lives,
					news);
			});
		var stepBoth = F4(
			function (key, pid, _v2, _v3) {
				var deads = _v3.a;
				var lives = _v3.b;
				var news = _v3.c;
				return _Utils_Tuple3(
					deads,
					A3($elm$core$Dict$insert, key, pid, lives),
					news);
			});
		var newSubs = A2($elm$core$List$map, $elm$browser$Browser$Events$addKey, subs);
		var _v0 = A6(
			$elm$core$Dict$merge,
			stepLeft,
			stepBoth,
			stepRight,
			state.cu,
			$elm$core$Dict$fromList(newSubs),
			_Utils_Tuple3(_List_Nil, $elm$core$Dict$empty, _List_Nil));
		var deadPids = _v0.a;
		var livePids = _v0.b;
		var makeNewPids = _v0.c;
		return A2(
			$elm$core$Task$andThen,
			function (pids) {
				return $elm$core$Task$succeed(
					A2(
						$elm$browser$Browser$Events$State,
						newSubs,
						A2(
							$elm$core$Dict$union,
							livePids,
							$elm$core$Dict$fromList(pids))));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$sequence(makeNewPids);
				},
				$elm$core$Task$sequence(
					A2($elm$core$List$map, $elm$core$Process$kill, deadPids))));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$browser$Browser$Events$onSelfMsg = F3(
	function (router, _v0, state) {
		var key = _v0.ey;
		var event = _v0.b5;
		var toMessage = function (_v2) {
			var subKey = _v2.a;
			var _v3 = _v2.b;
			var node = _v3.a;
			var name = _v3.b;
			var decoder = _v3.c;
			return _Utils_eq(subKey, key) ? A2(_Browser_decodeEvent, decoder, event) : $elm$core$Maybe$Nothing;
		};
		var messages = A2($elm$core$List$filterMap, toMessage, state.cR);
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$sendToApp(router),
					messages)));
	});
var $elm$browser$Browser$Events$subMap = F2(
	function (func, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var decoder = _v0.c;
		return A3(
			$elm$browser$Browser$Events$MySub,
			node,
			name,
			A2($elm$json$Json$Decode$map, func, decoder));
	});
_Platform_effectManagers['Browser.Events'] = _Platform_createManager($elm$browser$Browser$Events$init, $elm$browser$Browser$Events$onEffects, $elm$browser$Browser$Events$onSelfMsg, 0, $elm$browser$Browser$Events$subMap);
var $elm$browser$Browser$Events$subscription = _Platform_leaf('Browser.Events');
var $elm$browser$Browser$Events$on = F3(
	function (node, name, decoder) {
		return $elm$browser$Browser$Events$subscription(
			A3($elm$browser$Browser$Events$MySub, node, name, decoder));
	});
var $elm$browser$Browser$Events$onResize = function (func) {
	return A3(
		$elm$browser$Browser$Events$on,
		1,
		'resize',
		A2(
			$elm$json$Json$Decode$field,
			'target',
			A3(
				$elm$json$Json$Decode$map2,
				func,
				A2($elm$json$Json$Decode$field, 'innerWidth', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'innerHeight', $elm$json$Json$Decode$int))));
};
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $author$project$Ports$onStudentChange = _Platform_incomingPort('onStudentChange', $elm$json$Json$Decode$value);
var $author$project$Ports$onUrlChange = _Platform_incomingPort('onUrlChange', $elm$json$Json$Decode$string);
var $author$project$Modal$InternalHide = {$: 3};
var $author$project$Modal$NoOp = {$: 4};
var $author$project$Modal$escDecoder = A2(
	$elm$json$Json$Decode$map,
	function (k) {
		return (k === 'Escape') ? $author$project$Modal$InternalHide : $author$project$Modal$NoOp;
	},
	A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string));
var $elm$browser$Browser$Events$Document = 0;
var $elm$browser$Browser$Events$onKeyDown = A2($elm$browser$Browser$Events$on, 0, 'keydown');
var $author$project$Modal$subscriptions = function (model) {
	return model.x ? $elm$browser$Browser$Events$onKeyDown($author$project$Modal$escDecoder) : $elm$core$Platform$Sub$none;
};
var $author$project$Scaffold$subscriptions = F2(
	function (appSubs, model) {
		if (model.$ === 1) {
			var external = model.a;
			var appModel = model.b;
			var modal = model.c;
			return $elm$core$Platform$Sub$batch(
				_List_fromArray(
					[
						A2(
						$elm$core$Platform$Sub$map,
						$author$project$Scaffold$AppMsg,
						A2(appSubs, external, appModel)),
						$author$project$Ports$onLanguageChange($author$project$Scaffold$LanguageChanged),
						$author$project$Ports$onStudentChange($author$project$Scaffold$StudentChanged),
						$author$project$Ports$onUrlChange($author$project$Scaffold$UrlChanged),
						A2(
						$elm$core$Platform$Sub$map,
						$author$project$Scaffold$ModalMsg,
						$author$project$Modal$subscriptions(modal)),
						$elm$browser$Browser$Events$onResize($author$project$Scaffold$WindowResize)
					]));
		} else {
			return $elm$core$Platform$Sub$none;
		}
	});
var $author$project$Scaffold$CompleteInitialisation = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Scaffold$Initialised = F4(
	function (a, b, c, d) {
		return {$: 1, a: a, b: b, c: c, d: d};
	});
var $author$project$Scaffold$ReceivedTranslations = function (a) {
	return {$: 8, a: a};
};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$map = F2(
	function (func, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				A2(func, key, value),
				A2($elm$core$Dict$map, func, left),
				A2($elm$core$Dict$map, func, right));
		}
	});
var $author$project$Translation$translate = F2(
	function (_v0, textOrKey) {
		var dictionary = _v0;
		if (!textOrKey.$) {
			var txt = textOrKey.a;
			return txt;
		} else {
			var key = textOrKey.a;
			var _default = textOrKey.b;
			return A2(
				$elm$core$Maybe$withDefault,
				_default,
				A2(
					$elm$core$Dict$get,
					$elm$core$String$fromInt(key),
					dictionary));
		}
	});
var $author$project$Translation$TranslationLookup = $elm$core$Basics$identity;
var $author$project$Translation$translationLookup = $elm$core$Basics$identity;
var $author$project$ScaffoldData$createTranslationFunc = F2(
	function (lang, blurbCache) {
		return $author$project$Translation$translate(
			$author$project$Translation$translationLookup(
				A2(
					$elm$core$Dict$map,
					F2(
						function (_v0, _v1) {
							var value = _v1.f3;
							return value;
						}),
					A2(
						$elm$core$Maybe$withDefault,
						$elm$core$Dict$empty,
						A2($elm$core$Dict$get, lang, blurbCache)))));
	});
var $author$project$Scaffold$decodeStudentErrorBlurb = A2($author$project$Translation$KeyWithDefault, 728351, 'Unable to load student details');
var $author$project$Modal$defaultConfig = function (toggleEffect) {
	return {x: true, E: toggleEffect};
};
var $elm$json$Json$Encode$dict = F3(
	function (toKey, toValue, dictionary) {
		return _Json_wrap(
			A3(
				$elm$core$Dict$foldl,
				F3(
					function (key, value, obj) {
						return A3(
							_Json_addField,
							toKey(key),
							toValue(value),
							obj);
					}),
				_Json_emptyObject(0),
				dictionary));
	});
var $elm$json$Json$Encode$int = _Json_wrap;
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $author$project$ScaffoldData$encodeCachedBlurb = function (blurb) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'loadedAtPosix',
				$elm$json$Json$Encode$int(
					$elm$time$Time$posixToMillis(blurb.eI))),
				_Utils_Tuple2(
				'maxAge',
				$elm$json$Json$Encode$int(blurb.eQ)),
				_Utils_Tuple2(
				'value',
				$elm$json$Json$Encode$string(blurb.f3))
			]));
};
var $author$project$ScaffoldData$encodeBlurbCache = A2(
	$elm$json$Json$Encode$dict,
	$elm$core$Basics$identity,
	A2($elm$json$Json$Encode$dict, $elm$core$Basics$identity, $author$project$ScaffoldData$encodeCachedBlurb));
var $author$project$Scaffold$errorTitleBlurb = A2($author$project$Translation$KeyWithDefault, 728349, 'Error!');
var $author$project$Scaffold$extraBlurbs = _List_fromArray(
	[$author$project$Scaffold$errorTitleBlurb, $author$project$Scaffold$decodeStudentErrorBlurb]);
var $author$project$HttpUtils$logMessageFromHttpError = function (err) {
	switch (err.$) {
		case 1:
			return $elm$core$Maybe$Nothing;
		case 2:
			return $elm$core$Maybe$Nothing;
		case 3:
			var status = err.a;
			if (status === 401) {
				return $elm$core$Maybe$Just('401 received from the server. This should have been intercepted by the decorated XHR object');
			} else {
				return $elm$core$Maybe$Nothing;
			}
		case 4:
			var text = err.a;
			return $elm$core$Maybe$Just('Unable to decode the response from the api: ' + text);
		default:
			var url = err.a;
			return $elm$core$Maybe$Just('Malformed url: ' + url);
	}
};
var $author$project$Messages$httpErrorMessage = F4(
	function (displayTitle, displayMessage, context, err) {
		return {
			ae: A2(
				$elm$core$Maybe$map,
				$author$project$Messages$MessageDisplay(displayTitle),
				displayMessage),
			ap: A2(
				$elm$core$Maybe$map,
				$author$project$Messages$LogMessage(context),
				$author$project$HttpUtils$logMessageFromHttpError(err))
		};
	});
var $author$project$Modal$init = function (config) {
	return {x: config.x, U: false, E: config.E, M: false};
};
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$url$Url$Builder$toQueryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return key + ('=' + value);
};
var $elm$url$Url$Builder$toQuery = function (parameters) {
	if (!parameters.b) {
		return '';
	} else {
		return '?' + A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, $elm$url$Url$Builder$toQueryPair, parameters));
	}
};
var $elm$url$Url$Builder$absolute = F2(
	function (pathSegments, parameters) {
		return '/' + (A2($elm$core$String$join, '/', pathSegments) + $elm$url$Url$Builder$toQuery(parameters));
	});
var $author$project$ServerPath$serverPathParts = _List_fromArray(
	['api', 'bff']);
var $author$project$ServerPath$serverPath = A2($elm$url$Url$Builder$absolute, $author$project$ServerPath$serverPathParts, _List_Nil);
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$http$Http$stringResolver = A2(_Http_expect, '', $elm$core$Basics$identity);
var $author$project$HttpUtils$standardStringResolver = function (decoder) {
	return $elm$http$Http$stringResolver(
		function (resp) {
			switch (resp.$) {
				case 0:
					var url = resp.a;
					return $elm$core$Result$Err(
						$elm$http$Http$BadUrl(url));
				case 1:
					return $elm$core$Result$Err($elm$http$Http$Timeout);
				case 2:
					return $elm$core$Result$Err($elm$http$Http$NetworkError);
				case 3:
					var metadata = resp.a;
					return $elm$core$Result$Err(
						$elm$http$Http$BadStatus(metadata.cO));
				default:
					var body = resp.b;
					var _v1 = A2($elm$json$Json$Decode$decodeString, decoder, body);
					if (!_v1.$) {
						var value = _v1.a;
						return $elm$core$Result$Ok(value);
					} else {
						var err = _v1.a;
						return $elm$core$Result$Err(
							$elm$http$Http$BadBody(
								$elm$json$Json$Decode$errorToString(err)));
					}
			}
		});
};
var $elm$core$Task$fail = _Scheduler_fail;
var $elm$http$Http$resultToTask = function (result) {
	if (!result.$) {
		var a = result.a;
		return $elm$core$Task$succeed(a);
	} else {
		var x = result.a;
		return $elm$core$Task$fail(x);
	}
};
var $elm$http$Http$task = function (r) {
	return A3(
		_Http_toTask,
		0,
		$elm$http$Http$resultToTask,
		{c0: false, bV: r.bV, af: r.bz, a6: r.a6, be: r.be, bL: r.bL, cV: $elm$core$Maybe$Nothing, aG: r.aG});
};
var $author$project$ScaffoldApi$loadStudentTask = $elm$http$Http$task(
	{
		bV: $elm$http$Http$emptyBody,
		a6: _List_Nil,
		be: 'get',
		bz: $author$project$HttpUtils$standardStringResolver($author$project$Student$studentDecoder),
		bL: $elm$core$Maybe$Nothing,
		aG: $author$project$ServerPath$serverPath + '/auth/student'
	});
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$ScaffoldData$findHardCodedStrings = $elm$core$List$filterMap(
	function (b) {
		if (!b.$) {
			var txt = b.a;
			return $elm$core$Maybe$Just(txt);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$HttpUtils$whateverResolver = $elm$http$Http$stringResolver(
	function (resp) {
		switch (resp.$) {
			case 0:
				var url = resp.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = resp.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.cO));
			default:
				return $elm$core$Result$Ok(0);
		}
	});
var $author$project$ScaffoldApi$logHardCodedStrings = function (allBlurbs) {
	var hardcodedStrings = $author$project$ScaffoldData$findHardCodedStrings(allBlurbs);
	return $elm$core$List$isEmpty(hardcodedStrings) ? $elm$core$Task$succeed(0) : A2(
		$elm$core$Task$onError,
		function (_v0) {
			return $elm$core$Task$succeed(0);
		},
		$elm$http$Http$task(
			{
				bV: $elm$http$Http$jsonBody(
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'message',
								$elm$json$Json$Encode$string('The following hard coded strings must be replaced with blurbs:')),
								_Utils_Tuple2(
								'strings',
								A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, hardcodedStrings))
							]))),
				a6: _List_Nil,
				be: 'post',
				bz: $author$project$HttpUtils$whateverResolver,
				bL: $elm$core$Maybe$Nothing,
				aG: $author$project$ServerPath$serverPath + '/logging/error'
			}));
};
var $author$project$ScaffoldData$blurbExpired = F2(
	function (now, _v0) {
		var loadedAt = _v0.eI;
		var maxAge = _v0.eQ;
		var nowMs = $elm$time$Time$posixToMillis(now);
		var loadedAtMs = $elm$time$Time$posixToMillis(loadedAt);
		var expiresAtMs = loadedAtMs + (maxAge * 1000);
		return _Utils_cmp(expiresAtMs, nowMs) < 0;
	});
var $author$project$ScaffoldData$blurbIsMissing = F4(
	function (now, blurbCache, lang, textOrKey) {
		if (!textOrKey.$) {
			return $elm$core$Maybe$Nothing;
		} else {
			var key = textOrKey.a;
			var _v1 = A2($elm$core$Dict$get, lang, blurbCache);
			if (_v1.$ === 1) {
				return $elm$core$Maybe$Just(key);
			} else {
				var lookup = _v1.a;
				var _v2 = A2(
					$elm$core$Dict$get,
					$elm$core$String$fromInt(key),
					lookup);
				if (_v2.$ === 1) {
					return $elm$core$Maybe$Just(key);
				} else {
					var cachedBlurb = _v2.a;
					return A2($author$project$ScaffoldData$blurbExpired, now, cachedBlurb) ? $elm$core$Maybe$Just(key) : $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $author$project$ScaffoldData$missingBlurbKeys = F4(
	function (requiredBlurbs, blurbCache, now, lang) {
		return A2(
			$elm$core$List$filterMap,
			A3($author$project$ScaffoldData$blurbIsMissing, now, blurbCache, lang),
			requiredBlurbs);
	});
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $elm$core$Basics$round = _Basics_round;
var $author$project$DateUtils$floatToPosix = A2($elm$core$Basics$composeR, $elm$core$Basics$round, $elm$time$Time$millisToPosix);
var $author$project$ScaffoldData$translatedResourceToCachedBlurb = function (_v0) {
	var loadedAtPosix = _v0.eJ;
	var maxAge = _v0.eQ;
	var value = _v0.f3;
	return {
		eI: $author$project$DateUtils$floatToPosix(loadedAtPosix),
		eQ: $elm$core$Basics$round(maxAge),
		f3: value
	};
};
var $author$project$ScaffoldData$mergeBlurbs = F3(
	function (lang, transResp, blurbCache) {
		return A3(
			$elm$core$Dict$insert,
			lang,
			A3(
				$elm$core$List$foldr,
				F2(
					function (_v0, dict) {
						var k = _v0.a;
						var v = _v0.b;
						return A3(
							$elm$core$Dict$insert,
							k,
							$author$project$ScaffoldData$translatedResourceToCachedBlurb(v),
							dict);
					}),
				A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Dict$empty,
					A2($elm$core$Dict$get, lang, blurbCache)),
				$elm$core$Dict$toList(transResp)),
			blurbCache);
	});
var $author$project$ApiTypes$Translation$TranslatedResource$TranslatedResource = F4(
	function (maxAge, value, loadedAt, loadedAtPosix) {
		return {eI: loadedAt, eJ: loadedAtPosix, eQ: maxAge, f3: value};
	});
var $author$project$ScaffoldApi$translatedResourceDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'loadedAtPosix', $elm$json$Json$Decode$float),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'loadedAt', $elm$json$Json$Decode$string),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$string),
			A2(
				$author$project$JsonUtils$apply,
				A2($elm$json$Json$Decode$field, 'maxAge', $elm$json$Json$Decode$float),
				$elm$json$Json$Decode$succeed($author$project$ApiTypes$Translation$TranslatedResource$TranslatedResource)))));
var $author$project$ScaffoldApi$translationResponseDecoder = F2(
	function (lang, blurbCache) {
		return A2(
			$elm$json$Json$Decode$map,
			function (transResp) {
				return A3($author$project$ScaffoldData$mergeBlurbs, lang, transResp, blurbCache);
			},
			$elm$json$Json$Decode$dict($author$project$ScaffoldApi$translatedResourceDecoder));
	});
var $author$project$ScaffoldApi$loadTranslationsTask = F3(
	function (allBlurbs, lang, blurbCache) {
		return A2(
			$elm$core$Task$andThen,
			function (now) {
				return A2(
					$elm$core$Task$andThen,
					function (_v0) {
						var keys = A4($author$project$ScaffoldData$missingBlurbKeys, allBlurbs, blurbCache, now, lang);
						return $elm$core$List$isEmpty(keys) ? $elm$core$Task$succeed(blurbCache) : $elm$http$Http$task(
							{
								bV: $elm$http$Http$jsonBody(
									$elm$json$Json$Encode$object(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'lang',
												$elm$json$Json$Encode$string(lang)),
												_Utils_Tuple2(
												'keys',
												A2(
													$elm$json$Json$Encode$list,
													A2($elm$core$Basics$composeR, $elm$core$String$fromInt, $elm$json$Json$Encode$string),
													keys))
											]))),
								a6: _List_Nil,
								be: 'post',
								bz: $author$project$HttpUtils$standardStringResolver(
									A2($author$project$ScaffoldApi$translationResponseDecoder, lang, blurbCache)),
								bL: $elm$core$Maybe$Nothing,
								aG: $author$project$ServerPath$serverPath + '/translate'
							});
					},
					$author$project$ScaffoldApi$logHardCodedStrings(allBlurbs));
			},
			$elm$time$Time$now);
	});
var $author$project$ScaffoldApi$loadInitialData = F3(
	function (allBlurbs, flags, onResult) {
		return A2(
			$elm$core$Task$attempt,
			onResult,
			A2(
				$elm$core$Task$andThen,
				function (blurbCache) {
					var _v0 = flags.fF;
					if (!_v0.$) {
						var student = _v0.a;
						return $elm$core$Task$succeed(
							{c7: blurbCache, fF: student});
					} else {
						return A2(
							$elm$core$Task$map,
							function (s) {
								return {c7: blurbCache, fF: s};
							},
							$author$project$ScaffoldApi$loadStudentTask);
					}
				},
				A3($author$project$ScaffoldApi$loadTranslationsTask, allBlurbs, flags.B, flags.O)));
	});
var $author$project$ScaffoldApi$loadTranslations = F4(
	function (allBlurbs, lang, blurbCache, onResult) {
		return A2(
			$elm$core$Task$attempt,
			onResult,
			A3($author$project$ScaffoldApi$loadTranslationsTask, allBlurbs, lang, blurbCache));
	});
var $author$project$ScaffoldData$ExtraLargeScreen = 3;
var $author$project$Scaffold$mediaUrlBase = 'https://et2.ef-cdn.com/';
var $author$project$Scaffold$mediaUrl = function (path) {
	return A2($elm$core$String$startsWith, 'http', path) ? path : _Utils_ap($author$project$Scaffold$mediaUrlBase, path);
};
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$ScaffoldData$LargeScreen = 2;
var $author$project$ScaffoldData$MediumScreen = 1;
var $author$project$ScaffoldData$SmallScreen = 0;
var $author$project$Scaffold$screenSizeFromWidth = function (width) {
	return (width < 768) ? 0 : ((width < 1024) ? 1 : ((width < 1264) ? 2 : 3));
};
var $author$project$Scaffold$makeExternalState = F5(
	function (flags, blurbCache, viewport, allBlurbs, student) {
		var screenSizeFromViewport = A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.f5;
			},
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.f6;
				},
				A2($elm$core$Basics$composeR, $elm$core$Basics$round, $author$project$Scaffold$screenSizeFromWidth)));
		var screenSize = A2(
			$elm$core$Maybe$withDefault,
			3,
			A2($elm$core$Maybe$map, screenSizeFromViewport, viewport));
		return _Utils_Tuple2(
			{
				Z: allBlurbs,
				O: blurbCache,
				ab: flags.G.ab,
				ad: flags.G.ad,
				ag: flags.ag,
				d5: flags.d5,
				aj: flags.G.aj,
				ak: flags.ak,
				B: flags.B,
				ao: flags.G.ao,
				cn: $author$project$Scaffold$mediaUrl,
				e9: flags.G.e9,
				ay: screenSize,
				az: flags.G.az,
				fF: student,
				fX: A2($author$project$ScaffoldData$createTranslationFunc, flags.B, blurbCache),
				aG: flags.aG,
				aH: $elm$core$Dict$fromList(flags.aH)
			},
			$elm$core$Platform$Cmd$none);
	});
var $elm$core$Platform$Cmd$map = _Platform_map;
var $elm$json$Json$Encode$bool = _Json_wrap;
var $author$project$Ports$modalShowing = _Platform_outgoingPort('modalShowing', $elm$json$Json$Encode$bool);
var $author$project$Modal$FadeOut = function (a) {
	return {$: 2, a: a};
};
var $author$project$Modal$fadeDelay = 200;
var $elm$core$Process$sleep = _Process_sleep;
var $author$project$Modal$hide = function (model) {
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{M: false}),
		A2(
			$elm$core$Task$perform,
			$author$project$Modal$FadeOut,
			$elm$core$Process$sleep($author$project$Modal$fadeDelay)));
};
var $author$project$Modal$FadeIn = function (a) {
	return {$: 1, a: a};
};
var $author$project$Modal$show = function (model) {
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{U: true, M: false}),
		A2(
			$elm$core$Task$perform,
			$author$project$Modal$FadeIn,
			$elm$core$Process$sleep($author$project$Modal$fadeDelay / 2)));
};
var $author$project$Scaffold$processEffects = F4(
	function (modal, cmds, config, effs) {
		switch (effs.$) {
			case 4:
				return _Utils_Tuple3(modal, cmds, config);
			case 3:
				var curved = effs.a;
				var inverted = effs.b;
				var content = effs.c;
				var _v1 = $author$project$Modal$show(modal);
				var m = _v1.a;
				var c = _v1.b;
				return _Utils_Tuple3(
					m,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								cmds,
								A2($elm$core$Platform$Cmd$map, $author$project$Scaffold$ModalMsg, c)
							])),
					$elm$core$Maybe$Just(
						{aV: content, aZ: curved, a9: inverted}));
			case 2:
				var _v2 = $author$project$Modal$hide(modal);
				var m = _v2.a;
				var c = _v2.b;
				return _Utils_Tuple3(
					m,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								cmds,
								A2($elm$core$Platform$Cmd$map, $author$project$Scaffold$ModalMsg, c)
							])),
					config);
			case 0:
				var cmd = effs.b;
				return _Utils_Tuple3(
					modal,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								cmds,
								A2($elm$core$Platform$Cmd$map, $author$project$Scaffold$AppMsg, cmd)
							])),
					config);
			default:
				var childEffs = effs.a;
				return A3(
					$elm$core$List$foldl,
					F2(
						function (eff_, _v3) {
							var modal_ = _v3.a;
							var cmds_ = _v3.b;
							var config_ = _v3.c;
							return A4($author$project$Scaffold$processEffects, modal_, cmds_, config_, eff_);
						}),
					_Utils_Tuple3(modal, cmds, config),
					childEffs);
		}
	});
var $elm$core$Basics$not = _Basics_not;
var $author$project$Modal$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 4:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Platform$Cmd$none);
			case 0:
				var p = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$elm$core$Task$perform,
						$elm$core$Basics$identity,
						$elm$core$Task$succeed(p)),
					$elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{M: true}),
					$elm$core$Platform$Cmd$none,
					model.E(true));
			case 2:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{U: false}),
					$elm$core$Platform$Cmd$none,
					model.E(false));
			default:
				if (!model.x) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Platform$Cmd$none);
				} else {
					var _v1 = $author$project$Modal$hide(model);
					var m = _v1.a;
					var c = _v1.b;
					return _Utils_Tuple3(m, $elm$core$Platform$Cmd$none, c);
				}
		}
	});
var $author$project$Ports$updateBlurbCache = _Platform_outgoingPort('updateBlurbCache', $elm$core$Basics$identity);
var $author$project$Scaffold$update = F4(
	function (appInit, appUpdate, msg, model) {
		switch (msg.$) {
			case 7:
				var resources = msg.a;
				var viewport = msg.b;
				if (!model.$) {
					var flags = model.a;
					var allBlurbs = _Utils_ap(
						$author$project$Scaffold$extraBlurbs,
						_Utils_ap(resources, flags.dW));
					return _Utils_Tuple2(
						A2(
							$author$project$Scaffold$Uninitialised,
							flags,
							$elm$core$Maybe$Just(viewport)),
						A3(
							$author$project$ScaffoldApi$loadInitialData,
							allBlurbs,
							flags,
							$author$project$Scaffold$CompleteInitialisation(allBlurbs)));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 3:
				var url = msg.a;
				if (model.$ === 1) {
					var external = model.a;
					var appModel = model.b;
					var modal = model.c;
					var modalContent = model.d;
					return _Utils_Tuple2(
						A4(
							$author$project$Scaffold$Initialised,
							_Utils_update(
								external,
								{aG: url}),
							appModel,
							modal,
							modalContent),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 2:
				var encoded = msg.a;
				switch (model.$) {
					case 1:
						var external = model.a;
						var translate = external.fX;
						var appModel = model.b;
						var modal = model.c;
						var modalContent = model.d;
						var _v4 = A2($elm$json$Json$Decode$decodeValue, $author$project$Student$studentDecoder, encoded);
						if (_v4.$ === 1) {
							var err = _v4.a;
							return _Utils_Tuple2(
								A4($author$project$Scaffold$Initialised, external, appModel, modal, modalContent),
								$author$project$Ports$bubbleError(
									A4(
										$author$project$Messages$decodingErrorMessage,
										translate($author$project$Scaffold$errorTitleBlurb),
										$elm$core$Maybe$Just(
											translate($author$project$Scaffold$decodeStudentErrorBlurb)),
										'Decoding Student',
										err)));
						} else {
							var student = _v4.a;
							return _Utils_Tuple2(
								A4(
									$author$project$Scaffold$Initialised,
									_Utils_update(
										external,
										{fF: student}),
									appModel,
									modal,
									modalContent),
								$elm$core$Platform$Cmd$none);
						}
					case 0:
						var flags = model.a;
						var viewport = model.b;
						return _Utils_Tuple2(
							A2($author$project$Scaffold$Uninitialised, flags, viewport),
							$elm$core$Platform$Cmd$none);
					default:
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 1:
				if (msg.b.$ === 1) {
					var err = msg.b.a;
					return _Utils_Tuple2(
						model,
						$author$project$Ports$bubbleError(
							A4(
								$author$project$Messages$httpErrorMessage,
								'Error!',
								$elm$core$Maybe$Just('Unable to initialise the school'),
								'GetNextGroupClass',
								err)));
				} else {
					var allBlurbs = msg.a;
					var blurbs = msg.b.a.c7;
					var student = msg.b.a.fF;
					switch (model.$) {
						case 0:
							var flags = model.a;
							var viewport = model.b;
							var modal = $author$project$Modal$init(
								$author$project$Modal$defaultConfig($author$project$Ports$modalShowing));
							var _v6 = A5($author$project$Scaffold$makeExternalState, flags, blurbs, viewport, allBlurbs, student);
							var external = _v6.a;
							var cmd = _v6.b;
							var _v7 = appInit(external);
							var appModel = _v7.a;
							var appEffects = _v7.b;
							var _v8 = A4($author$project$Scaffold$processEffects, modal, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing, appEffects);
							var updatedModal = _v8.a;
							var initCmds = _v8.b;
							var updatedModalConfig = _v8.c;
							return _Utils_Tuple2(
								A4($author$project$Scaffold$Initialised, external, appModel, updatedModal, updatedModalConfig),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											initCmds,
											cmd,
											$author$project$Ports$updateBlurbCache(
											$author$project$ScaffoldData$encodeBlurbCache(blurbs))
										])));
						case 1:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						default:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				}
			case 8:
				if (msg.a.$ === 1) {
					var err = msg.a.a;
					return _Utils_Tuple2(
						model,
						$author$project$Ports$bubbleError(
							A4(
								$author$project$Messages$httpErrorMessage,
								'Error!',
								$elm$core$Maybe$Just('Error retrieving translations'),
								'GetTranslations',
								err)));
				} else {
					var blurbCache = msg.a.a;
					switch (model.$) {
						case 1:
							var external = model.a;
							var appModel = model.b;
							var modal = model.c;
							var modalContent = model.d;
							return _Utils_Tuple2(
								A4(
									$author$project$Scaffold$Initialised,
									_Utils_update(
										external,
										{
											O: blurbCache,
											fX: A2($author$project$ScaffoldData$createTranslationFunc, external.B, blurbCache)
										}),
									appModel,
									modal,
									modalContent),
								$author$project$Ports$updateBlurbCache(
									$author$project$ScaffoldData$encodeBlurbCache(blurbCache)));
						case 0:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						default:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				}
			case 0:
				var lang = msg.a;
				switch (model.$) {
					case 1:
						var external = model.a;
						var appModel = model.b;
						var modal = model.c;
						var modalContent = model.d;
						return _Utils_Tuple2(
							A4(
								$author$project$Scaffold$Initialised,
								_Utils_update(
									external,
									{B: lang}),
								appModel,
								modal,
								modalContent),
							A4($author$project$ScaffoldApi$loadTranslations, external.Z, lang, external.O, $author$project$Scaffold$ReceivedTranslations));
					case 0:
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					default:
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 4:
				var appMsg = msg.a;
				if (model.$ === 1) {
					var external = model.a;
					var appModel = model.b;
					var modal = model.c;
					var modalConfig = model.d;
					var _v12 = A3(appUpdate, external, appMsg, appModel);
					var updatedAppModel = _v12.a;
					var appEffects = _v12.b;
					var _v13 = A4($author$project$Scaffold$processEffects, modal, $elm$core$Platform$Cmd$none, modalConfig, appEffects);
					var updatedModal = _v13.a;
					var cmds = _v13.b;
					var updatedModalConfig = _v13.c;
					return _Utils_Tuple2(
						A4($author$project$Scaffold$Initialised, external, updatedAppModel, updatedModal, updatedModalConfig),
						cmds);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 5:
				var subMsg = msg.a;
				if (model.$ === 1) {
					var external = model.a;
					var appModel = model.b;
					var modal = model.c;
					var modalContent = model.d;
					var _v15 = A2($author$project$Modal$update, subMsg, modal);
					var updated = _v15.a;
					var parentCmd = _v15.b;
					var subCmds = _v15.c;
					return _Utils_Tuple2(
						A4($author$project$Scaffold$Initialised, external, appModel, updated, modalContent),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$Scaffold$ModalMsg, subCmds),
									A2($elm$core$Platform$Cmd$map, $author$project$Scaffold$AppMsg, parentCmd)
								])));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				var width = msg.a;
				if (model.$ === 1) {
					var external = model.a;
					var appModel = model.b;
					var modal = model.c;
					var modalContent = model.d;
					return _Utils_Tuple2(
						A4(
							$author$project$Scaffold$Initialised,
							_Utils_update(
								external,
								{
									ay: $author$project$Scaffold$screenSizeFromWidth(width)
								}),
							appModel,
							modal,
							modalContent),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Modal$ModalContent = $elm$core$Basics$identity;
var $author$project$Modal$Parent = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $author$project$Modal$wrap = $elm$html$Html$map($author$project$Modal$Parent);
var $author$project$Modal$content = function (child) {
	return function (_v0) {
		return $author$project$Modal$wrap(child);
	};
};
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$HtmlUtils$empty = $elm$html$Html$text('');
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $author$project$Icons$CloseIcon$icon = A2(
	$elm$svg$Svg$svg,
	_List_fromArray(
		[
			$elm$svg$Svg$Attributes$viewBox('0 0 30 30')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M25.29 27a1 1 0 01-.7-.29L5.29 7.41a1 1 0 010-1.41L6 5.29a1 1 0 011.41 0l19.3 19.3a1 1 0 010 1.41l-.71.71a1 1 0 01-.71.29z')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M6.71 27a1 1 0 01-.71-.29L5.29 26a1 1 0 010-1.41l19.3-19.3a1 1 0 011.41 0l.71.71a1 1 0 010 1.41l-19.3 19.3a1 1 0 01-.7.29z')
				]),
			_List_Nil)
		]));
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $author$project$Modal$modal = F4(
	function (curved, inverted, model, _v0) {
		var renderContent = _v0;
		return model.U ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-modal js-modal'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('is-visible', model.M)
						])),
					$elm$html$Html$Events$onClick($author$project$Modal$InternalHide)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-modal__container')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-modal__inner-w'),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('-curved', curved)
										])),
									A2($elm$html$Html$Attributes$attribute, 'data-qa', 'modal'),
									A2(
									$elm$html$Html$Events$stopPropagationOn,
									'click',
									$elm$json$Json$Decode$succeed(
										_Utils_Tuple2($author$project$Modal$NoOp, true)))
								]),
							_List_fromArray(
								[
									model.x ? A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('c-modal__close-btn'),
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													_Utils_Tuple2('-inverted', inverted)
												])),
											A2($elm$html$Html$Attributes$attribute, 'data-qa', 'modal-close'),
											A2($elm$html$Html$Attributes$attribute, 'tabindex', '0'),
											$elm$html$Html$Events$onClick($author$project$Modal$InternalHide)
										]),
									_List_fromArray(
										[$author$project$Icons$CloseIcon$icon])) : $author$project$HtmlUtils$empty,
									renderContent(model)
								]))
						]))
				])) : $author$project$HtmlUtils$empty;
	});
var $author$project$Scaffold$view = F3(
	function (appView, loadingView, model) {
		switch (model.$) {
			case 1:
				var external = model.a;
				var appModel = model.b;
				var modal = model.c;
				var modalConfig = model.d;
				return A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							$author$project$Scaffold$AppMsg,
							A2(appView, external, appModel)),
							function () {
							if (!modalConfig.$) {
								var curved = modalConfig.a.aZ;
								var inverted = modalConfig.a.a9;
								var content = modalConfig.a.aV;
								return A2(
									$elm$html$Html$map,
									$author$project$Scaffold$ModalMsg,
									A2(
										$elm$html$Html$div,
										_List_Nil,
										_List_fromArray(
											[
												A4(
												$author$project$Modal$modal,
												curved,
												inverted,
												modal,
												$author$project$Modal$content(
													A2(content, external, appModel)))
											])));
							} else {
								return $author$project$HtmlUtils$empty;
							}
						}()
						]));
			case 0:
				return A2($elm$html$Html$map, $author$project$Scaffold$AppMsg, loadingView);
			default:
				return A2($elm$html$Html$map, $author$project$Scaffold$AppMsg, loadingView);
		}
	});
var $author$project$Scaffold$bootstrap = F6(
	function (resources, appView, loadingView, appInit, appUpdate, appSubs) {
		return $elm$browser$Browser$element(
			{
				es: $author$project$Scaffold$init(resources),
				fJ: $author$project$Scaffold$subscriptions(appSubs),
				f1: A2($author$project$Scaffold$update, appInit, appUpdate),
				f4: A2($author$project$Scaffold$view, appView, loadingView)
			});
	});
var $author$project$Data$DataReceived = function (a) {
	return {$: 0, a: a};
};
var $author$project$Data$LoadDataEffect = {$: 0};
var $krisajenkins$remotedata$RemoteData$Loading = {$: 1};
var $author$project$Data$ServerTimeEffect = {$: 1};
var $author$project$Data$ServerTimeMsg = function (a) {
	return {$: 14, a: a};
};
var $author$project$Api$apiPath = $author$project$ServerPath$serverPath + '/home';
var $author$project$Scaffold$BatchEffect = function (a) {
	return {$: 1, a: a};
};
var $author$project$EffectUtils$batchEffect = $author$project$Scaffold$BatchEffect;
var $author$project$Scaffold$Effect = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$EffectUtils$fromCmd = $author$project$Scaffold$Effect;
var $author$project$ServerTime$TimeDeltaReceived = function (a) {
	return {$: 0, a: a};
};
var $author$project$ServerTime$UpdateTime = function (a) {
	return {$: 1, a: a};
};
var $author$project$ServerTime$getServerTimeDelta = F2(
	function (apiPath, onResult) {
		return A2(
			$elm$core$Task$attempt,
			onResult,
			A3(
				$elm$core$Task$map2,
				F2(
					function (serverTime, myTime) {
						return _Utils_Tuple2(
							serverTime - $elm$time$Time$posixToMillis(myTime),
							myTime);
					}),
				$elm$http$Http$task(
					{
						bV: $elm$http$Http$emptyBody,
						a6: _List_Nil,
						be: 'get',
						bz: $author$project$HttpUtils$standardStringResolver($elm$json$Json$Decode$int),
						bL: $elm$core$Maybe$Nothing,
						aG: apiPath + '/servertime'
					}),
				$elm$time$Time$now));
	});
var $author$project$ServerTime$init = function (apiPath) {
	return _Utils_Tuple2(
		{
			R: $elm$time$Time$millisToPosix(0),
			aE: 0
		},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A2($author$project$ServerTime$getServerTimeDelta, apiPath, $author$project$ServerTime$TimeDeltaReceived),
					A2($elm$core$Task$perform, $author$project$ServerTime$UpdateTime, $elm$time$Time$now)
				])));
};
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.cO));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $krisajenkins$remotedata$RemoteData$Failure = function (a) {
	return {$: 2, a: a};
};
var $krisajenkins$remotedata$RemoteData$Success = function (a) {
	return {$: 3, a: a};
};
var $krisajenkins$remotedata$RemoteData$fromResult = function (result) {
	if (result.$ === 1) {
		var e = result.a;
		return $krisajenkins$remotedata$RemoteData$Failure(e);
	} else {
		var x = result.a;
		return $krisajenkins$remotedata$RemoteData$Success(x);
	}
};
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {cE: reqs, cR: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.cV;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.cE));
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.cR)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					c0: r.c0,
					bV: r.bV,
					af: A2(_Http_mapExpect, func, r.af),
					a6: r.a6,
					be: r.be,
					bL: r.bL,
					cV: r.cV,
					aG: r.aG
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{c0: false, bV: r.bV, af: r.af, a6: r.a6, be: r.be, bL: r.bL, cV: r.cV, aG: r.aG}));
};
var $elm$http$Http$get = function (r) {
	return $elm$http$Http$request(
		{bV: $elm$http$Http$emptyBody, af: r.af, a6: _List_Nil, be: 'GET', bL: $elm$core$Maybe$Nothing, cV: $elm$core$Maybe$Nothing, aG: r.aG});
};
var $author$project$ApiTypes$Home$Index$HomePage = F7(
	function (onTrack, targetCompletionDate, overallProgress, selfStudy, groupClasses, privateClasses, scheduledClasses) {
		return {ee: groupClasses, e1: onTrack, e7: overallProgress, cw: privateClasses, bA: scheduledClasses, fs: selfStudy, fO: targetCompletionDate};
	});
var $author$project$ApiTypes$Home$Index$GroupClassState = F2(
	function (progress, groupClass) {
		return {ed: groupClass, bv: progress};
	});
var $author$project$ApiTypes$Home$Index$GroupClassSummary = F5(
	function (name, time, attended, imageUrl, timeslot) {
		return {c4: attended, a8: imageUrl, bf: name, fU: time, fV: timeslot};
	});
var $author$project$ApiTypes$ClassTime$ClassTime = F3(
	function (start, end, classroomFreezesAt) {
		return {dj: classroomFreezesAt, dN: end, fA: start};
	});
var $author$project$ApiTypes$FormattedDate$FormattedDate = F5(
	function (posix, formatted, formattedShort, formattedDay, formattedTime) {
		return {d$: formatted, d0: formattedDay, d1: formattedShort, d2: formattedTime, fe: posix};
	});
var $author$project$DateUtils$formattedDateDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'formattedTime', $elm$json$Json$Decode$string),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'formattedDay', $elm$json$Json$Decode$string),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'formattedShort', $elm$json$Json$Decode$string),
			A2(
				$author$project$JsonUtils$apply,
				A2($elm$json$Json$Decode$field, 'formatted', $elm$json$Json$Decode$string),
				A2(
					$author$project$JsonUtils$apply,
					A2($elm$json$Json$Decode$field, 'posix', $elm$json$Json$Decode$float),
					$elm$json$Json$Decode$succeed($author$project$ApiTypes$FormattedDate$FormattedDate))))));
var $author$project$Data$classTimeDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'classroomFreezesAt', $elm$json$Json$Decode$float),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'end', $author$project$DateUtils$formattedDateDecoder),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'start', $author$project$DateUtils$formattedDateDecoder),
			$elm$json$Json$Decode$succeed($author$project$ApiTypes$ClassTime$ClassTime))));
var $author$project$ApiTypes$GroupClasses$GroupClassTimeslot = function (endTime) {
	return function (evcServer) {
		return function (freezeTime) {
			return function (isVideoClass) {
				return function (language) {
					return function (levelCode) {
						return function (marketCode) {
							return function (partnerCode) {
								return function (serviceSubType) {
									return function (serviceType) {
										return function (startTime) {
											return function (teachLanguage) {
												return function (techCheckUrlCn) {
													return function (techCheckUrlUs) {
														return {dO: endTime, dP: evcServer, d3: freezeTime, ex: isVideoClass, eA: language, eE: levelCode, eO: marketCode, e8: partnerCode, fu: serviceSubType, fv: serviceType, fB: startTime, fP: teachLanguage, fR: techCheckUrlCn, fS: techCheckUrlUs};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$GroupClasses$groupClassTimeslotDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'techCheckUrlUs', $elm$json$Json$Decode$string),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'techCheckUrlCn', $elm$json$Json$Decode$string),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'teachLanguage', $elm$json$Json$Decode$string),
			A2(
				$author$project$JsonUtils$apply,
				A2($elm$json$Json$Decode$field, 'startTime', $elm$json$Json$Decode$float),
				A2(
					$author$project$JsonUtils$apply,
					A2($elm$json$Json$Decode$field, 'serviceType', $elm$json$Json$Decode$string),
					A2(
						$author$project$JsonUtils$apply,
						A2($elm$json$Json$Decode$field, 'serviceSubType', $elm$json$Json$Decode$string),
						A2(
							$author$project$JsonUtils$apply,
							A2($elm$json$Json$Decode$field, 'partnerCode', $elm$json$Json$Decode$string),
							A2(
								$author$project$JsonUtils$apply,
								A2($elm$json$Json$Decode$field, 'marketCode', $elm$json$Json$Decode$string),
								A2(
									$author$project$JsonUtils$apply,
									A2($elm$json$Json$Decode$field, 'levelCode', $elm$json$Json$Decode$string),
									A2(
										$author$project$JsonUtils$apply,
										A2($elm$json$Json$Decode$field, 'language', $elm$json$Json$Decode$string),
										A2(
											$author$project$JsonUtils$apply,
											A2($elm$json$Json$Decode$field, 'isVideoClass', $elm$json$Json$Decode$bool),
											A2(
												$author$project$JsonUtils$apply,
												A2($elm$json$Json$Decode$field, 'freezeTime', $elm$json$Json$Decode$float),
												A2(
													$author$project$JsonUtils$apply,
													A2($elm$json$Json$Decode$field, 'evcServer', $elm$json$Json$Decode$string),
													A2(
														$author$project$JsonUtils$apply,
														A2($elm$json$Json$Decode$field, 'endTime', $elm$json$Json$Decode$float),
														$elm$json$Json$Decode$succeed($author$project$ApiTypes$GroupClasses$GroupClassTimeslot)))))))))))))));
var $author$project$Data$groupClassSummaryDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'timeslot', $author$project$GroupClasses$groupClassTimeslotDecoder),
	A2(
		$author$project$JsonUtils$apply,
		$elm$json$Json$Decode$maybe(
			A2($elm$json$Json$Decode$field, 'imageUrl', $elm$json$Json$Decode$string)),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'attended', $elm$json$Json$Decode$bool),
			A2(
				$author$project$JsonUtils$apply,
				A2($elm$json$Json$Decode$field, 'time', $author$project$Data$classTimeDecoder),
				A2(
					$author$project$JsonUtils$apply,
					A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
					$elm$json$Json$Decode$succeed($author$project$ApiTypes$Home$Index$GroupClassSummary))))));
var $author$project$ApiTypes$Home$Index$Progress = F3(
	function (complete, target, percentComplete) {
		return {dn: complete, fa: percentComplete, fN: target};
	});
var $author$project$Data$progressDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'percentComplete', $elm$json$Json$Decode$float),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'target', $elm$json$Json$Decode$float),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'complete', $elm$json$Json$Decode$float),
			$elm$json$Json$Decode$succeed($author$project$ApiTypes$Home$Index$Progress))));
var $author$project$Data$groupClassStateDecoder = A2(
	$author$project$JsonUtils$apply,
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'groupClass', $author$project$Data$groupClassSummaryDecoder)),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'progress', $author$project$Data$progressDecoder),
		$elm$json$Json$Decode$succeed($author$project$ApiTypes$Home$Index$GroupClassState)));
var $author$project$ApiTypes$Home$Index$LicensedFeatureLicensed = function (a) {
	return {$: 0, a: a};
};
var $author$project$ApiTypes$Home$Index$LicensedFeatureUnlicensed = {$: 1};
var $author$project$Data$licensedFeatureDecoder = F2(
	function (failMsg, innerDecoder) {
		return $elm$json$Json$Decode$oneOf(
			_List_fromArray(
				[
					A2($elm$json$Json$Decode$map, $author$project$ApiTypes$Home$Index$LicensedFeatureLicensed, innerDecoder),
					A2(
					$elm$json$Json$Decode$andThen,
					function (s) {
						return (s === 'unlicensed') ? $elm$json$Json$Decode$succeed($author$project$ApiTypes$Home$Index$LicensedFeatureUnlicensed) : $elm$json$Json$Decode$fail(failMsg);
					},
					$elm$json$Json$Decode$string)
				]));
	});
var $author$project$ApiTypes$Home$Index$PrivateClassState = F2(
	function (progress, privateClasses) {
		return {cw: privateClasses, bv: progress};
	});
var $author$project$ApiTypes$Home$Index$PrivateClassSummary = function (name) {
	return function (time) {
		return function (classId) {
			return function (classTypeCode) {
				return function (teacherId) {
					return function (evcServerCode) {
						return function (bookingProvider) {
							return function (lessonType) {
								return function (startTime) {
									return function (imageUrl) {
										return {c8: bookingProvider, dh: classId, di: classTypeCode, dQ: evcServerCode, a8: imageUrl, cm: lessonType, bf: name, fB: startTime, fQ: teacherId, fU: time};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Data$privateClassSummaryDecoder = A2(
	$author$project$JsonUtils$apply,
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'imageUrl', $elm$json$Json$Decode$string)),
	A2(
		$author$project$JsonUtils$apply,
		$elm$json$Json$Decode$maybe(
			A2($elm$json$Json$Decode$field, 'startTime', $elm$json$Json$Decode$string)),
		A2(
			$author$project$JsonUtils$apply,
			$elm$json$Json$Decode$maybe(
				A2($elm$json$Json$Decode$field, 'lessonType', $elm$json$Json$Decode$string)),
			A2(
				$author$project$JsonUtils$apply,
				A2($elm$json$Json$Decode$field, 'bookingProvider', $elm$json$Json$Decode$string),
				A2(
					$author$project$JsonUtils$apply,
					A2($elm$json$Json$Decode$field, 'evcServerCode', $elm$json$Json$Decode$string),
					A2(
						$author$project$JsonUtils$apply,
						$elm$json$Json$Decode$maybe(
							A2($elm$json$Json$Decode$field, 'teacherId', $elm$json$Json$Decode$string)),
						A2(
							$author$project$JsonUtils$apply,
							A2($elm$json$Json$Decode$field, 'classTypeCode', $elm$json$Json$Decode$string),
							A2(
								$author$project$JsonUtils$apply,
								A2($elm$json$Json$Decode$field, 'classId', $elm$json$Json$Decode$string),
								A2(
									$author$project$JsonUtils$apply,
									A2($elm$json$Json$Decode$field, 'time', $author$project$Data$classTimeDecoder),
									A2(
										$author$project$JsonUtils$apply,
										A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
										$elm$json$Json$Decode$succeed($author$project$ApiTypes$Home$Index$PrivateClassSummary)))))))))));
var $author$project$Data$privateClassStateDecoder = A2(
	$author$project$JsonUtils$apply,
	A2(
		$elm$json$Json$Decode$field,
		'privateClasses',
		$elm$json$Json$Decode$list($author$project$Data$privateClassSummaryDecoder)),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'progress', $author$project$Data$progressDecoder),
		$elm$json$Json$Decode$succeed($author$project$ApiTypes$Home$Index$PrivateClassState)));
var $author$project$ApiTypes$Progress$Complete = 2;
var $author$project$ApiTypes$Progress$NotOnTrack = 1;
var $author$project$ApiTypes$Progress$OnTrack = 0;
var $author$project$Data$progressStatusDecoder = A2(
	$elm$json$Json$Decode$map,
	function (s) {
		switch (s) {
			case 'NotOnTrack':
				return 1;
			case 'Complete':
				return 2;
			default:
				return 0;
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$ApiTypes$Home$Index$ScheduledClassState = function (scheduledClasses) {
	return {bA: scheduledClasses};
};
var $author$project$Data$scheduledClassStateDecoder = A2(
	$author$project$JsonUtils$apply,
	A2(
		$elm$json$Json$Decode$field,
		'scheduledClasses',
		$elm$json$Json$Decode$list($author$project$Data$privateClassSummaryDecoder)),
	$elm$json$Json$Decode$succeed($author$project$ApiTypes$Home$Index$ScheduledClassState));
var $author$project$ApiTypes$Home$Index$SelfStudyState = F3(
	function (progress, focusUnit, courseSupportsClasses) {
		return {du: courseSupportsClasses, d_: focusUnit, bv: progress};
	});
var $author$project$ApiTypes$Home$Index$UnitSummary = F7(
	function (unitContext, unitTitle, stepNumber, totalSteps, stepName, backgroundImage, callToAction) {
		return {c5: backgroundImage, dc: callToAction, fD: stepName, fE: stepNumber, fW: totalSteps, fZ: unitContext, f0: unitTitle};
	});
var $author$project$ApiTypes$Home$Index$UnitActionBookUnitReview = function (a) {
	return {$: 2, a: a};
};
var $author$project$ApiTypes$Home$Index$UnitActionBookedUnitReview = function (a) {
	return {$: 1, a: a};
};
var $author$project$ApiTypes$Home$Index$UnitActionChangeCourse = {$: 4};
var $author$project$ApiTypes$Home$Index$UnitActionContinueStudying = {$: 0};
var $author$project$ApiTypes$Home$Index$UnitActionTakeLevelTest = function (a) {
	return {$: 3, a: a};
};
var $author$project$ApiTypes$Home$Index$LevelTemplate = function (levelTemplateId) {
	return {eG: levelTemplateId};
};
var $author$project$Data$levelTemplateDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'levelTemplateId', $elm$json$Json$Decode$string),
	$elm$json$Json$Decode$succeed($author$project$ApiTypes$Home$Index$LevelTemplate));
var $author$project$ApiTypes$Home$Index$UnitReviewTemplate = function (unitTemplateId) {
	return {f$: unitTemplateId};
};
var $author$project$Data$unitTemplateDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'unitTemplateId', $elm$json$Json$Decode$string),
	$elm$json$Json$Decode$succeed($author$project$ApiTypes$Home$Index$UnitReviewTemplate));
var $author$project$Data$unitActionDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2($elm$json$Json$Decode$map, $author$project$ApiTypes$Home$Index$UnitActionBookedUnitReview, $author$project$Data$privateClassSummaryDecoder),
			A2($elm$json$Json$Decode$map, $author$project$ApiTypes$Home$Index$UnitActionBookUnitReview, $author$project$Data$unitTemplateDecoder),
			A2($elm$json$Json$Decode$map, $author$project$ApiTypes$Home$Index$UnitActionTakeLevelTest, $author$project$Data$levelTemplateDecoder),
			A2(
			$elm$json$Json$Decode$andThen,
			function (s) {
				switch (s) {
					case 'ChangeCourse':
						return $elm$json$Json$Decode$succeed($author$project$ApiTypes$Home$Index$UnitActionChangeCourse);
					case 'ContinueStudying':
						return $elm$json$Json$Decode$succeed($author$project$ApiTypes$Home$Index$UnitActionContinueStudying);
					default:
						var action = s;
						return $elm$json$Json$Decode$fail('Unexpected unit action: ' + action);
				}
			},
			$elm$json$Json$Decode$string)
		]));
var $author$project$Data$unitSummaryDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'callToAction', $author$project$Data$unitActionDecoder),
	A2(
		$author$project$JsonUtils$apply,
		$elm$json$Json$Decode$maybe(
			A2($elm$json$Json$Decode$field, 'backgroundImage', $elm$json$Json$Decode$string)),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'stepName', $elm$json$Json$Decode$string),
			A2(
				$author$project$JsonUtils$apply,
				A2($elm$json$Json$Decode$field, 'totalSteps', $elm$json$Json$Decode$float),
				A2(
					$author$project$JsonUtils$apply,
					A2($elm$json$Json$Decode$field, 'stepNumber', $elm$json$Json$Decode$float),
					A2(
						$author$project$JsonUtils$apply,
						A2($elm$json$Json$Decode$field, 'unitTitle', $elm$json$Json$Decode$string),
						A2(
							$author$project$JsonUtils$apply,
							A2($elm$json$Json$Decode$field, 'unitContext', $elm$json$Json$Decode$string),
							$elm$json$Json$Decode$succeed($author$project$ApiTypes$Home$Index$UnitSummary))))))));
var $author$project$Data$selfStudyDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'courseSupportsClasses', $elm$json$Json$Decode$bool),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'focusUnit', $author$project$Data$unitSummaryDecoder),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'progress', $author$project$Data$progressDecoder),
			$elm$json$Json$Decode$succeed($author$project$ApiTypes$Home$Index$SelfStudyState))));
var $author$project$Data$homePageDecoder = A2(
	$author$project$JsonUtils$apply,
	$elm$json$Json$Decode$maybe(
		A2(
			$elm$json$Json$Decode$field,
			'scheduledClasses',
			A2($author$project$Data$licensedFeatureDecoder, 'unable to decode scheduled classes', $author$project$Data$scheduledClassStateDecoder))),
	A2(
		$author$project$JsonUtils$apply,
		A2(
			$elm$json$Json$Decode$field,
			'privateClasses',
			A2($author$project$Data$licensedFeatureDecoder, 'unable to decode private classes', $author$project$Data$privateClassStateDecoder)),
		A2(
			$author$project$JsonUtils$apply,
			A2(
				$elm$json$Json$Decode$field,
				'groupClasses',
				A2($author$project$Data$licensedFeatureDecoder, 'unable to decode group classes', $author$project$Data$groupClassStateDecoder)),
			A2(
				$author$project$JsonUtils$apply,
				A2($elm$json$Json$Decode$field, 'selfStudy', $author$project$Data$selfStudyDecoder),
				A2(
					$author$project$JsonUtils$apply,
					A2($elm$json$Json$Decode$field, 'overallProgress', $elm$json$Json$Decode$float),
					A2(
						$author$project$JsonUtils$apply,
						A2($elm$json$Json$Decode$field, 'targetCompletionDate', $elm$json$Json$Decode$string),
						A2(
							$author$project$JsonUtils$apply,
							A2($elm$json$Json$Decode$field, 'onTrack', $author$project$Data$progressStatusDecoder),
							$elm$json$Json$Decode$succeed($author$project$ApiTypes$Home$Index$HomePage))))))));
var $author$project$Api$loadData = function (onResult) {
	return $elm$http$Http$get(
		{
			af: A2(
				$elm$http$Http$expectJson,
				A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, onResult),
				$author$project$Data$homePageDecoder),
			aG: $author$project$Api$apiPath
		});
};
var $author$project$Control$init = function (_v0) {
	var _v1 = $author$project$ServerTime$init($author$project$Api$apiPath);
	var serverTimeModel = _v1.a;
	var serverTimeCmd = _v1.b;
	return _Utils_Tuple2(
		{H: $krisajenkins$remotedata$RemoteData$Loading, P: false, J: true, aB: serverTimeModel},
		$author$project$EffectUtils$batchEffect(
			_List_fromArray(
				[
					A2(
					$author$project$EffectUtils$fromCmd,
					$author$project$Data$LoadDataEffect,
					$author$project$Api$loadData($author$project$Data$DataReceived)),
					A2(
					$author$project$EffectUtils$fromCmd,
					$author$project$Data$ServerTimeEffect,
					A2($elm$core$Platform$Cmd$map, $author$project$Data$ServerTimeMsg, serverTimeCmd))
				])));
};
var $author$project$Data$LanguageChanged = function (a) {
	return {$: 13, a: a};
};
var $elm$time$Time$Every = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$State = F2(
	function (taggers, processes) {
		return {cz: processes, cS: taggers};
	});
var $elm$time$Time$init = $elm$core$Task$succeed(
	A2($elm$time$Time$State, $elm$core$Dict$empty, $elm$core$Dict$empty));
var $elm$time$Time$addMySub = F2(
	function (_v0, state) {
		var interval = _v0.a;
		var tagger = _v0.b;
		var _v1 = A2($elm$core$Dict$get, interval, state);
		if (_v1.$ === 1) {
			return A3(
				$elm$core$Dict$insert,
				interval,
				_List_fromArray(
					[tagger]),
				state);
		} else {
			var taggers = _v1.a;
			return A3(
				$elm$core$Dict$insert,
				interval,
				A2($elm$core$List$cons, tagger, taggers),
				state);
		}
	});
var $elm$time$Time$setInterval = _Time_setInterval;
var $elm$time$Time$spawnHelp = F3(
	function (router, intervals, processes) {
		if (!intervals.b) {
			return $elm$core$Task$succeed(processes);
		} else {
			var interval = intervals.a;
			var rest = intervals.b;
			var spawnTimer = $elm$core$Process$spawn(
				A2(
					$elm$time$Time$setInterval,
					interval,
					A2($elm$core$Platform$sendToSelf, router, interval)));
			var spawnRest = function (id) {
				return A3(
					$elm$time$Time$spawnHelp,
					router,
					rest,
					A3($elm$core$Dict$insert, interval, id, processes));
			};
			return A2($elm$core$Task$andThen, spawnRest, spawnTimer);
		}
	});
var $elm$time$Time$onEffects = F3(
	function (router, subs, _v0) {
		var processes = _v0.cz;
		var rightStep = F3(
			function (_v6, id, _v7) {
				var spawns = _v7.a;
				var existing = _v7.b;
				var kills = _v7.c;
				return _Utils_Tuple3(
					spawns,
					existing,
					A2(
						$elm$core$Task$andThen,
						function (_v5) {
							return kills;
						},
						$elm$core$Process$kill(id)));
			});
		var newTaggers = A3($elm$core$List$foldl, $elm$time$Time$addMySub, $elm$core$Dict$empty, subs);
		var leftStep = F3(
			function (interval, taggers, _v4) {
				var spawns = _v4.a;
				var existing = _v4.b;
				var kills = _v4.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, interval, spawns),
					existing,
					kills);
			});
		var bothStep = F4(
			function (interval, taggers, id, _v3) {
				var spawns = _v3.a;
				var existing = _v3.b;
				var kills = _v3.c;
				return _Utils_Tuple3(
					spawns,
					A3($elm$core$Dict$insert, interval, id, existing),
					kills);
			});
		var _v1 = A6(
			$elm$core$Dict$merge,
			leftStep,
			bothStep,
			rightStep,
			newTaggers,
			processes,
			_Utils_Tuple3(
				_List_Nil,
				$elm$core$Dict$empty,
				$elm$core$Task$succeed(0)));
		var spawnList = _v1.a;
		var existingDict = _v1.b;
		var killTask = _v1.c;
		return A2(
			$elm$core$Task$andThen,
			function (newProcesses) {
				return $elm$core$Task$succeed(
					A2($elm$time$Time$State, newTaggers, newProcesses));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$time$Time$spawnHelp, router, spawnList, existingDict);
				},
				killTask));
	});
var $elm$time$Time$onSelfMsg = F3(
	function (router, interval, state) {
		var _v0 = A2($elm$core$Dict$get, interval, state.cS);
		if (_v0.$ === 1) {
			return $elm$core$Task$succeed(state);
		} else {
			var taggers = _v0.a;
			var tellTaggers = function (time) {
				return $elm$core$Task$sequence(
					A2(
						$elm$core$List$map,
						function (tagger) {
							return A2(
								$elm$core$Platform$sendToApp,
								router,
								tagger(time));
						},
						taggers));
			};
			return A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$succeed(state);
				},
				A2($elm$core$Task$andThen, tellTaggers, $elm$time$Time$now));
		}
	});
var $elm$time$Time$subMap = F2(
	function (f, _v0) {
		var interval = _v0.a;
		var tagger = _v0.b;
		return A2(
			$elm$time$Time$Every,
			interval,
			A2($elm$core$Basics$composeL, f, tagger));
	});
_Platform_effectManagers['Time'] = _Platform_createManager($elm$time$Time$init, $elm$time$Time$onEffects, $elm$time$Time$onSelfMsg, 0, $elm$time$Time$subMap);
var $elm$time$Time$subscription = _Platform_leaf('Time');
var $elm$time$Time$every = F2(
	function (interval, tagger) {
		return $elm$time$Time$subscription(
			A2($elm$time$Time$Every, interval, tagger));
	});
var $author$project$ServerTime$subscriptions = function (_v0) {
	return A2($elm$time$Time$every, 1000, $author$project$ServerTime$UpdateTime);
};
var $author$project$Control$subscriptions = F2(
	function (_v0, model) {
		return $elm$core$Platform$Sub$batch(
			_List_fromArray(
				[
					$author$project$Ports$onLanguageChange($author$project$Data$LanguageChanged),
					A2(
					$elm$core$Platform$Sub$map,
					$author$project$Data$ServerTimeMsg,
					$author$project$ServerTime$subscriptions(model.aB))
				]));
	});
var $author$project$Data$BubbleErrorEffect = {$: 2};
var $author$project$Data$ChangeUrlEffect = function (a) {
	return {$: 3, a: a};
};
var $author$project$Data$ClassroomUrlReceived = function (a) {
	return {$: 7, a: a};
};
var $author$project$Data$GetClassroomUrlEffect = {$: 6};
var $author$project$Data$GetTechCheckUrlEffect = {$: 5};
var $author$project$Data$GroupClassReceived = function (a) {
	return {$: 9, a: a};
};
var $author$project$Data$NextGroupClassEffect = {$: 4};
var $author$project$Data$TechCheckUrlReceived = function (a) {
	return {$: 6, a: a};
};
var $author$project$Ports$changeUrl = _Platform_outgoingPort(
	'changeUrl',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'blank',
					$elm$json$Json$Encode$bool($.bU)),
					_Utils_Tuple2(
					'external',
					$elm$json$Json$Encode$bool($.b7)),
					_Utils_Tuple2(
					'url',
					$elm$json$Json$Encode$string($.aG))
				]));
	});
var $author$project$Scaffold$CloseModal = {$: 2};
var $author$project$EffectUtils$closeModal = $author$project$Scaffold$CloseModal;
var $author$project$ServerTime$correctedTime = function ($) {
	return $.R;
};
var $elm$json$Json$Encode$float = _Json_wrap;
var $author$project$GroupClasses$encodeGroupClassTimeslot = function (timeslot) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'endTime',
				$elm$json$Json$Encode$float(timeslot.dO)),
				_Utils_Tuple2(
				'evcServer',
				$elm$json$Json$Encode$string(timeslot.dP)),
				_Utils_Tuple2(
				'freezeTime',
				$elm$json$Json$Encode$float(timeslot.d3)),
				_Utils_Tuple2(
				'isVideoClass',
				$elm$json$Json$Encode$bool(timeslot.ex)),
				_Utils_Tuple2(
				'language',
				$elm$json$Json$Encode$string(timeslot.eA)),
				_Utils_Tuple2(
				'levelCode',
				$elm$json$Json$Encode$string(timeslot.eE)),
				_Utils_Tuple2(
				'marketCode',
				$elm$json$Json$Encode$string(timeslot.eO)),
				_Utils_Tuple2(
				'partnerCode',
				$elm$json$Json$Encode$string(timeslot.e8)),
				_Utils_Tuple2(
				'serviceSubType',
				$elm$json$Json$Encode$string(timeslot.fu)),
				_Utils_Tuple2(
				'serviceType',
				$elm$json$Json$Encode$string(timeslot.fv)),
				_Utils_Tuple2(
				'startTime',
				$elm$json$Json$Encode$float(timeslot.fB)),
				_Utils_Tuple2(
				'teachLanguage',
				$elm$json$Json$Encode$string(timeslot.fP)),
				_Utils_Tuple2(
				'techCheckUrlCn',
				$elm$json$Json$Encode$string(timeslot.fR)),
				_Utils_Tuple2(
				'techCheckUrlUs',
				$elm$json$Json$Encode$string(timeslot.fS))
			]));
};
var $elm$http$Http$expectString = function (toMsg) {
	return A2(
		$elm$http$Http$expectStringResponse,
		toMsg,
		$elm$http$Http$resolve($elm$core$Result$Ok));
};
var $elm$http$Http$post = function (r) {
	return $elm$http$Http$request(
		{bV: r.bV, af: r.af, a6: _List_Nil, be: 'POST', bL: $elm$core$Maybe$Nothing, cV: $elm$core$Maybe$Nothing, aG: r.aG});
};
var $author$project$Api$getGroupClassTechCheckUrl = F2(
	function (_class, onResult) {
		return $elm$http$Http$post(
			{
				bV: $elm$http$Http$jsonBody(
					$author$project$GroupClasses$encodeGroupClassTimeslot(_class.fV)),
				af: $elm$http$Http$expectString(onResult),
				aG: $author$project$Api$apiPath + '/techcheckurl/group'
			});
	});
var $author$project$Api$getNextGroupClass = function (onResult) {
	return $elm$http$Http$get(
		{
			af: A2(
				$elm$http$Http$expectJson,
				onResult,
				$elm$json$Json$Decode$maybe($author$project$Data$groupClassSummaryDecoder)),
			aG: $author$project$Api$apiPath + '/nextgroupclass'
		});
};
var $author$project$Api$encodePrivateClassroomUrlRequest = F2(
	function (_v0, _class) {
		var fullUrl = _v0.d5;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'sourceUrl',
					$elm$json$Json$Encode$string(fullUrl)),
					_Utils_Tuple2(
					'classId',
					$elm$json$Json$Encode$string(_class.dh)),
					_Utils_Tuple2(
					'classTypeCode',
					$elm$json$Json$Encode$string(_class.di)),
					_Utils_Tuple2(
					'startTime',
					$elm$json$Json$Encode$float(_class.fU.fA.fe)),
					_Utils_Tuple2(
					'teacherId',
					A2(
						$elm$core$Maybe$withDefault,
						$elm$json$Json$Encode$string(''),
						A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, _class.fQ))),
					_Utils_Tuple2(
					'evcServerCode',
					$elm$json$Json$Encode$string(_class.dQ)),
					_Utils_Tuple2(
					'bookingProvider',
					$elm$json$Json$Encode$string(_class.c8))
				]));
	});
var $author$project$Api$getPrivateClassClassroomUrl = F3(
	function (external, _class, onResult) {
		return $elm$http$Http$post(
			{
				bV: $elm$http$Http$jsonBody(
					A2($author$project$Api$encodePrivateClassroomUrlRequest, external, _class)),
				af: $elm$http$Http$expectString(onResult),
				aG: $author$project$Api$apiPath + '/classroomurl/private'
			});
	});
var $author$project$Student$NoGrantOrCredit = {$: 2};
var $author$project$Student$Credit = function (a) {
	return {$: 1, a: a};
};
var $author$project$Student$Grant = {$: 0};
var $author$project$Student$getGrantOrRemainingCredit = function (feature) {
	if (feature.$ === 1) {
		return $author$project$Student$NoGrantOrCredit;
	} else {
		var quota = feature.a.fj;
		if (quota.$ === 1) {
			var remaining = quota.a.fm;
			return (remaining > 0) ? $author$project$Student$Credit(remaining) : $author$project$Student$NoGrantOrCredit;
		} else {
			return $author$project$Student$Grant;
		}
	}
};
var $elm$core$Basics$neq = _Utils_notEqual;
var $author$project$Student$hasGrantOrRemainingCredit = function (feature) {
	return !_Utils_eq(
		$author$project$Student$getGrantOrRemainingCredit(feature),
		$author$project$Student$NoGrantOrCredit);
};
var $author$project$LicensedFeature$map = F2(
	function (fn, feature) {
		if (feature.$ === 1) {
			return $author$project$ApiTypes$Home$Index$LicensedFeatureUnlicensed;
		} else {
			var f = feature.a;
			return $author$project$ApiTypes$Home$Index$LicensedFeatureLicensed(
				fn(f));
		}
	});
var $krisajenkins$remotedata$RemoteData$NotAsked = {$: 0};
var $krisajenkins$remotedata$RemoteData$map = F2(
	function (f, data) {
		switch (data.$) {
			case 3:
				var value = data.a;
				return $krisajenkins$remotedata$RemoteData$Success(
					f(value));
			case 1:
				return $krisajenkins$remotedata$RemoteData$Loading;
			case 0:
				return $krisajenkins$remotedata$RemoteData$NotAsked;
			default:
				var error = data.a;
				return $krisajenkins$remotedata$RemoteData$Failure(error);
		}
	});
var $author$project$Ports$maybeBubbleError = A2(
	$elm$core$Basics$composeR,
	$elm$core$Maybe$map($author$project$Ports$bubbleError),
	$elm$core$Maybe$withDefault($elm$core$Platform$Cmd$none));
var $author$project$Data$CloseModal = {$: 11};
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $author$project$Button$button = F2(
	function (props, content) {
		return A2(
			$elm$html$Html$button,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('c-button'),
						$elm$html$Html$Attributes$type_('button')
					]),
				A2(
					$elm$core$List$filterMap,
					function (prop) {
						if (!prop.$) {
							var a = prop.a;
							return $elm$core$Maybe$Just(a);
						} else {
							return $elm$core$Maybe$Nothing;
						}
					},
					props)),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-button__content')
						]),
					content)
				]));
	});
var $author$project$Button$ButtonProp = function (a) {
	return {$: 0, a: a};
};
var $author$project$Button$buttonProp = A2($elm$core$Basics$composeL, $author$project$Button$ButtonProp, $elm$html$Html$Attributes$class);
var $author$project$Button$class = $author$project$Button$buttonProp;
var $author$project$Button$dark = $author$project$Button$buttonProp('-dark');
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $author$project$Button$onClick = function (msg) {
	return $author$project$Button$ButtonProp(
		$elm$html$Html$Events$onClick(msg));
};
var $elm$html$Html$p = _VirtualDom_node('p');
var $author$project$Data$progressStatusToBlurb = function (status) {
	switch (status) {
		case 0:
			return $author$project$Blurbs$blurbs.e1;
		case 1:
			return $author$project$Blurbs$blurbs.bl;
		default:
			return $author$project$Blurbs$blurbs.aJ;
	}
};
var $author$project$CssUtils$qa = $elm$html$Html$Attributes$attribute('data-qa');
var $author$project$Button$qa = A2($elm$core$Basics$composeR, $author$project$CssUtils$qa, $author$project$Button$ButtonProp);
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $author$project$StringUtils$replaceToken = F2(
	function (needle, replaceWith) {
		return A2($elm$core$String$replace, needle, replaceWith);
	});
var $author$project$StringUtils$replaceNamedToken = F2(
	function (name, replaceWith) {
		return A2($author$project$StringUtils$replaceToken, '{{' + (name + '}}'), replaceWith);
	});
var $hecrj$html_parser$Html$Parser$Element = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$Advanced$backtrackable = function (_v0) {
	var parse = _v0;
	return function (s0) {
		var _v1 = parse(s0);
		if (_v1.$ === 1) {
			var x = _v1.b;
			return A2($elm$parser$Parser$Advanced$Bad, false, x);
		} else {
			var a = _v1.b;
			var s1 = _v1.c;
			return A3($elm$parser$Parser$Advanced$Good, false, a, s1);
		}
	};
};
var $elm$parser$Parser$backtrackable = $elm$parser$Parser$Advanced$backtrackable;
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {bZ: col, dq: contextStack, cx: problem, cH: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.cH, s.bZ, x, s.dp));
	});
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.b, s.a);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{bZ: 1, dp: s.dp, c: s.c, b: s.b + 1, cH: s.cH + 1, a: s.a}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{bZ: s.bZ + 1, dp: s.dp, c: s.c, b: newOffset, cH: s.cH, a: s.a}));
		};
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.a);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.b, offset) < 0,
					0,
					{bZ: col, dp: s0.dp, c: s0.c, b: offset, cH: row, a: s0.a});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.b, s.cH, s.bZ, s);
	};
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $hecrj$html_parser$Html$Parser$chompOneOrMore = function (fn) {
	return A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$chompIf(fn),
		$elm$parser$Parser$chompWhile(fn));
};
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.b, s1.b, s0.a),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $hecrj$html_parser$Html$Parser$isSpaceCharacter = function (c) {
	return (c === ' ') || ((c === '\t') || ((c === '\n') || ((c === '\u000D') || ((c === '\u000C') || (c === '\u00A0')))));
};
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $elm$core$String$toLower = _String_toLower;
var $hecrj$html_parser$Html$Parser$closingTag = function (name) {
	var chompName = A2(
		$elm$parser$Parser$andThen,
		function (closingName) {
			return _Utils_eq(
				$elm$core$String$toLower(closingName),
				name) ? $elm$parser$Parser$succeed(0) : $elm$parser$Parser$problem('closing tag does not match opening tag: ' + name);
		},
		$elm$parser$Parser$getChompedString(
			$hecrj$html_parser$Html$Parser$chompOneOrMore(
				function (c) {
					return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && (c !== '>');
				})));
	return A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('<')),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('/'))),
				chompName),
			$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('>')));
};
var $hecrj$html_parser$Html$Parser$Comment = function (a) {
	return {$: 2, a: a};
};
var $elm$parser$Parser$Advanced$findSubString = _Parser_findSubString;
var $elm$parser$Parser$Advanced$fromInfo = F4(
	function (row, col, x, context) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, row, col, x, context));
	});
var $elm$parser$Parser$Advanced$chompUntil = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$findSubString, str, s.b, s.cH, s.bZ, s.a);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A4($elm$parser$Parser$Advanced$fromInfo, newRow, newCol, expecting, s.dp)) : A3(
			$elm$parser$Parser$Advanced$Good,
			_Utils_cmp(s.b, newOffset) < 0,
			0,
			{bZ: newCol, dp: s.dp, c: s.c, b: newOffset, cH: newRow, a: s.a});
	};
};
var $elm$parser$Parser$Expecting = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $elm$parser$Parser$chompUntil = function (str) {
	return $elm$parser$Parser$Advanced$chompUntil(
		$elm$parser$Parser$toToken(str));
};
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.b, s.cH, s.bZ, s.a);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{bZ: newCol, dp: s.dp, c: s.c, b: newOffset, cH: newRow, a: s.a});
	};
};
var $elm$parser$Parser$token = function (str) {
	return $elm$parser$Parser$Advanced$token(
		$elm$parser$Parser$toToken(str));
};
var $hecrj$html_parser$Html$Parser$comment = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($hecrj$html_parser$Html$Parser$Comment),
			$elm$parser$Parser$token('<!')),
		$elm$parser$Parser$token('--')),
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$getChompedString(
			$elm$parser$Parser$chompUntil('-->')),
		$elm$parser$Parser$token('-->')));
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $hecrj$html_parser$Html$Parser$voidElements = _List_fromArray(
	['area', 'base', 'br', 'col', 'embed', 'hr', 'img', 'input', 'link', 'meta', 'param', 'source', 'track', 'wbr']);
var $hecrj$html_parser$Html$Parser$isVoidElement = function (name) {
	return A2($elm$core$List$member, name, $hecrj$html_parser$Html$Parser$voidElements);
};
var $elm$parser$Parser$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var parse = _v0;
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p1 = _v1.a;
				var step = _v1.b;
				var s1 = _v1.c;
				if (!step.$) {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3($elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var $elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return function (s) {
			return A4($elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
		};
	});
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$toAdvancedStep = function (step) {
	if (!step.$) {
		var s = step.a;
		return $elm$parser$Parser$Advanced$Loop(s);
	} else {
		var a = step.a;
		return $elm$parser$Parser$Advanced$Done(a);
	}
};
var $elm$parser$Parser$loop = F2(
	function (state, callback) {
		return A2(
			$elm$parser$Parser$Advanced$loop,
			state,
			function (s) {
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$toAdvancedStep,
					callback(s));
			});
	});
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $hecrj$html_parser$Html$Parser$many = function (parser_) {
	return A2(
		$elm$parser$Parser$loop,
		_List_Nil,
		function (list) {
			return $elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$map,
						function (_new) {
							return $elm$parser$Parser$Loop(
								A2($elm$core$List$cons, _new, list));
						},
						parser_),
						$elm$parser$Parser$succeed(
						$elm$parser$Parser$Done(
							$elm$core$List$reverse(list)))
					]));
		});
};
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $hecrj$html_parser$Html$Parser$isTagAttributeCharacter = function (c) {
	return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && ((c !== '\"') && ((c !== '\'') && ((c !== '>') && ((c !== '/') && (c !== '=')))));
};
var $hecrj$html_parser$Html$Parser$tagAttributeName = A2(
	$elm$parser$Parser$map,
	$elm$core$String$toLower,
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($hecrj$html_parser$Html$Parser$isTagAttributeCharacter)));
var $hecrj$html_parser$Html$Parser$chompSemicolon = $elm$parser$Parser$chompIf(
	$elm$core$Basics$eq(';'));
var $hecrj$html_parser$Html$Parser$NamedCharacterReferences$dict = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('Aacute', 'Á'),
			_Utils_Tuple2('aacute', 'á'),
			_Utils_Tuple2('Abreve', 'Ă'),
			_Utils_Tuple2('abreve', 'ă'),
			_Utils_Tuple2('ac', '∾'),
			_Utils_Tuple2('acd', '∿'),
			_Utils_Tuple2('acE', '∾̳'),
			_Utils_Tuple2('Acirc', 'Â'),
			_Utils_Tuple2('acirc', 'â'),
			_Utils_Tuple2('acute', '´'),
			_Utils_Tuple2('Acy', 'А'),
			_Utils_Tuple2('acy', 'а'),
			_Utils_Tuple2('AElig', 'Æ'),
			_Utils_Tuple2('aelig', 'æ'),
			_Utils_Tuple2('af', '\u2061'),
			_Utils_Tuple2('Afr', '\uD835\uDD04'),
			_Utils_Tuple2('afr', '\uD835\uDD1E'),
			_Utils_Tuple2('Agrave', 'À'),
			_Utils_Tuple2('agrave', 'à'),
			_Utils_Tuple2('alefsym', 'ℵ'),
			_Utils_Tuple2('aleph', 'ℵ'),
			_Utils_Tuple2('Alpha', 'Α'),
			_Utils_Tuple2('alpha', 'α'),
			_Utils_Tuple2('Amacr', 'Ā'),
			_Utils_Tuple2('amacr', 'ā'),
			_Utils_Tuple2('amalg', '⨿'),
			_Utils_Tuple2('amp', '&'),
			_Utils_Tuple2('AMP', '&'),
			_Utils_Tuple2('andand', '⩕'),
			_Utils_Tuple2('And', '⩓'),
			_Utils_Tuple2('and', '∧'),
			_Utils_Tuple2('andd', '⩜'),
			_Utils_Tuple2('andslope', '⩘'),
			_Utils_Tuple2('andv', '⩚'),
			_Utils_Tuple2('ang', '∠'),
			_Utils_Tuple2('ange', '⦤'),
			_Utils_Tuple2('angle', '∠'),
			_Utils_Tuple2('angmsdaa', '⦨'),
			_Utils_Tuple2('angmsdab', '⦩'),
			_Utils_Tuple2('angmsdac', '⦪'),
			_Utils_Tuple2('angmsdad', '⦫'),
			_Utils_Tuple2('angmsdae', '⦬'),
			_Utils_Tuple2('angmsdaf', '⦭'),
			_Utils_Tuple2('angmsdag', '⦮'),
			_Utils_Tuple2('angmsdah', '⦯'),
			_Utils_Tuple2('angmsd', '∡'),
			_Utils_Tuple2('angrt', '∟'),
			_Utils_Tuple2('angrtvb', '⊾'),
			_Utils_Tuple2('angrtvbd', '⦝'),
			_Utils_Tuple2('angsph', '∢'),
			_Utils_Tuple2('angst', 'Å'),
			_Utils_Tuple2('angzarr', '⍼'),
			_Utils_Tuple2('Aogon', 'Ą'),
			_Utils_Tuple2('aogon', 'ą'),
			_Utils_Tuple2('Aopf', '\uD835\uDD38'),
			_Utils_Tuple2('aopf', '\uD835\uDD52'),
			_Utils_Tuple2('apacir', '⩯'),
			_Utils_Tuple2('ap', '≈'),
			_Utils_Tuple2('apE', '⩰'),
			_Utils_Tuple2('ape', '≊'),
			_Utils_Tuple2('apid', '≋'),
			_Utils_Tuple2('apos', '\''),
			_Utils_Tuple2('ApplyFunction', '\u2061'),
			_Utils_Tuple2('approx', '≈'),
			_Utils_Tuple2('approxeq', '≊'),
			_Utils_Tuple2('Aring', 'Å'),
			_Utils_Tuple2('aring', 'å'),
			_Utils_Tuple2('Ascr', '\uD835\uDC9C'),
			_Utils_Tuple2('ascr', '\uD835\uDCB6'),
			_Utils_Tuple2('Assign', '≔'),
			_Utils_Tuple2('ast', '*'),
			_Utils_Tuple2('asymp', '≈'),
			_Utils_Tuple2('asympeq', '≍'),
			_Utils_Tuple2('Atilde', 'Ã'),
			_Utils_Tuple2('atilde', 'ã'),
			_Utils_Tuple2('Auml', 'Ä'),
			_Utils_Tuple2('auml', 'ä'),
			_Utils_Tuple2('awconint', '∳'),
			_Utils_Tuple2('awint', '⨑'),
			_Utils_Tuple2('backcong', '≌'),
			_Utils_Tuple2('backepsilon', '϶'),
			_Utils_Tuple2('backprime', '‵'),
			_Utils_Tuple2('backsim', '∽'),
			_Utils_Tuple2('backsimeq', '⋍'),
			_Utils_Tuple2('Backslash', '∖'),
			_Utils_Tuple2('Barv', '⫧'),
			_Utils_Tuple2('barvee', '⊽'),
			_Utils_Tuple2('barwed', '⌅'),
			_Utils_Tuple2('Barwed', '⌆'),
			_Utils_Tuple2('barwedge', '⌅'),
			_Utils_Tuple2('bbrk', '⎵'),
			_Utils_Tuple2('bbrktbrk', '⎶'),
			_Utils_Tuple2('bcong', '≌'),
			_Utils_Tuple2('Bcy', 'Б'),
			_Utils_Tuple2('bcy', 'б'),
			_Utils_Tuple2('bdquo', '„'),
			_Utils_Tuple2('becaus', '∵'),
			_Utils_Tuple2('because', '∵'),
			_Utils_Tuple2('Because', '∵'),
			_Utils_Tuple2('bemptyv', '⦰'),
			_Utils_Tuple2('bepsi', '϶'),
			_Utils_Tuple2('bernou', 'ℬ'),
			_Utils_Tuple2('Bernoullis', 'ℬ'),
			_Utils_Tuple2('Beta', 'Β'),
			_Utils_Tuple2('beta', 'β'),
			_Utils_Tuple2('beth', 'ℶ'),
			_Utils_Tuple2('between', '≬'),
			_Utils_Tuple2('Bfr', '\uD835\uDD05'),
			_Utils_Tuple2('bfr', '\uD835\uDD1F'),
			_Utils_Tuple2('bigcap', '⋂'),
			_Utils_Tuple2('bigcirc', '◯'),
			_Utils_Tuple2('bigcup', '⋃'),
			_Utils_Tuple2('bigodot', '⨀'),
			_Utils_Tuple2('bigoplus', '⨁'),
			_Utils_Tuple2('bigotimes', '⨂'),
			_Utils_Tuple2('bigsqcup', '⨆'),
			_Utils_Tuple2('bigstar', '★'),
			_Utils_Tuple2('bigtriangledown', '▽'),
			_Utils_Tuple2('bigtriangleup', '△'),
			_Utils_Tuple2('biguplus', '⨄'),
			_Utils_Tuple2('bigvee', '⋁'),
			_Utils_Tuple2('bigwedge', '⋀'),
			_Utils_Tuple2('bkarow', '⤍'),
			_Utils_Tuple2('blacklozenge', '⧫'),
			_Utils_Tuple2('blacksquare', '▪'),
			_Utils_Tuple2('blacktriangle', '▴'),
			_Utils_Tuple2('blacktriangledown', '▾'),
			_Utils_Tuple2('blacktriangleleft', '◂'),
			_Utils_Tuple2('blacktriangleright', '▸'),
			_Utils_Tuple2('blank', '␣'),
			_Utils_Tuple2('blk12', '▒'),
			_Utils_Tuple2('blk14', '░'),
			_Utils_Tuple2('blk34', '▓'),
			_Utils_Tuple2('block', '█'),
			_Utils_Tuple2('bne', '=⃥'),
			_Utils_Tuple2('bnequiv', '≡⃥'),
			_Utils_Tuple2('bNot', '⫭'),
			_Utils_Tuple2('bnot', '⌐'),
			_Utils_Tuple2('Bopf', '\uD835\uDD39'),
			_Utils_Tuple2('bopf', '\uD835\uDD53'),
			_Utils_Tuple2('bot', '⊥'),
			_Utils_Tuple2('bottom', '⊥'),
			_Utils_Tuple2('bowtie', '⋈'),
			_Utils_Tuple2('boxbox', '⧉'),
			_Utils_Tuple2('boxdl', '┐'),
			_Utils_Tuple2('boxdL', '╕'),
			_Utils_Tuple2('boxDl', '╖'),
			_Utils_Tuple2('boxDL', '╗'),
			_Utils_Tuple2('boxdr', '┌'),
			_Utils_Tuple2('boxdR', '╒'),
			_Utils_Tuple2('boxDr', '╓'),
			_Utils_Tuple2('boxDR', '╔'),
			_Utils_Tuple2('boxh', '─'),
			_Utils_Tuple2('boxH', '═'),
			_Utils_Tuple2('boxhd', '┬'),
			_Utils_Tuple2('boxHd', '╤'),
			_Utils_Tuple2('boxhD', '╥'),
			_Utils_Tuple2('boxHD', '╦'),
			_Utils_Tuple2('boxhu', '┴'),
			_Utils_Tuple2('boxHu', '╧'),
			_Utils_Tuple2('boxhU', '╨'),
			_Utils_Tuple2('boxHU', '╩'),
			_Utils_Tuple2('boxminus', '⊟'),
			_Utils_Tuple2('boxplus', '⊞'),
			_Utils_Tuple2('boxtimes', '⊠'),
			_Utils_Tuple2('boxul', '┘'),
			_Utils_Tuple2('boxuL', '╛'),
			_Utils_Tuple2('boxUl', '╜'),
			_Utils_Tuple2('boxUL', '╝'),
			_Utils_Tuple2('boxur', '└'),
			_Utils_Tuple2('boxuR', '╘'),
			_Utils_Tuple2('boxUr', '╙'),
			_Utils_Tuple2('boxUR', '╚'),
			_Utils_Tuple2('boxv', '│'),
			_Utils_Tuple2('boxV', '║'),
			_Utils_Tuple2('boxvh', '┼'),
			_Utils_Tuple2('boxvH', '╪'),
			_Utils_Tuple2('boxVh', '╫'),
			_Utils_Tuple2('boxVH', '╬'),
			_Utils_Tuple2('boxvl', '┤'),
			_Utils_Tuple2('boxvL', '╡'),
			_Utils_Tuple2('boxVl', '╢'),
			_Utils_Tuple2('boxVL', '╣'),
			_Utils_Tuple2('boxvr', '├'),
			_Utils_Tuple2('boxvR', '╞'),
			_Utils_Tuple2('boxVr', '╟'),
			_Utils_Tuple2('boxVR', '╠'),
			_Utils_Tuple2('bprime', '‵'),
			_Utils_Tuple2('breve', '˘'),
			_Utils_Tuple2('Breve', '˘'),
			_Utils_Tuple2('brvbar', '¦'),
			_Utils_Tuple2('bscr', '\uD835\uDCB7'),
			_Utils_Tuple2('Bscr', 'ℬ'),
			_Utils_Tuple2('bsemi', '⁏'),
			_Utils_Tuple2('bsim', '∽'),
			_Utils_Tuple2('bsime', '⋍'),
			_Utils_Tuple2('bsolb', '⧅'),
			_Utils_Tuple2('bsol', '\\'),
			_Utils_Tuple2('bsolhsub', '⟈'),
			_Utils_Tuple2('bull', '•'),
			_Utils_Tuple2('bullet', '•'),
			_Utils_Tuple2('bump', '≎'),
			_Utils_Tuple2('bumpE', '⪮'),
			_Utils_Tuple2('bumpe', '≏'),
			_Utils_Tuple2('Bumpeq', '≎'),
			_Utils_Tuple2('bumpeq', '≏'),
			_Utils_Tuple2('Cacute', 'Ć'),
			_Utils_Tuple2('cacute', 'ć'),
			_Utils_Tuple2('capand', '⩄'),
			_Utils_Tuple2('capbrcup', '⩉'),
			_Utils_Tuple2('capcap', '⩋'),
			_Utils_Tuple2('cap', '∩'),
			_Utils_Tuple2('Cap', '⋒'),
			_Utils_Tuple2('capcup', '⩇'),
			_Utils_Tuple2('capdot', '⩀'),
			_Utils_Tuple2('CapitalDifferentialD', 'ⅅ'),
			_Utils_Tuple2('caps', '∩︀'),
			_Utils_Tuple2('caret', '⁁'),
			_Utils_Tuple2('caron', 'ˇ'),
			_Utils_Tuple2('Cayleys', 'ℭ'),
			_Utils_Tuple2('ccaps', '⩍'),
			_Utils_Tuple2('Ccaron', 'Č'),
			_Utils_Tuple2('ccaron', 'č'),
			_Utils_Tuple2('Ccedil', 'Ç'),
			_Utils_Tuple2('ccedil', 'ç'),
			_Utils_Tuple2('Ccirc', 'Ĉ'),
			_Utils_Tuple2('ccirc', 'ĉ'),
			_Utils_Tuple2('Cconint', '∰'),
			_Utils_Tuple2('ccups', '⩌'),
			_Utils_Tuple2('ccupssm', '⩐'),
			_Utils_Tuple2('Cdot', 'Ċ'),
			_Utils_Tuple2('cdot', 'ċ'),
			_Utils_Tuple2('cedil', '¸'),
			_Utils_Tuple2('Cedilla', '¸'),
			_Utils_Tuple2('cemptyv', '⦲'),
			_Utils_Tuple2('cent', '¢'),
			_Utils_Tuple2('centerdot', '·'),
			_Utils_Tuple2('CenterDot', '·'),
			_Utils_Tuple2('cfr', '\uD835\uDD20'),
			_Utils_Tuple2('Cfr', 'ℭ'),
			_Utils_Tuple2('CHcy', 'Ч'),
			_Utils_Tuple2('chcy', 'ч'),
			_Utils_Tuple2('check', '✓'),
			_Utils_Tuple2('checkmark', '✓'),
			_Utils_Tuple2('Chi', 'Χ'),
			_Utils_Tuple2('chi', 'χ'),
			_Utils_Tuple2('circ', 'ˆ'),
			_Utils_Tuple2('circeq', '≗'),
			_Utils_Tuple2('circlearrowleft', '↺'),
			_Utils_Tuple2('circlearrowright', '↻'),
			_Utils_Tuple2('circledast', '⊛'),
			_Utils_Tuple2('circledcirc', '⊚'),
			_Utils_Tuple2('circleddash', '⊝'),
			_Utils_Tuple2('CircleDot', '⊙'),
			_Utils_Tuple2('circledR', '®'),
			_Utils_Tuple2('circledS', 'Ⓢ'),
			_Utils_Tuple2('CircleMinus', '⊖'),
			_Utils_Tuple2('CirclePlus', '⊕'),
			_Utils_Tuple2('CircleTimes', '⊗'),
			_Utils_Tuple2('cir', '○'),
			_Utils_Tuple2('cirE', '⧃'),
			_Utils_Tuple2('cire', '≗'),
			_Utils_Tuple2('cirfnint', '⨐'),
			_Utils_Tuple2('cirmid', '⫯'),
			_Utils_Tuple2('cirscir', '⧂'),
			_Utils_Tuple2('ClockwiseContourIntegral', '∲'),
			_Utils_Tuple2('CloseCurlyDoubleQuote', '”'),
			_Utils_Tuple2('CloseCurlyQuote', '’'),
			_Utils_Tuple2('clubs', '♣'),
			_Utils_Tuple2('clubsuit', '♣'),
			_Utils_Tuple2('colon', ':'),
			_Utils_Tuple2('Colon', '∷'),
			_Utils_Tuple2('Colone', '⩴'),
			_Utils_Tuple2('colone', '≔'),
			_Utils_Tuple2('coloneq', '≔'),
			_Utils_Tuple2('comma', ','),
			_Utils_Tuple2('commat', '@'),
			_Utils_Tuple2('comp', '∁'),
			_Utils_Tuple2('compfn', '∘'),
			_Utils_Tuple2('complement', '∁'),
			_Utils_Tuple2('complexes', 'ℂ'),
			_Utils_Tuple2('cong', '≅'),
			_Utils_Tuple2('congdot', '⩭'),
			_Utils_Tuple2('Congruent', '≡'),
			_Utils_Tuple2('conint', '∮'),
			_Utils_Tuple2('Conint', '∯'),
			_Utils_Tuple2('ContourIntegral', '∮'),
			_Utils_Tuple2('copf', '\uD835\uDD54'),
			_Utils_Tuple2('Copf', 'ℂ'),
			_Utils_Tuple2('coprod', '∐'),
			_Utils_Tuple2('Coproduct', '∐'),
			_Utils_Tuple2('copy', '©'),
			_Utils_Tuple2('COPY', '©'),
			_Utils_Tuple2('copysr', '℗'),
			_Utils_Tuple2('CounterClockwiseContourIntegral', '∳'),
			_Utils_Tuple2('crarr', '↵'),
			_Utils_Tuple2('cross', '✗'),
			_Utils_Tuple2('Cross', '⨯'),
			_Utils_Tuple2('Cscr', '\uD835\uDC9E'),
			_Utils_Tuple2('cscr', '\uD835\uDCB8'),
			_Utils_Tuple2('csub', '⫏'),
			_Utils_Tuple2('csube', '⫑'),
			_Utils_Tuple2('csup', '⫐'),
			_Utils_Tuple2('csupe', '⫒'),
			_Utils_Tuple2('ctdot', '⋯'),
			_Utils_Tuple2('cudarrl', '⤸'),
			_Utils_Tuple2('cudarrr', '⤵'),
			_Utils_Tuple2('cuepr', '⋞'),
			_Utils_Tuple2('cuesc', '⋟'),
			_Utils_Tuple2('cularr', '↶'),
			_Utils_Tuple2('cularrp', '⤽'),
			_Utils_Tuple2('cupbrcap', '⩈'),
			_Utils_Tuple2('cupcap', '⩆'),
			_Utils_Tuple2('CupCap', '≍'),
			_Utils_Tuple2('cup', '∪'),
			_Utils_Tuple2('Cup', '⋓'),
			_Utils_Tuple2('cupcup', '⩊'),
			_Utils_Tuple2('cupdot', '⊍'),
			_Utils_Tuple2('cupor', '⩅'),
			_Utils_Tuple2('cups', '∪︀'),
			_Utils_Tuple2('curarr', '↷'),
			_Utils_Tuple2('curarrm', '⤼'),
			_Utils_Tuple2('curlyeqprec', '⋞'),
			_Utils_Tuple2('curlyeqsucc', '⋟'),
			_Utils_Tuple2('curlyvee', '⋎'),
			_Utils_Tuple2('curlywedge', '⋏'),
			_Utils_Tuple2('curren', '¤'),
			_Utils_Tuple2('curvearrowleft', '↶'),
			_Utils_Tuple2('curvearrowright', '↷'),
			_Utils_Tuple2('cuvee', '⋎'),
			_Utils_Tuple2('cuwed', '⋏'),
			_Utils_Tuple2('cwconint', '∲'),
			_Utils_Tuple2('cwint', '∱'),
			_Utils_Tuple2('cylcty', '⌭'),
			_Utils_Tuple2('dagger', '†'),
			_Utils_Tuple2('Dagger', '‡'),
			_Utils_Tuple2('daleth', 'ℸ'),
			_Utils_Tuple2('darr', '↓'),
			_Utils_Tuple2('Darr', '↡'),
			_Utils_Tuple2('dArr', '⇓'),
			_Utils_Tuple2('dash', '‐'),
			_Utils_Tuple2('Dashv', '⫤'),
			_Utils_Tuple2('dashv', '⊣'),
			_Utils_Tuple2('dbkarow', '⤏'),
			_Utils_Tuple2('dblac', '˝'),
			_Utils_Tuple2('Dcaron', 'Ď'),
			_Utils_Tuple2('dcaron', 'ď'),
			_Utils_Tuple2('Dcy', 'Д'),
			_Utils_Tuple2('dcy', 'д'),
			_Utils_Tuple2('ddagger', '‡'),
			_Utils_Tuple2('ddarr', '⇊'),
			_Utils_Tuple2('DD', 'ⅅ'),
			_Utils_Tuple2('dd', 'ⅆ'),
			_Utils_Tuple2('DDotrahd', '⤑'),
			_Utils_Tuple2('ddotseq', '⩷'),
			_Utils_Tuple2('deg', '°'),
			_Utils_Tuple2('Del', '∇'),
			_Utils_Tuple2('Delta', 'Δ'),
			_Utils_Tuple2('delta', 'δ'),
			_Utils_Tuple2('demptyv', '⦱'),
			_Utils_Tuple2('dfisht', '⥿'),
			_Utils_Tuple2('Dfr', '\uD835\uDD07'),
			_Utils_Tuple2('dfr', '\uD835\uDD21'),
			_Utils_Tuple2('dHar', '⥥'),
			_Utils_Tuple2('dharl', '⇃'),
			_Utils_Tuple2('dharr', '⇂'),
			_Utils_Tuple2('DiacriticalAcute', '´'),
			_Utils_Tuple2('DiacriticalDot', '˙'),
			_Utils_Tuple2('DiacriticalDoubleAcute', '˝'),
			_Utils_Tuple2('DiacriticalGrave', '`'),
			_Utils_Tuple2('DiacriticalTilde', '˜'),
			_Utils_Tuple2('diam', '⋄'),
			_Utils_Tuple2('diamond', '⋄'),
			_Utils_Tuple2('Diamond', '⋄'),
			_Utils_Tuple2('diamondsuit', '♦'),
			_Utils_Tuple2('diams', '♦'),
			_Utils_Tuple2('die', '¨'),
			_Utils_Tuple2('DifferentialD', 'ⅆ'),
			_Utils_Tuple2('digamma', 'ϝ'),
			_Utils_Tuple2('disin', '⋲'),
			_Utils_Tuple2('div', '÷'),
			_Utils_Tuple2('divide', '÷'),
			_Utils_Tuple2('divideontimes', '⋇'),
			_Utils_Tuple2('divonx', '⋇'),
			_Utils_Tuple2('DJcy', 'Ђ'),
			_Utils_Tuple2('djcy', 'ђ'),
			_Utils_Tuple2('dlcorn', '⌞'),
			_Utils_Tuple2('dlcrop', '⌍'),
			_Utils_Tuple2('dollar', '$'),
			_Utils_Tuple2('Dopf', '\uD835\uDD3B'),
			_Utils_Tuple2('dopf', '\uD835\uDD55'),
			_Utils_Tuple2('Dot', '¨'),
			_Utils_Tuple2('dot', '˙'),
			_Utils_Tuple2('DotDot', '⃜'),
			_Utils_Tuple2('doteq', '≐'),
			_Utils_Tuple2('doteqdot', '≑'),
			_Utils_Tuple2('DotEqual', '≐'),
			_Utils_Tuple2('dotminus', '∸'),
			_Utils_Tuple2('dotplus', '∔'),
			_Utils_Tuple2('dotsquare', '⊡'),
			_Utils_Tuple2('doublebarwedge', '⌆'),
			_Utils_Tuple2('DoubleContourIntegral', '∯'),
			_Utils_Tuple2('DoubleDot', '¨'),
			_Utils_Tuple2('DoubleDownArrow', '⇓'),
			_Utils_Tuple2('DoubleLeftArrow', '⇐'),
			_Utils_Tuple2('DoubleLeftRightArrow', '⇔'),
			_Utils_Tuple2('DoubleLeftTee', '⫤'),
			_Utils_Tuple2('DoubleLongLeftArrow', '⟸'),
			_Utils_Tuple2('DoubleLongLeftRightArrow', '⟺'),
			_Utils_Tuple2('DoubleLongRightArrow', '⟹'),
			_Utils_Tuple2('DoubleRightArrow', '⇒'),
			_Utils_Tuple2('DoubleRightTee', '⊨'),
			_Utils_Tuple2('DoubleUpArrow', '⇑'),
			_Utils_Tuple2('DoubleUpDownArrow', '⇕'),
			_Utils_Tuple2('DoubleVerticalBar', '∥'),
			_Utils_Tuple2('DownArrowBar', '⤓'),
			_Utils_Tuple2('downarrow', '↓'),
			_Utils_Tuple2('DownArrow', '↓'),
			_Utils_Tuple2('Downarrow', '⇓'),
			_Utils_Tuple2('DownArrowUpArrow', '⇵'),
			_Utils_Tuple2('DownBreve', '̑'),
			_Utils_Tuple2('downdownarrows', '⇊'),
			_Utils_Tuple2('downharpoonleft', '⇃'),
			_Utils_Tuple2('downharpoonright', '⇂'),
			_Utils_Tuple2('DownLeftRightVector', '⥐'),
			_Utils_Tuple2('DownLeftTeeVector', '⥞'),
			_Utils_Tuple2('DownLeftVectorBar', '⥖'),
			_Utils_Tuple2('DownLeftVector', '↽'),
			_Utils_Tuple2('DownRightTeeVector', '⥟'),
			_Utils_Tuple2('DownRightVectorBar', '⥗'),
			_Utils_Tuple2('DownRightVector', '⇁'),
			_Utils_Tuple2('DownTeeArrow', '↧'),
			_Utils_Tuple2('DownTee', '⊤'),
			_Utils_Tuple2('drbkarow', '⤐'),
			_Utils_Tuple2('drcorn', '⌟'),
			_Utils_Tuple2('drcrop', '⌌'),
			_Utils_Tuple2('Dscr', '\uD835\uDC9F'),
			_Utils_Tuple2('dscr', '\uD835\uDCB9'),
			_Utils_Tuple2('DScy', 'Ѕ'),
			_Utils_Tuple2('dscy', 'ѕ'),
			_Utils_Tuple2('dsol', '⧶'),
			_Utils_Tuple2('Dstrok', 'Đ'),
			_Utils_Tuple2('dstrok', 'đ'),
			_Utils_Tuple2('dtdot', '⋱'),
			_Utils_Tuple2('dtri', '▿'),
			_Utils_Tuple2('dtrif', '▾'),
			_Utils_Tuple2('duarr', '⇵'),
			_Utils_Tuple2('duhar', '⥯'),
			_Utils_Tuple2('dwangle', '⦦'),
			_Utils_Tuple2('DZcy', 'Џ'),
			_Utils_Tuple2('dzcy', 'џ'),
			_Utils_Tuple2('dzigrarr', '⟿'),
			_Utils_Tuple2('Eacute', 'É'),
			_Utils_Tuple2('eacute', 'é'),
			_Utils_Tuple2('easter', '⩮'),
			_Utils_Tuple2('Ecaron', 'Ě'),
			_Utils_Tuple2('ecaron', 'ě'),
			_Utils_Tuple2('Ecirc', 'Ê'),
			_Utils_Tuple2('ecirc', 'ê'),
			_Utils_Tuple2('ecir', '≖'),
			_Utils_Tuple2('ecolon', '≕'),
			_Utils_Tuple2('Ecy', 'Э'),
			_Utils_Tuple2('ecy', 'э'),
			_Utils_Tuple2('eDDot', '⩷'),
			_Utils_Tuple2('Edot', 'Ė'),
			_Utils_Tuple2('edot', 'ė'),
			_Utils_Tuple2('eDot', '≑'),
			_Utils_Tuple2('ee', 'ⅇ'),
			_Utils_Tuple2('efDot', '≒'),
			_Utils_Tuple2('Efr', '\uD835\uDD08'),
			_Utils_Tuple2('efr', '\uD835\uDD22'),
			_Utils_Tuple2('eg', '⪚'),
			_Utils_Tuple2('Egrave', 'È'),
			_Utils_Tuple2('egrave', 'è'),
			_Utils_Tuple2('egs', '⪖'),
			_Utils_Tuple2('egsdot', '⪘'),
			_Utils_Tuple2('el', '⪙'),
			_Utils_Tuple2('Element', '∈'),
			_Utils_Tuple2('elinters', '⏧'),
			_Utils_Tuple2('ell', 'ℓ'),
			_Utils_Tuple2('els', '⪕'),
			_Utils_Tuple2('elsdot', '⪗'),
			_Utils_Tuple2('Emacr', 'Ē'),
			_Utils_Tuple2('emacr', 'ē'),
			_Utils_Tuple2('empty', '∅'),
			_Utils_Tuple2('emptyset', '∅'),
			_Utils_Tuple2('EmptySmallSquare', '◻'),
			_Utils_Tuple2('emptyv', '∅'),
			_Utils_Tuple2('EmptyVerySmallSquare', '▫'),
			_Utils_Tuple2('emsp13', '\u2004'),
			_Utils_Tuple2('emsp14', '\u2005'),
			_Utils_Tuple2('emsp', '\u2003'),
			_Utils_Tuple2('ENG', 'Ŋ'),
			_Utils_Tuple2('eng', 'ŋ'),
			_Utils_Tuple2('ensp', '\u2002'),
			_Utils_Tuple2('Eogon', 'Ę'),
			_Utils_Tuple2('eogon', 'ę'),
			_Utils_Tuple2('Eopf', '\uD835\uDD3C'),
			_Utils_Tuple2('eopf', '\uD835\uDD56'),
			_Utils_Tuple2('epar', '⋕'),
			_Utils_Tuple2('eparsl', '⧣'),
			_Utils_Tuple2('eplus', '⩱'),
			_Utils_Tuple2('epsi', 'ε'),
			_Utils_Tuple2('Epsilon', 'Ε'),
			_Utils_Tuple2('epsilon', 'ε'),
			_Utils_Tuple2('epsiv', 'ϵ'),
			_Utils_Tuple2('eqcirc', '≖'),
			_Utils_Tuple2('eqcolon', '≕'),
			_Utils_Tuple2('eqsim', '≂'),
			_Utils_Tuple2('eqslantgtr', '⪖'),
			_Utils_Tuple2('eqslantless', '⪕'),
			_Utils_Tuple2('Equal', '⩵'),
			_Utils_Tuple2('equals', '='),
			_Utils_Tuple2('EqualTilde', '≂'),
			_Utils_Tuple2('equest', '≟'),
			_Utils_Tuple2('Equilibrium', '⇌'),
			_Utils_Tuple2('equiv', '≡'),
			_Utils_Tuple2('equivDD', '⩸'),
			_Utils_Tuple2('eqvparsl', '⧥'),
			_Utils_Tuple2('erarr', '⥱'),
			_Utils_Tuple2('erDot', '≓'),
			_Utils_Tuple2('escr', 'ℯ'),
			_Utils_Tuple2('Escr', 'ℰ'),
			_Utils_Tuple2('esdot', '≐'),
			_Utils_Tuple2('Esim', '⩳'),
			_Utils_Tuple2('esim', '≂'),
			_Utils_Tuple2('Eta', 'Η'),
			_Utils_Tuple2('eta', 'η'),
			_Utils_Tuple2('ETH', 'Ð'),
			_Utils_Tuple2('eth', 'ð'),
			_Utils_Tuple2('Euml', 'Ë'),
			_Utils_Tuple2('euml', 'ë'),
			_Utils_Tuple2('euro', '€'),
			_Utils_Tuple2('excl', '!'),
			_Utils_Tuple2('exist', '∃'),
			_Utils_Tuple2('Exists', '∃'),
			_Utils_Tuple2('expectation', 'ℰ'),
			_Utils_Tuple2('exponentiale', 'ⅇ'),
			_Utils_Tuple2('ExponentialE', 'ⅇ'),
			_Utils_Tuple2('fallingdotseq', '≒'),
			_Utils_Tuple2('Fcy', 'Ф'),
			_Utils_Tuple2('fcy', 'ф'),
			_Utils_Tuple2('female', '♀'),
			_Utils_Tuple2('ffilig', 'ﬃ'),
			_Utils_Tuple2('fflig', 'ﬀ'),
			_Utils_Tuple2('ffllig', 'ﬄ'),
			_Utils_Tuple2('Ffr', '\uD835\uDD09'),
			_Utils_Tuple2('ffr', '\uD835\uDD23'),
			_Utils_Tuple2('filig', 'ﬁ'),
			_Utils_Tuple2('FilledSmallSquare', '◼'),
			_Utils_Tuple2('FilledVerySmallSquare', '▪'),
			_Utils_Tuple2('fjlig', 'fj'),
			_Utils_Tuple2('flat', '♭'),
			_Utils_Tuple2('fllig', 'ﬂ'),
			_Utils_Tuple2('fltns', '▱'),
			_Utils_Tuple2('fnof', 'ƒ'),
			_Utils_Tuple2('Fopf', '\uD835\uDD3D'),
			_Utils_Tuple2('fopf', '\uD835\uDD57'),
			_Utils_Tuple2('forall', '∀'),
			_Utils_Tuple2('ForAll', '∀'),
			_Utils_Tuple2('fork', '⋔'),
			_Utils_Tuple2('forkv', '⫙'),
			_Utils_Tuple2('Fouriertrf', 'ℱ'),
			_Utils_Tuple2('fpartint', '⨍'),
			_Utils_Tuple2('frac12', '½'),
			_Utils_Tuple2('frac13', '⅓'),
			_Utils_Tuple2('frac14', '¼'),
			_Utils_Tuple2('frac15', '⅕'),
			_Utils_Tuple2('frac16', '⅙'),
			_Utils_Tuple2('frac18', '⅛'),
			_Utils_Tuple2('frac23', '⅔'),
			_Utils_Tuple2('frac25', '⅖'),
			_Utils_Tuple2('frac34', '¾'),
			_Utils_Tuple2('frac35', '⅗'),
			_Utils_Tuple2('frac38', '⅜'),
			_Utils_Tuple2('frac45', '⅘'),
			_Utils_Tuple2('frac56', '⅚'),
			_Utils_Tuple2('frac58', '⅝'),
			_Utils_Tuple2('frac78', '⅞'),
			_Utils_Tuple2('frasl', '⁄'),
			_Utils_Tuple2('frown', '⌢'),
			_Utils_Tuple2('fscr', '\uD835\uDCBB'),
			_Utils_Tuple2('Fscr', 'ℱ'),
			_Utils_Tuple2('gacute', 'ǵ'),
			_Utils_Tuple2('Gamma', 'Γ'),
			_Utils_Tuple2('gamma', 'γ'),
			_Utils_Tuple2('Gammad', 'Ϝ'),
			_Utils_Tuple2('gammad', 'ϝ'),
			_Utils_Tuple2('gap', '⪆'),
			_Utils_Tuple2('Gbreve', 'Ğ'),
			_Utils_Tuple2('gbreve', 'ğ'),
			_Utils_Tuple2('Gcedil', 'Ģ'),
			_Utils_Tuple2('Gcirc', 'Ĝ'),
			_Utils_Tuple2('gcirc', 'ĝ'),
			_Utils_Tuple2('Gcy', 'Г'),
			_Utils_Tuple2('gcy', 'г'),
			_Utils_Tuple2('Gdot', 'Ġ'),
			_Utils_Tuple2('gdot', 'ġ'),
			_Utils_Tuple2('ge', '≥'),
			_Utils_Tuple2('gE', '≧'),
			_Utils_Tuple2('gEl', '⪌'),
			_Utils_Tuple2('gel', '⋛'),
			_Utils_Tuple2('geq', '≥'),
			_Utils_Tuple2('geqq', '≧'),
			_Utils_Tuple2('geqslant', '⩾'),
			_Utils_Tuple2('gescc', '⪩'),
			_Utils_Tuple2('ges', '⩾'),
			_Utils_Tuple2('gesdot', '⪀'),
			_Utils_Tuple2('gesdoto', '⪂'),
			_Utils_Tuple2('gesdotol', '⪄'),
			_Utils_Tuple2('gesl', '⋛︀'),
			_Utils_Tuple2('gesles', '⪔'),
			_Utils_Tuple2('Gfr', '\uD835\uDD0A'),
			_Utils_Tuple2('gfr', '\uD835\uDD24'),
			_Utils_Tuple2('gg', '≫'),
			_Utils_Tuple2('Gg', '⋙'),
			_Utils_Tuple2('ggg', '⋙'),
			_Utils_Tuple2('gimel', 'ℷ'),
			_Utils_Tuple2('GJcy', 'Ѓ'),
			_Utils_Tuple2('gjcy', 'ѓ'),
			_Utils_Tuple2('gla', '⪥'),
			_Utils_Tuple2('gl', '≷'),
			_Utils_Tuple2('glE', '⪒'),
			_Utils_Tuple2('glj', '⪤'),
			_Utils_Tuple2('gnap', '⪊'),
			_Utils_Tuple2('gnapprox', '⪊'),
			_Utils_Tuple2('gne', '⪈'),
			_Utils_Tuple2('gnE', '≩'),
			_Utils_Tuple2('gneq', '⪈'),
			_Utils_Tuple2('gneqq', '≩'),
			_Utils_Tuple2('gnsim', '⋧'),
			_Utils_Tuple2('Gopf', '\uD835\uDD3E'),
			_Utils_Tuple2('gopf', '\uD835\uDD58'),
			_Utils_Tuple2('grave', '`'),
			_Utils_Tuple2('GreaterEqual', '≥'),
			_Utils_Tuple2('GreaterEqualLess', '⋛'),
			_Utils_Tuple2('GreaterFullEqual', '≧'),
			_Utils_Tuple2('GreaterGreater', '⪢'),
			_Utils_Tuple2('GreaterLess', '≷'),
			_Utils_Tuple2('GreaterSlantEqual', '⩾'),
			_Utils_Tuple2('GreaterTilde', '≳'),
			_Utils_Tuple2('Gscr', '\uD835\uDCA2'),
			_Utils_Tuple2('gscr', 'ℊ'),
			_Utils_Tuple2('gsim', '≳'),
			_Utils_Tuple2('gsime', '⪎'),
			_Utils_Tuple2('gsiml', '⪐'),
			_Utils_Tuple2('gtcc', '⪧'),
			_Utils_Tuple2('gtcir', '⩺'),
			_Utils_Tuple2('gt', '>'),
			_Utils_Tuple2('GT', '>'),
			_Utils_Tuple2('Gt', '≫'),
			_Utils_Tuple2('gtdot', '⋗'),
			_Utils_Tuple2('gtlPar', '⦕'),
			_Utils_Tuple2('gtquest', '⩼'),
			_Utils_Tuple2('gtrapprox', '⪆'),
			_Utils_Tuple2('gtrarr', '⥸'),
			_Utils_Tuple2('gtrdot', '⋗'),
			_Utils_Tuple2('gtreqless', '⋛'),
			_Utils_Tuple2('gtreqqless', '⪌'),
			_Utils_Tuple2('gtrless', '≷'),
			_Utils_Tuple2('gtrsim', '≳'),
			_Utils_Tuple2('gvertneqq', '≩︀'),
			_Utils_Tuple2('gvnE', '≩︀'),
			_Utils_Tuple2('Hacek', 'ˇ'),
			_Utils_Tuple2('hairsp', '\u200A'),
			_Utils_Tuple2('half', '½'),
			_Utils_Tuple2('hamilt', 'ℋ'),
			_Utils_Tuple2('HARDcy', 'Ъ'),
			_Utils_Tuple2('hardcy', 'ъ'),
			_Utils_Tuple2('harrcir', '⥈'),
			_Utils_Tuple2('harr', '↔'),
			_Utils_Tuple2('hArr', '⇔'),
			_Utils_Tuple2('harrw', '↭'),
			_Utils_Tuple2('Hat', '^'),
			_Utils_Tuple2('hbar', 'ℏ'),
			_Utils_Tuple2('Hcirc', 'Ĥ'),
			_Utils_Tuple2('hcirc', 'ĥ'),
			_Utils_Tuple2('hearts', '♥'),
			_Utils_Tuple2('heartsuit', '♥'),
			_Utils_Tuple2('hellip', '…'),
			_Utils_Tuple2('hercon', '⊹'),
			_Utils_Tuple2('hfr', '\uD835\uDD25'),
			_Utils_Tuple2('Hfr', 'ℌ'),
			_Utils_Tuple2('HilbertSpace', 'ℋ'),
			_Utils_Tuple2('hksearow', '⤥'),
			_Utils_Tuple2('hkswarow', '⤦'),
			_Utils_Tuple2('hoarr', '⇿'),
			_Utils_Tuple2('homtht', '∻'),
			_Utils_Tuple2('hookleftarrow', '↩'),
			_Utils_Tuple2('hookrightarrow', '↪'),
			_Utils_Tuple2('hopf', '\uD835\uDD59'),
			_Utils_Tuple2('Hopf', 'ℍ'),
			_Utils_Tuple2('horbar', '―'),
			_Utils_Tuple2('HorizontalLine', '─'),
			_Utils_Tuple2('hscr', '\uD835\uDCBD'),
			_Utils_Tuple2('Hscr', 'ℋ'),
			_Utils_Tuple2('hslash', 'ℏ'),
			_Utils_Tuple2('Hstrok', 'Ħ'),
			_Utils_Tuple2('hstrok', 'ħ'),
			_Utils_Tuple2('HumpDownHump', '≎'),
			_Utils_Tuple2('HumpEqual', '≏'),
			_Utils_Tuple2('hybull', '⁃'),
			_Utils_Tuple2('hyphen', '‐'),
			_Utils_Tuple2('Iacute', 'Í'),
			_Utils_Tuple2('iacute', 'í'),
			_Utils_Tuple2('ic', '\u2063'),
			_Utils_Tuple2('Icirc', 'Î'),
			_Utils_Tuple2('icirc', 'î'),
			_Utils_Tuple2('Icy', 'И'),
			_Utils_Tuple2('icy', 'и'),
			_Utils_Tuple2('Idot', 'İ'),
			_Utils_Tuple2('IEcy', 'Е'),
			_Utils_Tuple2('iecy', 'е'),
			_Utils_Tuple2('iexcl', '¡'),
			_Utils_Tuple2('iff', '⇔'),
			_Utils_Tuple2('ifr', '\uD835\uDD26'),
			_Utils_Tuple2('Ifr', 'ℑ'),
			_Utils_Tuple2('Igrave', 'Ì'),
			_Utils_Tuple2('igrave', 'ì'),
			_Utils_Tuple2('ii', 'ⅈ'),
			_Utils_Tuple2('iiiint', '⨌'),
			_Utils_Tuple2('iiint', '∭'),
			_Utils_Tuple2('iinfin', '⧜'),
			_Utils_Tuple2('iiota', '℩'),
			_Utils_Tuple2('IJlig', 'Ĳ'),
			_Utils_Tuple2('ijlig', 'ĳ'),
			_Utils_Tuple2('Imacr', 'Ī'),
			_Utils_Tuple2('imacr', 'ī'),
			_Utils_Tuple2('image', 'ℑ'),
			_Utils_Tuple2('ImaginaryI', 'ⅈ'),
			_Utils_Tuple2('imagline', 'ℐ'),
			_Utils_Tuple2('imagpart', 'ℑ'),
			_Utils_Tuple2('imath', 'ı'),
			_Utils_Tuple2('Im', 'ℑ'),
			_Utils_Tuple2('imof', '⊷'),
			_Utils_Tuple2('imped', 'Ƶ'),
			_Utils_Tuple2('Implies', '⇒'),
			_Utils_Tuple2('incare', '℅'),
			_Utils_Tuple2('in', '∈'),
			_Utils_Tuple2('infin', '∞'),
			_Utils_Tuple2('infintie', '⧝'),
			_Utils_Tuple2('inodot', 'ı'),
			_Utils_Tuple2('intcal', '⊺'),
			_Utils_Tuple2('int', '∫'),
			_Utils_Tuple2('Int', '∬'),
			_Utils_Tuple2('integers', 'ℤ'),
			_Utils_Tuple2('Integral', '∫'),
			_Utils_Tuple2('intercal', '⊺'),
			_Utils_Tuple2('Intersection', '⋂'),
			_Utils_Tuple2('intlarhk', '⨗'),
			_Utils_Tuple2('intprod', '⨼'),
			_Utils_Tuple2('InvisibleComma', '\u2063'),
			_Utils_Tuple2('InvisibleTimes', '\u2062'),
			_Utils_Tuple2('IOcy', 'Ё'),
			_Utils_Tuple2('iocy', 'ё'),
			_Utils_Tuple2('Iogon', 'Į'),
			_Utils_Tuple2('iogon', 'į'),
			_Utils_Tuple2('Iopf', '\uD835\uDD40'),
			_Utils_Tuple2('iopf', '\uD835\uDD5A'),
			_Utils_Tuple2('Iota', 'Ι'),
			_Utils_Tuple2('iota', 'ι'),
			_Utils_Tuple2('iprod', '⨼'),
			_Utils_Tuple2('iquest', '¿'),
			_Utils_Tuple2('iscr', '\uD835\uDCBE'),
			_Utils_Tuple2('Iscr', 'ℐ'),
			_Utils_Tuple2('isin', '∈'),
			_Utils_Tuple2('isindot', '⋵'),
			_Utils_Tuple2('isinE', '⋹'),
			_Utils_Tuple2('isins', '⋴'),
			_Utils_Tuple2('isinsv', '⋳'),
			_Utils_Tuple2('isinv', '∈'),
			_Utils_Tuple2('it', '\u2062'),
			_Utils_Tuple2('Itilde', 'Ĩ'),
			_Utils_Tuple2('itilde', 'ĩ'),
			_Utils_Tuple2('Iukcy', 'І'),
			_Utils_Tuple2('iukcy', 'і'),
			_Utils_Tuple2('Iuml', 'Ï'),
			_Utils_Tuple2('iuml', 'ï'),
			_Utils_Tuple2('Jcirc', 'Ĵ'),
			_Utils_Tuple2('jcirc', 'ĵ'),
			_Utils_Tuple2('Jcy', 'Й'),
			_Utils_Tuple2('jcy', 'й'),
			_Utils_Tuple2('Jfr', '\uD835\uDD0D'),
			_Utils_Tuple2('jfr', '\uD835\uDD27'),
			_Utils_Tuple2('jmath', 'ȷ'),
			_Utils_Tuple2('Jopf', '\uD835\uDD41'),
			_Utils_Tuple2('jopf', '\uD835\uDD5B'),
			_Utils_Tuple2('Jscr', '\uD835\uDCA5'),
			_Utils_Tuple2('jscr', '\uD835\uDCBF'),
			_Utils_Tuple2('Jsercy', 'Ј'),
			_Utils_Tuple2('jsercy', 'ј'),
			_Utils_Tuple2('Jukcy', 'Є'),
			_Utils_Tuple2('jukcy', 'є'),
			_Utils_Tuple2('Kappa', 'Κ'),
			_Utils_Tuple2('kappa', 'κ'),
			_Utils_Tuple2('kappav', 'ϰ'),
			_Utils_Tuple2('Kcedil', 'Ķ'),
			_Utils_Tuple2('kcedil', 'ķ'),
			_Utils_Tuple2('Kcy', 'К'),
			_Utils_Tuple2('kcy', 'к'),
			_Utils_Tuple2('Kfr', '\uD835\uDD0E'),
			_Utils_Tuple2('kfr', '\uD835\uDD28'),
			_Utils_Tuple2('kgreen', 'ĸ'),
			_Utils_Tuple2('KHcy', 'Х'),
			_Utils_Tuple2('khcy', 'х'),
			_Utils_Tuple2('KJcy', 'Ќ'),
			_Utils_Tuple2('kjcy', 'ќ'),
			_Utils_Tuple2('Kopf', '\uD835\uDD42'),
			_Utils_Tuple2('kopf', '\uD835\uDD5C'),
			_Utils_Tuple2('Kscr', '\uD835\uDCA6'),
			_Utils_Tuple2('kscr', '\uD835\uDCC0'),
			_Utils_Tuple2('lAarr', '⇚'),
			_Utils_Tuple2('Lacute', 'Ĺ'),
			_Utils_Tuple2('lacute', 'ĺ'),
			_Utils_Tuple2('laemptyv', '⦴'),
			_Utils_Tuple2('lagran', 'ℒ'),
			_Utils_Tuple2('Lambda', 'Λ'),
			_Utils_Tuple2('lambda', 'λ'),
			_Utils_Tuple2('lang', '⟨'),
			_Utils_Tuple2('Lang', '⟪'),
			_Utils_Tuple2('langd', '⦑'),
			_Utils_Tuple2('langle', '⟨'),
			_Utils_Tuple2('lap', '⪅'),
			_Utils_Tuple2('Laplacetrf', 'ℒ'),
			_Utils_Tuple2('laquo', '«'),
			_Utils_Tuple2('larrb', '⇤'),
			_Utils_Tuple2('larrbfs', '⤟'),
			_Utils_Tuple2('larr', '←'),
			_Utils_Tuple2('Larr', '↞'),
			_Utils_Tuple2('lArr', '⇐'),
			_Utils_Tuple2('larrfs', '⤝'),
			_Utils_Tuple2('larrhk', '↩'),
			_Utils_Tuple2('larrlp', '↫'),
			_Utils_Tuple2('larrpl', '⤹'),
			_Utils_Tuple2('larrsim', '⥳'),
			_Utils_Tuple2('larrtl', '↢'),
			_Utils_Tuple2('latail', '⤙'),
			_Utils_Tuple2('lAtail', '⤛'),
			_Utils_Tuple2('lat', '⪫'),
			_Utils_Tuple2('late', '⪭'),
			_Utils_Tuple2('lates', '⪭︀'),
			_Utils_Tuple2('lbarr', '⤌'),
			_Utils_Tuple2('lBarr', '⤎'),
			_Utils_Tuple2('lbbrk', '❲'),
			_Utils_Tuple2('lbrace', '{'),
			_Utils_Tuple2('lbrack', '['),
			_Utils_Tuple2('lbrke', '⦋'),
			_Utils_Tuple2('lbrksld', '⦏'),
			_Utils_Tuple2('lbrkslu', '⦍'),
			_Utils_Tuple2('Lcaron', 'Ľ'),
			_Utils_Tuple2('lcaron', 'ľ'),
			_Utils_Tuple2('Lcedil', 'Ļ'),
			_Utils_Tuple2('lcedil', 'ļ'),
			_Utils_Tuple2('lceil', '⌈'),
			_Utils_Tuple2('lcub', '{'),
			_Utils_Tuple2('Lcy', 'Л'),
			_Utils_Tuple2('lcy', 'л'),
			_Utils_Tuple2('ldca', '⤶'),
			_Utils_Tuple2('ldquo', '“'),
			_Utils_Tuple2('ldquor', '„'),
			_Utils_Tuple2('ldrdhar', '⥧'),
			_Utils_Tuple2('ldrushar', '⥋'),
			_Utils_Tuple2('ldsh', '↲'),
			_Utils_Tuple2('le', '≤'),
			_Utils_Tuple2('lE', '≦'),
			_Utils_Tuple2('LeftAngleBracket', '⟨'),
			_Utils_Tuple2('LeftArrowBar', '⇤'),
			_Utils_Tuple2('leftarrow', '←'),
			_Utils_Tuple2('LeftArrow', '←'),
			_Utils_Tuple2('Leftarrow', '⇐'),
			_Utils_Tuple2('LeftArrowRightArrow', '⇆'),
			_Utils_Tuple2('leftarrowtail', '↢'),
			_Utils_Tuple2('LeftCeiling', '⌈'),
			_Utils_Tuple2('LeftDoubleBracket', '⟦'),
			_Utils_Tuple2('LeftDownTeeVector', '⥡'),
			_Utils_Tuple2('LeftDownVectorBar', '⥙'),
			_Utils_Tuple2('LeftDownVector', '⇃'),
			_Utils_Tuple2('LeftFloor', '⌊'),
			_Utils_Tuple2('leftharpoondown', '↽'),
			_Utils_Tuple2('leftharpoonup', '↼'),
			_Utils_Tuple2('leftleftarrows', '⇇'),
			_Utils_Tuple2('leftrightarrow', '↔'),
			_Utils_Tuple2('LeftRightArrow', '↔'),
			_Utils_Tuple2('Leftrightarrow', '⇔'),
			_Utils_Tuple2('leftrightarrows', '⇆'),
			_Utils_Tuple2('leftrightharpoons', '⇋'),
			_Utils_Tuple2('leftrightsquigarrow', '↭'),
			_Utils_Tuple2('LeftRightVector', '⥎'),
			_Utils_Tuple2('LeftTeeArrow', '↤'),
			_Utils_Tuple2('LeftTee', '⊣'),
			_Utils_Tuple2('LeftTeeVector', '⥚'),
			_Utils_Tuple2('leftthreetimes', '⋋'),
			_Utils_Tuple2('LeftTriangleBar', '⧏'),
			_Utils_Tuple2('LeftTriangle', '⊲'),
			_Utils_Tuple2('LeftTriangleEqual', '⊴'),
			_Utils_Tuple2('LeftUpDownVector', '⥑'),
			_Utils_Tuple2('LeftUpTeeVector', '⥠'),
			_Utils_Tuple2('LeftUpVectorBar', '⥘'),
			_Utils_Tuple2('LeftUpVector', '↿'),
			_Utils_Tuple2('LeftVectorBar', '⥒'),
			_Utils_Tuple2('LeftVector', '↼'),
			_Utils_Tuple2('lEg', '⪋'),
			_Utils_Tuple2('leg', '⋚'),
			_Utils_Tuple2('leq', '≤'),
			_Utils_Tuple2('leqq', '≦'),
			_Utils_Tuple2('leqslant', '⩽'),
			_Utils_Tuple2('lescc', '⪨'),
			_Utils_Tuple2('les', '⩽'),
			_Utils_Tuple2('lesdot', '⩿'),
			_Utils_Tuple2('lesdoto', '⪁'),
			_Utils_Tuple2('lesdotor', '⪃'),
			_Utils_Tuple2('lesg', '⋚︀'),
			_Utils_Tuple2('lesges', '⪓'),
			_Utils_Tuple2('lessapprox', '⪅'),
			_Utils_Tuple2('lessdot', '⋖'),
			_Utils_Tuple2('lesseqgtr', '⋚'),
			_Utils_Tuple2('lesseqqgtr', '⪋'),
			_Utils_Tuple2('LessEqualGreater', '⋚'),
			_Utils_Tuple2('LessFullEqual', '≦'),
			_Utils_Tuple2('LessGreater', '≶'),
			_Utils_Tuple2('lessgtr', '≶'),
			_Utils_Tuple2('LessLess', '⪡'),
			_Utils_Tuple2('lesssim', '≲'),
			_Utils_Tuple2('LessSlantEqual', '⩽'),
			_Utils_Tuple2('LessTilde', '≲'),
			_Utils_Tuple2('lfisht', '⥼'),
			_Utils_Tuple2('lfloor', '⌊'),
			_Utils_Tuple2('Lfr', '\uD835\uDD0F'),
			_Utils_Tuple2('lfr', '\uD835\uDD29'),
			_Utils_Tuple2('lg', '≶'),
			_Utils_Tuple2('lgE', '⪑'),
			_Utils_Tuple2('lHar', '⥢'),
			_Utils_Tuple2('lhard', '↽'),
			_Utils_Tuple2('lharu', '↼'),
			_Utils_Tuple2('lharul', '⥪'),
			_Utils_Tuple2('lhblk', '▄'),
			_Utils_Tuple2('LJcy', 'Љ'),
			_Utils_Tuple2('ljcy', 'љ'),
			_Utils_Tuple2('llarr', '⇇'),
			_Utils_Tuple2('ll', '≪'),
			_Utils_Tuple2('Ll', '⋘'),
			_Utils_Tuple2('llcorner', '⌞'),
			_Utils_Tuple2('Lleftarrow', '⇚'),
			_Utils_Tuple2('llhard', '⥫'),
			_Utils_Tuple2('lltri', '◺'),
			_Utils_Tuple2('Lmidot', 'Ŀ'),
			_Utils_Tuple2('lmidot', 'ŀ'),
			_Utils_Tuple2('lmoustache', '⎰'),
			_Utils_Tuple2('lmoust', '⎰'),
			_Utils_Tuple2('lnap', '⪉'),
			_Utils_Tuple2('lnapprox', '⪉'),
			_Utils_Tuple2('lne', '⪇'),
			_Utils_Tuple2('lnE', '≨'),
			_Utils_Tuple2('lneq', '⪇'),
			_Utils_Tuple2('lneqq', '≨'),
			_Utils_Tuple2('lnsim', '⋦'),
			_Utils_Tuple2('loang', '⟬'),
			_Utils_Tuple2('loarr', '⇽'),
			_Utils_Tuple2('lobrk', '⟦'),
			_Utils_Tuple2('longleftarrow', '⟵'),
			_Utils_Tuple2('LongLeftArrow', '⟵'),
			_Utils_Tuple2('Longleftarrow', '⟸'),
			_Utils_Tuple2('longleftrightarrow', '⟷'),
			_Utils_Tuple2('LongLeftRightArrow', '⟷'),
			_Utils_Tuple2('Longleftrightarrow', '⟺'),
			_Utils_Tuple2('longmapsto', '⟼'),
			_Utils_Tuple2('longrightarrow', '⟶'),
			_Utils_Tuple2('LongRightArrow', '⟶'),
			_Utils_Tuple2('Longrightarrow', '⟹'),
			_Utils_Tuple2('looparrowleft', '↫'),
			_Utils_Tuple2('looparrowright', '↬'),
			_Utils_Tuple2('lopar', '⦅'),
			_Utils_Tuple2('Lopf', '\uD835\uDD43'),
			_Utils_Tuple2('lopf', '\uD835\uDD5D'),
			_Utils_Tuple2('loplus', '⨭'),
			_Utils_Tuple2('lotimes', '⨴'),
			_Utils_Tuple2('lowast', '∗'),
			_Utils_Tuple2('lowbar', '_'),
			_Utils_Tuple2('LowerLeftArrow', '↙'),
			_Utils_Tuple2('LowerRightArrow', '↘'),
			_Utils_Tuple2('loz', '◊'),
			_Utils_Tuple2('lozenge', '◊'),
			_Utils_Tuple2('lozf', '⧫'),
			_Utils_Tuple2('lpar', '('),
			_Utils_Tuple2('lparlt', '⦓'),
			_Utils_Tuple2('lrarr', '⇆'),
			_Utils_Tuple2('lrcorner', '⌟'),
			_Utils_Tuple2('lrhar', '⇋'),
			_Utils_Tuple2('lrhard', '⥭'),
			_Utils_Tuple2('lrm', '\u200E'),
			_Utils_Tuple2('lrtri', '⊿'),
			_Utils_Tuple2('lsaquo', '‹'),
			_Utils_Tuple2('lscr', '\uD835\uDCC1'),
			_Utils_Tuple2('Lscr', 'ℒ'),
			_Utils_Tuple2('lsh', '↰'),
			_Utils_Tuple2('Lsh', '↰'),
			_Utils_Tuple2('lsim', '≲'),
			_Utils_Tuple2('lsime', '⪍'),
			_Utils_Tuple2('lsimg', '⪏'),
			_Utils_Tuple2('lsqb', '['),
			_Utils_Tuple2('lsquo', '‘'),
			_Utils_Tuple2('lsquor', '‚'),
			_Utils_Tuple2('Lstrok', 'Ł'),
			_Utils_Tuple2('lstrok', 'ł'),
			_Utils_Tuple2('ltcc', '⪦'),
			_Utils_Tuple2('ltcir', '⩹'),
			_Utils_Tuple2('lt', '<'),
			_Utils_Tuple2('LT', '<'),
			_Utils_Tuple2('Lt', '≪'),
			_Utils_Tuple2('ltdot', '⋖'),
			_Utils_Tuple2('lthree', '⋋'),
			_Utils_Tuple2('ltimes', '⋉'),
			_Utils_Tuple2('ltlarr', '⥶'),
			_Utils_Tuple2('ltquest', '⩻'),
			_Utils_Tuple2('ltri', '◃'),
			_Utils_Tuple2('ltrie', '⊴'),
			_Utils_Tuple2('ltrif', '◂'),
			_Utils_Tuple2('ltrPar', '⦖'),
			_Utils_Tuple2('lurdshar', '⥊'),
			_Utils_Tuple2('luruhar', '⥦'),
			_Utils_Tuple2('lvertneqq', '≨︀'),
			_Utils_Tuple2('lvnE', '≨︀'),
			_Utils_Tuple2('macr', '¯'),
			_Utils_Tuple2('male', '♂'),
			_Utils_Tuple2('malt', '✠'),
			_Utils_Tuple2('maltese', '✠'),
			_Utils_Tuple2('Map', '⤅'),
			_Utils_Tuple2('map', '↦'),
			_Utils_Tuple2('mapsto', '↦'),
			_Utils_Tuple2('mapstodown', '↧'),
			_Utils_Tuple2('mapstoleft', '↤'),
			_Utils_Tuple2('mapstoup', '↥'),
			_Utils_Tuple2('marker', '▮'),
			_Utils_Tuple2('mcomma', '⨩'),
			_Utils_Tuple2('Mcy', 'М'),
			_Utils_Tuple2('mcy', 'м'),
			_Utils_Tuple2('mdash', '—'),
			_Utils_Tuple2('mDDot', '∺'),
			_Utils_Tuple2('measuredangle', '∡'),
			_Utils_Tuple2('MediumSpace', '\u205F'),
			_Utils_Tuple2('Mellintrf', 'ℳ'),
			_Utils_Tuple2('Mfr', '\uD835\uDD10'),
			_Utils_Tuple2('mfr', '\uD835\uDD2A'),
			_Utils_Tuple2('mho', '℧'),
			_Utils_Tuple2('micro', 'µ'),
			_Utils_Tuple2('midast', '*'),
			_Utils_Tuple2('midcir', '⫰'),
			_Utils_Tuple2('mid', '∣'),
			_Utils_Tuple2('middot', '·'),
			_Utils_Tuple2('minusb', '⊟'),
			_Utils_Tuple2('minus', '−'),
			_Utils_Tuple2('minusd', '∸'),
			_Utils_Tuple2('minusdu', '⨪'),
			_Utils_Tuple2('MinusPlus', '∓'),
			_Utils_Tuple2('mlcp', '⫛'),
			_Utils_Tuple2('mldr', '…'),
			_Utils_Tuple2('mnplus', '∓'),
			_Utils_Tuple2('models', '⊧'),
			_Utils_Tuple2('Mopf', '\uD835\uDD44'),
			_Utils_Tuple2('mopf', '\uD835\uDD5E'),
			_Utils_Tuple2('mp', '∓'),
			_Utils_Tuple2('mscr', '\uD835\uDCC2'),
			_Utils_Tuple2('Mscr', 'ℳ'),
			_Utils_Tuple2('mstpos', '∾'),
			_Utils_Tuple2('Mu', 'Μ'),
			_Utils_Tuple2('mu', 'μ'),
			_Utils_Tuple2('multimap', '⊸'),
			_Utils_Tuple2('mumap', '⊸'),
			_Utils_Tuple2('nabla', '∇'),
			_Utils_Tuple2('Nacute', 'Ń'),
			_Utils_Tuple2('nacute', 'ń'),
			_Utils_Tuple2('nang', '∠⃒'),
			_Utils_Tuple2('nap', '≉'),
			_Utils_Tuple2('napE', '⩰̸'),
			_Utils_Tuple2('napid', '≋̸'),
			_Utils_Tuple2('napos', 'ŉ'),
			_Utils_Tuple2('napprox', '≉'),
			_Utils_Tuple2('natural', '♮'),
			_Utils_Tuple2('naturals', 'ℕ'),
			_Utils_Tuple2('natur', '♮'),
			_Utils_Tuple2('nbsp', '\u00A0'),
			_Utils_Tuple2('nbump', '≎̸'),
			_Utils_Tuple2('nbumpe', '≏̸'),
			_Utils_Tuple2('ncap', '⩃'),
			_Utils_Tuple2('Ncaron', 'Ň'),
			_Utils_Tuple2('ncaron', 'ň'),
			_Utils_Tuple2('Ncedil', 'Ņ'),
			_Utils_Tuple2('ncedil', 'ņ'),
			_Utils_Tuple2('ncong', '≇'),
			_Utils_Tuple2('ncongdot', '⩭̸'),
			_Utils_Tuple2('ncup', '⩂'),
			_Utils_Tuple2('Ncy', 'Н'),
			_Utils_Tuple2('ncy', 'н'),
			_Utils_Tuple2('ndash', '–'),
			_Utils_Tuple2('nearhk', '⤤'),
			_Utils_Tuple2('nearr', '↗'),
			_Utils_Tuple2('neArr', '⇗'),
			_Utils_Tuple2('nearrow', '↗'),
			_Utils_Tuple2('ne', '≠'),
			_Utils_Tuple2('nedot', '≐̸'),
			_Utils_Tuple2('NegativeMediumSpace', '\u200B'),
			_Utils_Tuple2('NegativeThickSpace', '\u200B'),
			_Utils_Tuple2('NegativeThinSpace', '\u200B'),
			_Utils_Tuple2('NegativeVeryThinSpace', '\u200B'),
			_Utils_Tuple2('nequiv', '≢'),
			_Utils_Tuple2('nesear', '⤨'),
			_Utils_Tuple2('nesim', '≂̸'),
			_Utils_Tuple2('NestedGreaterGreater', '≫'),
			_Utils_Tuple2('NestedLessLess', '≪'),
			_Utils_Tuple2('NewLine', '\n'),
			_Utils_Tuple2('nexist', '∄'),
			_Utils_Tuple2('nexists', '∄'),
			_Utils_Tuple2('Nfr', '\uD835\uDD11'),
			_Utils_Tuple2('nfr', '\uD835\uDD2B'),
			_Utils_Tuple2('ngE', '≧̸'),
			_Utils_Tuple2('nge', '≱'),
			_Utils_Tuple2('ngeq', '≱'),
			_Utils_Tuple2('ngeqq', '≧̸'),
			_Utils_Tuple2('ngeqslant', '⩾̸'),
			_Utils_Tuple2('nges', '⩾̸'),
			_Utils_Tuple2('nGg', '⋙̸'),
			_Utils_Tuple2('ngsim', '≵'),
			_Utils_Tuple2('nGt', '≫⃒'),
			_Utils_Tuple2('ngt', '≯'),
			_Utils_Tuple2('ngtr', '≯'),
			_Utils_Tuple2('nGtv', '≫̸'),
			_Utils_Tuple2('nharr', '↮'),
			_Utils_Tuple2('nhArr', '⇎'),
			_Utils_Tuple2('nhpar', '⫲'),
			_Utils_Tuple2('ni', '∋'),
			_Utils_Tuple2('nis', '⋼'),
			_Utils_Tuple2('nisd', '⋺'),
			_Utils_Tuple2('niv', '∋'),
			_Utils_Tuple2('NJcy', 'Њ'),
			_Utils_Tuple2('njcy', 'њ'),
			_Utils_Tuple2('nlarr', '↚'),
			_Utils_Tuple2('nlArr', '⇍'),
			_Utils_Tuple2('nldr', '‥'),
			_Utils_Tuple2('nlE', '≦̸'),
			_Utils_Tuple2('nle', '≰'),
			_Utils_Tuple2('nleftarrow', '↚'),
			_Utils_Tuple2('nLeftarrow', '⇍'),
			_Utils_Tuple2('nleftrightarrow', '↮'),
			_Utils_Tuple2('nLeftrightarrow', '⇎'),
			_Utils_Tuple2('nleq', '≰'),
			_Utils_Tuple2('nleqq', '≦̸'),
			_Utils_Tuple2('nleqslant', '⩽̸'),
			_Utils_Tuple2('nles', '⩽̸'),
			_Utils_Tuple2('nless', '≮'),
			_Utils_Tuple2('nLl', '⋘̸'),
			_Utils_Tuple2('nlsim', '≴'),
			_Utils_Tuple2('nLt', '≪⃒'),
			_Utils_Tuple2('nlt', '≮'),
			_Utils_Tuple2('nltri', '⋪'),
			_Utils_Tuple2('nltrie', '⋬'),
			_Utils_Tuple2('nLtv', '≪̸'),
			_Utils_Tuple2('nmid', '∤'),
			_Utils_Tuple2('NoBreak', '\u2060'),
			_Utils_Tuple2('NonBreakingSpace', '\u00A0'),
			_Utils_Tuple2('nopf', '\uD835\uDD5F'),
			_Utils_Tuple2('Nopf', 'ℕ'),
			_Utils_Tuple2('Not', '⫬'),
			_Utils_Tuple2('not', '¬'),
			_Utils_Tuple2('NotCongruent', '≢'),
			_Utils_Tuple2('NotCupCap', '≭'),
			_Utils_Tuple2('NotDoubleVerticalBar', '∦'),
			_Utils_Tuple2('NotElement', '∉'),
			_Utils_Tuple2('NotEqual', '≠'),
			_Utils_Tuple2('NotEqualTilde', '≂̸'),
			_Utils_Tuple2('NotExists', '∄'),
			_Utils_Tuple2('NotGreater', '≯'),
			_Utils_Tuple2('NotGreaterEqual', '≱'),
			_Utils_Tuple2('NotGreaterFullEqual', '≧̸'),
			_Utils_Tuple2('NotGreaterGreater', '≫̸'),
			_Utils_Tuple2('NotGreaterLess', '≹'),
			_Utils_Tuple2('NotGreaterSlantEqual', '⩾̸'),
			_Utils_Tuple2('NotGreaterTilde', '≵'),
			_Utils_Tuple2('NotHumpDownHump', '≎̸'),
			_Utils_Tuple2('NotHumpEqual', '≏̸'),
			_Utils_Tuple2('notin', '∉'),
			_Utils_Tuple2('notindot', '⋵̸'),
			_Utils_Tuple2('notinE', '⋹̸'),
			_Utils_Tuple2('notinva', '∉'),
			_Utils_Tuple2('notinvb', '⋷'),
			_Utils_Tuple2('notinvc', '⋶'),
			_Utils_Tuple2('NotLeftTriangleBar', '⧏̸'),
			_Utils_Tuple2('NotLeftTriangle', '⋪'),
			_Utils_Tuple2('NotLeftTriangleEqual', '⋬'),
			_Utils_Tuple2('NotLess', '≮'),
			_Utils_Tuple2('NotLessEqual', '≰'),
			_Utils_Tuple2('NotLessGreater', '≸'),
			_Utils_Tuple2('NotLessLess', '≪̸'),
			_Utils_Tuple2('NotLessSlantEqual', '⩽̸'),
			_Utils_Tuple2('NotLessTilde', '≴'),
			_Utils_Tuple2('NotNestedGreaterGreater', '⪢̸'),
			_Utils_Tuple2('NotNestedLessLess', '⪡̸'),
			_Utils_Tuple2('notni', '∌'),
			_Utils_Tuple2('notniva', '∌'),
			_Utils_Tuple2('notnivb', '⋾'),
			_Utils_Tuple2('notnivc', '⋽'),
			_Utils_Tuple2('NotPrecedes', '⊀'),
			_Utils_Tuple2('NotPrecedesEqual', '⪯̸'),
			_Utils_Tuple2('NotPrecedesSlantEqual', '⋠'),
			_Utils_Tuple2('NotReverseElement', '∌'),
			_Utils_Tuple2('NotRightTriangleBar', '⧐̸'),
			_Utils_Tuple2('NotRightTriangle', '⋫'),
			_Utils_Tuple2('NotRightTriangleEqual', '⋭'),
			_Utils_Tuple2('NotSquareSubset', '⊏̸'),
			_Utils_Tuple2('NotSquareSubsetEqual', '⋢'),
			_Utils_Tuple2('NotSquareSuperset', '⊐̸'),
			_Utils_Tuple2('NotSquareSupersetEqual', '⋣'),
			_Utils_Tuple2('NotSubset', '⊂⃒'),
			_Utils_Tuple2('NotSubsetEqual', '⊈'),
			_Utils_Tuple2('NotSucceeds', '⊁'),
			_Utils_Tuple2('NotSucceedsEqual', '⪰̸'),
			_Utils_Tuple2('NotSucceedsSlantEqual', '⋡'),
			_Utils_Tuple2('NotSucceedsTilde', '≿̸'),
			_Utils_Tuple2('NotSuperset', '⊃⃒'),
			_Utils_Tuple2('NotSupersetEqual', '⊉'),
			_Utils_Tuple2('NotTilde', '≁'),
			_Utils_Tuple2('NotTildeEqual', '≄'),
			_Utils_Tuple2('NotTildeFullEqual', '≇'),
			_Utils_Tuple2('NotTildeTilde', '≉'),
			_Utils_Tuple2('NotVerticalBar', '∤'),
			_Utils_Tuple2('nparallel', '∦'),
			_Utils_Tuple2('npar', '∦'),
			_Utils_Tuple2('nparsl', '⫽⃥'),
			_Utils_Tuple2('npart', '∂̸'),
			_Utils_Tuple2('npolint', '⨔'),
			_Utils_Tuple2('npr', '⊀'),
			_Utils_Tuple2('nprcue', '⋠'),
			_Utils_Tuple2('nprec', '⊀'),
			_Utils_Tuple2('npreceq', '⪯̸'),
			_Utils_Tuple2('npre', '⪯̸'),
			_Utils_Tuple2('nrarrc', '⤳̸'),
			_Utils_Tuple2('nrarr', '↛'),
			_Utils_Tuple2('nrArr', '⇏'),
			_Utils_Tuple2('nrarrw', '↝̸'),
			_Utils_Tuple2('nrightarrow', '↛'),
			_Utils_Tuple2('nRightarrow', '⇏'),
			_Utils_Tuple2('nrtri', '⋫'),
			_Utils_Tuple2('nrtrie', '⋭'),
			_Utils_Tuple2('nsc', '⊁'),
			_Utils_Tuple2('nsccue', '⋡'),
			_Utils_Tuple2('nsce', '⪰̸'),
			_Utils_Tuple2('Nscr', '\uD835\uDCA9'),
			_Utils_Tuple2('nscr', '\uD835\uDCC3'),
			_Utils_Tuple2('nshortmid', '∤'),
			_Utils_Tuple2('nshortparallel', '∦'),
			_Utils_Tuple2('nsim', '≁'),
			_Utils_Tuple2('nsime', '≄'),
			_Utils_Tuple2('nsimeq', '≄'),
			_Utils_Tuple2('nsmid', '∤'),
			_Utils_Tuple2('nspar', '∦'),
			_Utils_Tuple2('nsqsube', '⋢'),
			_Utils_Tuple2('nsqsupe', '⋣'),
			_Utils_Tuple2('nsub', '⊄'),
			_Utils_Tuple2('nsubE', '⫅̸'),
			_Utils_Tuple2('nsube', '⊈'),
			_Utils_Tuple2('nsubset', '⊂⃒'),
			_Utils_Tuple2('nsubseteq', '⊈'),
			_Utils_Tuple2('nsubseteqq', '⫅̸'),
			_Utils_Tuple2('nsucc', '⊁'),
			_Utils_Tuple2('nsucceq', '⪰̸'),
			_Utils_Tuple2('nsup', '⊅'),
			_Utils_Tuple2('nsupE', '⫆̸'),
			_Utils_Tuple2('nsupe', '⊉'),
			_Utils_Tuple2('nsupset', '⊃⃒'),
			_Utils_Tuple2('nsupseteq', '⊉'),
			_Utils_Tuple2('nsupseteqq', '⫆̸'),
			_Utils_Tuple2('ntgl', '≹'),
			_Utils_Tuple2('Ntilde', 'Ñ'),
			_Utils_Tuple2('ntilde', 'ñ'),
			_Utils_Tuple2('ntlg', '≸'),
			_Utils_Tuple2('ntriangleleft', '⋪'),
			_Utils_Tuple2('ntrianglelefteq', '⋬'),
			_Utils_Tuple2('ntriangleright', '⋫'),
			_Utils_Tuple2('ntrianglerighteq', '⋭'),
			_Utils_Tuple2('Nu', 'Ν'),
			_Utils_Tuple2('nu', 'ν'),
			_Utils_Tuple2('num', '#'),
			_Utils_Tuple2('numero', '№'),
			_Utils_Tuple2('numsp', '\u2007'),
			_Utils_Tuple2('nvap', '≍⃒'),
			_Utils_Tuple2('nvdash', '⊬'),
			_Utils_Tuple2('nvDash', '⊭'),
			_Utils_Tuple2('nVdash', '⊮'),
			_Utils_Tuple2('nVDash', '⊯'),
			_Utils_Tuple2('nvge', '≥⃒'),
			_Utils_Tuple2('nvgt', '>⃒'),
			_Utils_Tuple2('nvHarr', '⤄'),
			_Utils_Tuple2('nvinfin', '⧞'),
			_Utils_Tuple2('nvlArr', '⤂'),
			_Utils_Tuple2('nvle', '≤⃒'),
			_Utils_Tuple2('nvlt', '<⃒'),
			_Utils_Tuple2('nvltrie', '⊴⃒'),
			_Utils_Tuple2('nvrArr', '⤃'),
			_Utils_Tuple2('nvrtrie', '⊵⃒'),
			_Utils_Tuple2('nvsim', '∼⃒'),
			_Utils_Tuple2('nwarhk', '⤣'),
			_Utils_Tuple2('nwarr', '↖'),
			_Utils_Tuple2('nwArr', '⇖'),
			_Utils_Tuple2('nwarrow', '↖'),
			_Utils_Tuple2('nwnear', '⤧'),
			_Utils_Tuple2('Oacute', 'Ó'),
			_Utils_Tuple2('oacute', 'ó'),
			_Utils_Tuple2('oast', '⊛'),
			_Utils_Tuple2('Ocirc', 'Ô'),
			_Utils_Tuple2('ocirc', 'ô'),
			_Utils_Tuple2('ocir', '⊚'),
			_Utils_Tuple2('Ocy', 'О'),
			_Utils_Tuple2('ocy', 'о'),
			_Utils_Tuple2('odash', '⊝'),
			_Utils_Tuple2('Odblac', 'Ő'),
			_Utils_Tuple2('odblac', 'ő'),
			_Utils_Tuple2('odiv', '⨸'),
			_Utils_Tuple2('odot', '⊙'),
			_Utils_Tuple2('odsold', '⦼'),
			_Utils_Tuple2('OElig', 'Œ'),
			_Utils_Tuple2('oelig', 'œ'),
			_Utils_Tuple2('ofcir', '⦿'),
			_Utils_Tuple2('Ofr', '\uD835\uDD12'),
			_Utils_Tuple2('ofr', '\uD835\uDD2C'),
			_Utils_Tuple2('ogon', '˛'),
			_Utils_Tuple2('Ograve', 'Ò'),
			_Utils_Tuple2('ograve', 'ò'),
			_Utils_Tuple2('ogt', '⧁'),
			_Utils_Tuple2('ohbar', '⦵'),
			_Utils_Tuple2('ohm', 'Ω'),
			_Utils_Tuple2('oint', '∮'),
			_Utils_Tuple2('olarr', '↺'),
			_Utils_Tuple2('olcir', '⦾'),
			_Utils_Tuple2('olcross', '⦻'),
			_Utils_Tuple2('oline', '‾'),
			_Utils_Tuple2('olt', '⧀'),
			_Utils_Tuple2('Omacr', 'Ō'),
			_Utils_Tuple2('omacr', 'ō'),
			_Utils_Tuple2('Omega', 'Ω'),
			_Utils_Tuple2('omega', 'ω'),
			_Utils_Tuple2('Omicron', 'Ο'),
			_Utils_Tuple2('omicron', 'ο'),
			_Utils_Tuple2('omid', '⦶'),
			_Utils_Tuple2('ominus', '⊖'),
			_Utils_Tuple2('Oopf', '\uD835\uDD46'),
			_Utils_Tuple2('oopf', '\uD835\uDD60'),
			_Utils_Tuple2('opar', '⦷'),
			_Utils_Tuple2('OpenCurlyDoubleQuote', '“'),
			_Utils_Tuple2('OpenCurlyQuote', '‘'),
			_Utils_Tuple2('operp', '⦹'),
			_Utils_Tuple2('oplus', '⊕'),
			_Utils_Tuple2('orarr', '↻'),
			_Utils_Tuple2('Or', '⩔'),
			_Utils_Tuple2('or', '∨'),
			_Utils_Tuple2('ord', '⩝'),
			_Utils_Tuple2('order', 'ℴ'),
			_Utils_Tuple2('orderof', 'ℴ'),
			_Utils_Tuple2('ordf', 'ª'),
			_Utils_Tuple2('ordm', 'º'),
			_Utils_Tuple2('origof', '⊶'),
			_Utils_Tuple2('oror', '⩖'),
			_Utils_Tuple2('orslope', '⩗'),
			_Utils_Tuple2('orv', '⩛'),
			_Utils_Tuple2('oS', 'Ⓢ'),
			_Utils_Tuple2('Oscr', '\uD835\uDCAA'),
			_Utils_Tuple2('oscr', 'ℴ'),
			_Utils_Tuple2('Oslash', 'Ø'),
			_Utils_Tuple2('oslash', 'ø'),
			_Utils_Tuple2('osol', '⊘'),
			_Utils_Tuple2('Otilde', 'Õ'),
			_Utils_Tuple2('otilde', 'õ'),
			_Utils_Tuple2('otimesas', '⨶'),
			_Utils_Tuple2('Otimes', '⨷'),
			_Utils_Tuple2('otimes', '⊗'),
			_Utils_Tuple2('Ouml', 'Ö'),
			_Utils_Tuple2('ouml', 'ö'),
			_Utils_Tuple2('ovbar', '⌽'),
			_Utils_Tuple2('OverBar', '‾'),
			_Utils_Tuple2('OverBrace', '⏞'),
			_Utils_Tuple2('OverBracket', '⎴'),
			_Utils_Tuple2('OverParenthesis', '⏜'),
			_Utils_Tuple2('para', '¶'),
			_Utils_Tuple2('parallel', '∥'),
			_Utils_Tuple2('par', '∥'),
			_Utils_Tuple2('parsim', '⫳'),
			_Utils_Tuple2('parsl', '⫽'),
			_Utils_Tuple2('part', '∂'),
			_Utils_Tuple2('PartialD', '∂'),
			_Utils_Tuple2('Pcy', 'П'),
			_Utils_Tuple2('pcy', 'п'),
			_Utils_Tuple2('percnt', '%'),
			_Utils_Tuple2('period', '.'),
			_Utils_Tuple2('permil', '‰'),
			_Utils_Tuple2('perp', '⊥'),
			_Utils_Tuple2('pertenk', '‱'),
			_Utils_Tuple2('Pfr', '\uD835\uDD13'),
			_Utils_Tuple2('pfr', '\uD835\uDD2D'),
			_Utils_Tuple2('Phi', 'Φ'),
			_Utils_Tuple2('phi', 'φ'),
			_Utils_Tuple2('phiv', 'ϕ'),
			_Utils_Tuple2('phmmat', 'ℳ'),
			_Utils_Tuple2('phone', '☎'),
			_Utils_Tuple2('Pi', 'Π'),
			_Utils_Tuple2('pi', 'π'),
			_Utils_Tuple2('pitchfork', '⋔'),
			_Utils_Tuple2('piv', 'ϖ'),
			_Utils_Tuple2('planck', 'ℏ'),
			_Utils_Tuple2('planckh', 'ℎ'),
			_Utils_Tuple2('plankv', 'ℏ'),
			_Utils_Tuple2('plusacir', '⨣'),
			_Utils_Tuple2('plusb', '⊞'),
			_Utils_Tuple2('pluscir', '⨢'),
			_Utils_Tuple2('plus', '+'),
			_Utils_Tuple2('plusdo', '∔'),
			_Utils_Tuple2('plusdu', '⨥'),
			_Utils_Tuple2('pluse', '⩲'),
			_Utils_Tuple2('PlusMinus', '±'),
			_Utils_Tuple2('plusmn', '±'),
			_Utils_Tuple2('plussim', '⨦'),
			_Utils_Tuple2('plustwo', '⨧'),
			_Utils_Tuple2('pm', '±'),
			_Utils_Tuple2('Poincareplane', 'ℌ'),
			_Utils_Tuple2('pointint', '⨕'),
			_Utils_Tuple2('popf', '\uD835\uDD61'),
			_Utils_Tuple2('Popf', 'ℙ'),
			_Utils_Tuple2('pound', '£'),
			_Utils_Tuple2('prap', '⪷'),
			_Utils_Tuple2('Pr', '⪻'),
			_Utils_Tuple2('pr', '≺'),
			_Utils_Tuple2('prcue', '≼'),
			_Utils_Tuple2('precapprox', '⪷'),
			_Utils_Tuple2('prec', '≺'),
			_Utils_Tuple2('preccurlyeq', '≼'),
			_Utils_Tuple2('Precedes', '≺'),
			_Utils_Tuple2('PrecedesEqual', '⪯'),
			_Utils_Tuple2('PrecedesSlantEqual', '≼'),
			_Utils_Tuple2('PrecedesTilde', '≾'),
			_Utils_Tuple2('preceq', '⪯'),
			_Utils_Tuple2('precnapprox', '⪹'),
			_Utils_Tuple2('precneqq', '⪵'),
			_Utils_Tuple2('precnsim', '⋨'),
			_Utils_Tuple2('pre', '⪯'),
			_Utils_Tuple2('prE', '⪳'),
			_Utils_Tuple2('precsim', '≾'),
			_Utils_Tuple2('prime', '′'),
			_Utils_Tuple2('Prime', '″'),
			_Utils_Tuple2('primes', 'ℙ'),
			_Utils_Tuple2('prnap', '⪹'),
			_Utils_Tuple2('prnE', '⪵'),
			_Utils_Tuple2('prnsim', '⋨'),
			_Utils_Tuple2('prod', '∏'),
			_Utils_Tuple2('Product', '∏'),
			_Utils_Tuple2('profalar', '⌮'),
			_Utils_Tuple2('profline', '⌒'),
			_Utils_Tuple2('profsurf', '⌓'),
			_Utils_Tuple2('prop', '∝'),
			_Utils_Tuple2('Proportional', '∝'),
			_Utils_Tuple2('Proportion', '∷'),
			_Utils_Tuple2('propto', '∝'),
			_Utils_Tuple2('prsim', '≾'),
			_Utils_Tuple2('prurel', '⊰'),
			_Utils_Tuple2('Pscr', '\uD835\uDCAB'),
			_Utils_Tuple2('pscr', '\uD835\uDCC5'),
			_Utils_Tuple2('Psi', 'Ψ'),
			_Utils_Tuple2('psi', 'ψ'),
			_Utils_Tuple2('puncsp', '\u2008'),
			_Utils_Tuple2('Qfr', '\uD835\uDD14'),
			_Utils_Tuple2('qfr', '\uD835\uDD2E'),
			_Utils_Tuple2('qint', '⨌'),
			_Utils_Tuple2('qopf', '\uD835\uDD62'),
			_Utils_Tuple2('Qopf', 'ℚ'),
			_Utils_Tuple2('qprime', '⁗'),
			_Utils_Tuple2('Qscr', '\uD835\uDCAC'),
			_Utils_Tuple2('qscr', '\uD835\uDCC6'),
			_Utils_Tuple2('quaternions', 'ℍ'),
			_Utils_Tuple2('quatint', '⨖'),
			_Utils_Tuple2('quest', '?'),
			_Utils_Tuple2('questeq', '≟'),
			_Utils_Tuple2('quot', '\"'),
			_Utils_Tuple2('QUOT', '\"'),
			_Utils_Tuple2('rAarr', '⇛'),
			_Utils_Tuple2('race', '∽̱'),
			_Utils_Tuple2('Racute', 'Ŕ'),
			_Utils_Tuple2('racute', 'ŕ'),
			_Utils_Tuple2('radic', '√'),
			_Utils_Tuple2('raemptyv', '⦳'),
			_Utils_Tuple2('rang', '⟩'),
			_Utils_Tuple2('Rang', '⟫'),
			_Utils_Tuple2('rangd', '⦒'),
			_Utils_Tuple2('range', '⦥'),
			_Utils_Tuple2('rangle', '⟩'),
			_Utils_Tuple2('raquo', '»'),
			_Utils_Tuple2('rarrap', '⥵'),
			_Utils_Tuple2('rarrb', '⇥'),
			_Utils_Tuple2('rarrbfs', '⤠'),
			_Utils_Tuple2('rarrc', '⤳'),
			_Utils_Tuple2('rarr', '→'),
			_Utils_Tuple2('Rarr', '↠'),
			_Utils_Tuple2('rArr', '⇒'),
			_Utils_Tuple2('rarrfs', '⤞'),
			_Utils_Tuple2('rarrhk', '↪'),
			_Utils_Tuple2('rarrlp', '↬'),
			_Utils_Tuple2('rarrpl', '⥅'),
			_Utils_Tuple2('rarrsim', '⥴'),
			_Utils_Tuple2('Rarrtl', '⤖'),
			_Utils_Tuple2('rarrtl', '↣'),
			_Utils_Tuple2('rarrw', '↝'),
			_Utils_Tuple2('ratail', '⤚'),
			_Utils_Tuple2('rAtail', '⤜'),
			_Utils_Tuple2('ratio', '∶'),
			_Utils_Tuple2('rationals', 'ℚ'),
			_Utils_Tuple2('rbarr', '⤍'),
			_Utils_Tuple2('rBarr', '⤏'),
			_Utils_Tuple2('RBarr', '⤐'),
			_Utils_Tuple2('rbbrk', '❳'),
			_Utils_Tuple2('rbrace', '}'),
			_Utils_Tuple2('rbrack', ']'),
			_Utils_Tuple2('rbrke', '⦌'),
			_Utils_Tuple2('rbrksld', '⦎'),
			_Utils_Tuple2('rbrkslu', '⦐'),
			_Utils_Tuple2('Rcaron', 'Ř'),
			_Utils_Tuple2('rcaron', 'ř'),
			_Utils_Tuple2('Rcedil', 'Ŗ'),
			_Utils_Tuple2('rcedil', 'ŗ'),
			_Utils_Tuple2('rceil', '⌉'),
			_Utils_Tuple2('rcub', '}'),
			_Utils_Tuple2('Rcy', 'Р'),
			_Utils_Tuple2('rcy', 'р'),
			_Utils_Tuple2('rdca', '⤷'),
			_Utils_Tuple2('rdldhar', '⥩'),
			_Utils_Tuple2('rdquo', '”'),
			_Utils_Tuple2('rdquor', '”'),
			_Utils_Tuple2('rdsh', '↳'),
			_Utils_Tuple2('real', 'ℜ'),
			_Utils_Tuple2('realine', 'ℛ'),
			_Utils_Tuple2('realpart', 'ℜ'),
			_Utils_Tuple2('reals', 'ℝ'),
			_Utils_Tuple2('Re', 'ℜ'),
			_Utils_Tuple2('rect', '▭'),
			_Utils_Tuple2('reg', '®'),
			_Utils_Tuple2('REG', '®'),
			_Utils_Tuple2('ReverseElement', '∋'),
			_Utils_Tuple2('ReverseEquilibrium', '⇋'),
			_Utils_Tuple2('ReverseUpEquilibrium', '⥯'),
			_Utils_Tuple2('rfisht', '⥽'),
			_Utils_Tuple2('rfloor', '⌋'),
			_Utils_Tuple2('rfr', '\uD835\uDD2F'),
			_Utils_Tuple2('Rfr', 'ℜ'),
			_Utils_Tuple2('rHar', '⥤'),
			_Utils_Tuple2('rhard', '⇁'),
			_Utils_Tuple2('rharu', '⇀'),
			_Utils_Tuple2('rharul', '⥬'),
			_Utils_Tuple2('Rho', 'Ρ'),
			_Utils_Tuple2('rho', 'ρ'),
			_Utils_Tuple2('rhov', 'ϱ'),
			_Utils_Tuple2('RightAngleBracket', '⟩'),
			_Utils_Tuple2('RightArrowBar', '⇥'),
			_Utils_Tuple2('rightarrow', '→'),
			_Utils_Tuple2('RightArrow', '→'),
			_Utils_Tuple2('Rightarrow', '⇒'),
			_Utils_Tuple2('RightArrowLeftArrow', '⇄'),
			_Utils_Tuple2('rightarrowtail', '↣'),
			_Utils_Tuple2('RightCeiling', '⌉'),
			_Utils_Tuple2('RightDoubleBracket', '⟧'),
			_Utils_Tuple2('RightDownTeeVector', '⥝'),
			_Utils_Tuple2('RightDownVectorBar', '⥕'),
			_Utils_Tuple2('RightDownVector', '⇂'),
			_Utils_Tuple2('RightFloor', '⌋'),
			_Utils_Tuple2('rightharpoondown', '⇁'),
			_Utils_Tuple2('rightharpoonup', '⇀'),
			_Utils_Tuple2('rightleftarrows', '⇄'),
			_Utils_Tuple2('rightleftharpoons', '⇌'),
			_Utils_Tuple2('rightrightarrows', '⇉'),
			_Utils_Tuple2('rightsquigarrow', '↝'),
			_Utils_Tuple2('RightTeeArrow', '↦'),
			_Utils_Tuple2('RightTee', '⊢'),
			_Utils_Tuple2('RightTeeVector', '⥛'),
			_Utils_Tuple2('rightthreetimes', '⋌'),
			_Utils_Tuple2('RightTriangleBar', '⧐'),
			_Utils_Tuple2('RightTriangle', '⊳'),
			_Utils_Tuple2('RightTriangleEqual', '⊵'),
			_Utils_Tuple2('RightUpDownVector', '⥏'),
			_Utils_Tuple2('RightUpTeeVector', '⥜'),
			_Utils_Tuple2('RightUpVectorBar', '⥔'),
			_Utils_Tuple2('RightUpVector', '↾'),
			_Utils_Tuple2('RightVectorBar', '⥓'),
			_Utils_Tuple2('RightVector', '⇀'),
			_Utils_Tuple2('ring', '˚'),
			_Utils_Tuple2('risingdotseq', '≓'),
			_Utils_Tuple2('rlarr', '⇄'),
			_Utils_Tuple2('rlhar', '⇌'),
			_Utils_Tuple2('rlm', '\u200F'),
			_Utils_Tuple2('rmoustache', '⎱'),
			_Utils_Tuple2('rmoust', '⎱'),
			_Utils_Tuple2('rnmid', '⫮'),
			_Utils_Tuple2('roang', '⟭'),
			_Utils_Tuple2('roarr', '⇾'),
			_Utils_Tuple2('robrk', '⟧'),
			_Utils_Tuple2('ropar', '⦆'),
			_Utils_Tuple2('ropf', '\uD835\uDD63'),
			_Utils_Tuple2('Ropf', 'ℝ'),
			_Utils_Tuple2('roplus', '⨮'),
			_Utils_Tuple2('rotimes', '⨵'),
			_Utils_Tuple2('RoundImplies', '⥰'),
			_Utils_Tuple2('rpar', ')'),
			_Utils_Tuple2('rpargt', '⦔'),
			_Utils_Tuple2('rppolint', '⨒'),
			_Utils_Tuple2('rrarr', '⇉'),
			_Utils_Tuple2('Rrightarrow', '⇛'),
			_Utils_Tuple2('rsaquo', '›'),
			_Utils_Tuple2('rscr', '\uD835\uDCC7'),
			_Utils_Tuple2('Rscr', 'ℛ'),
			_Utils_Tuple2('rsh', '↱'),
			_Utils_Tuple2('Rsh', '↱'),
			_Utils_Tuple2('rsqb', ']'),
			_Utils_Tuple2('rsquo', '’'),
			_Utils_Tuple2('rsquor', '’'),
			_Utils_Tuple2('rthree', '⋌'),
			_Utils_Tuple2('rtimes', '⋊'),
			_Utils_Tuple2('rtri', '▹'),
			_Utils_Tuple2('rtrie', '⊵'),
			_Utils_Tuple2('rtrif', '▸'),
			_Utils_Tuple2('rtriltri', '⧎'),
			_Utils_Tuple2('RuleDelayed', '⧴'),
			_Utils_Tuple2('ruluhar', '⥨'),
			_Utils_Tuple2('rx', '℞'),
			_Utils_Tuple2('Sacute', 'Ś'),
			_Utils_Tuple2('sacute', 'ś'),
			_Utils_Tuple2('sbquo', '‚'),
			_Utils_Tuple2('scap', '⪸'),
			_Utils_Tuple2('Scaron', 'Š'),
			_Utils_Tuple2('scaron', 'š'),
			_Utils_Tuple2('Sc', '⪼'),
			_Utils_Tuple2('sc', '≻'),
			_Utils_Tuple2('sccue', '≽'),
			_Utils_Tuple2('sce', '⪰'),
			_Utils_Tuple2('scE', '⪴'),
			_Utils_Tuple2('Scedil', 'Ş'),
			_Utils_Tuple2('scedil', 'ş'),
			_Utils_Tuple2('Scirc', 'Ŝ'),
			_Utils_Tuple2('scirc', 'ŝ'),
			_Utils_Tuple2('scnap', '⪺'),
			_Utils_Tuple2('scnE', '⪶'),
			_Utils_Tuple2('scnsim', '⋩'),
			_Utils_Tuple2('scpolint', '⨓'),
			_Utils_Tuple2('scsim', '≿'),
			_Utils_Tuple2('Scy', 'С'),
			_Utils_Tuple2('scy', 'с'),
			_Utils_Tuple2('sdotb', '⊡'),
			_Utils_Tuple2('sdot', '⋅'),
			_Utils_Tuple2('sdote', '⩦'),
			_Utils_Tuple2('searhk', '⤥'),
			_Utils_Tuple2('searr', '↘'),
			_Utils_Tuple2('seArr', '⇘'),
			_Utils_Tuple2('searrow', '↘'),
			_Utils_Tuple2('sect', '§'),
			_Utils_Tuple2('semi', ';'),
			_Utils_Tuple2('seswar', '⤩'),
			_Utils_Tuple2('setminus', '∖'),
			_Utils_Tuple2('setmn', '∖'),
			_Utils_Tuple2('sext', '✶'),
			_Utils_Tuple2('Sfr', '\uD835\uDD16'),
			_Utils_Tuple2('sfr', '\uD835\uDD30'),
			_Utils_Tuple2('sfrown', '⌢'),
			_Utils_Tuple2('sharp', '♯'),
			_Utils_Tuple2('SHCHcy', 'Щ'),
			_Utils_Tuple2('shchcy', 'щ'),
			_Utils_Tuple2('SHcy', 'Ш'),
			_Utils_Tuple2('shcy', 'ш'),
			_Utils_Tuple2('ShortDownArrow', '↓'),
			_Utils_Tuple2('ShortLeftArrow', '←'),
			_Utils_Tuple2('shortmid', '∣'),
			_Utils_Tuple2('shortparallel', '∥'),
			_Utils_Tuple2('ShortRightArrow', '→'),
			_Utils_Tuple2('ShortUpArrow', '↑'),
			_Utils_Tuple2('shy', '\u00AD'),
			_Utils_Tuple2('Sigma', 'Σ'),
			_Utils_Tuple2('sigma', 'σ'),
			_Utils_Tuple2('sigmaf', 'ς'),
			_Utils_Tuple2('sigmav', 'ς'),
			_Utils_Tuple2('sim', '∼'),
			_Utils_Tuple2('simdot', '⩪'),
			_Utils_Tuple2('sime', '≃'),
			_Utils_Tuple2('simeq', '≃'),
			_Utils_Tuple2('simg', '⪞'),
			_Utils_Tuple2('simgE', '⪠'),
			_Utils_Tuple2('siml', '⪝'),
			_Utils_Tuple2('simlE', '⪟'),
			_Utils_Tuple2('simne', '≆'),
			_Utils_Tuple2('simplus', '⨤'),
			_Utils_Tuple2('simrarr', '⥲'),
			_Utils_Tuple2('slarr', '←'),
			_Utils_Tuple2('SmallCircle', '∘'),
			_Utils_Tuple2('smallsetminus', '∖'),
			_Utils_Tuple2('smashp', '⨳'),
			_Utils_Tuple2('smeparsl', '⧤'),
			_Utils_Tuple2('smid', '∣'),
			_Utils_Tuple2('smile', '⌣'),
			_Utils_Tuple2('smt', '⪪'),
			_Utils_Tuple2('smte', '⪬'),
			_Utils_Tuple2('smtes', '⪬︀'),
			_Utils_Tuple2('SOFTcy', 'Ь'),
			_Utils_Tuple2('softcy', 'ь'),
			_Utils_Tuple2('solbar', '⌿'),
			_Utils_Tuple2('solb', '⧄'),
			_Utils_Tuple2('sol', '/'),
			_Utils_Tuple2('Sopf', '\uD835\uDD4A'),
			_Utils_Tuple2('sopf', '\uD835\uDD64'),
			_Utils_Tuple2('spades', '♠'),
			_Utils_Tuple2('spadesuit', '♠'),
			_Utils_Tuple2('spar', '∥'),
			_Utils_Tuple2('sqcap', '⊓'),
			_Utils_Tuple2('sqcaps', '⊓︀'),
			_Utils_Tuple2('sqcup', '⊔'),
			_Utils_Tuple2('sqcups', '⊔︀'),
			_Utils_Tuple2('Sqrt', '√'),
			_Utils_Tuple2('sqsub', '⊏'),
			_Utils_Tuple2('sqsube', '⊑'),
			_Utils_Tuple2('sqsubset', '⊏'),
			_Utils_Tuple2('sqsubseteq', '⊑'),
			_Utils_Tuple2('sqsup', '⊐'),
			_Utils_Tuple2('sqsupe', '⊒'),
			_Utils_Tuple2('sqsupset', '⊐'),
			_Utils_Tuple2('sqsupseteq', '⊒'),
			_Utils_Tuple2('square', '□'),
			_Utils_Tuple2('Square', '□'),
			_Utils_Tuple2('SquareIntersection', '⊓'),
			_Utils_Tuple2('SquareSubset', '⊏'),
			_Utils_Tuple2('SquareSubsetEqual', '⊑'),
			_Utils_Tuple2('SquareSuperset', '⊐'),
			_Utils_Tuple2('SquareSupersetEqual', '⊒'),
			_Utils_Tuple2('SquareUnion', '⊔'),
			_Utils_Tuple2('squarf', '▪'),
			_Utils_Tuple2('squ', '□'),
			_Utils_Tuple2('squf', '▪'),
			_Utils_Tuple2('srarr', '→'),
			_Utils_Tuple2('Sscr', '\uD835\uDCAE'),
			_Utils_Tuple2('sscr', '\uD835\uDCC8'),
			_Utils_Tuple2('ssetmn', '∖'),
			_Utils_Tuple2('ssmile', '⌣'),
			_Utils_Tuple2('sstarf', '⋆'),
			_Utils_Tuple2('Star', '⋆'),
			_Utils_Tuple2('star', '☆'),
			_Utils_Tuple2('starf', '★'),
			_Utils_Tuple2('straightepsilon', 'ϵ'),
			_Utils_Tuple2('straightphi', 'ϕ'),
			_Utils_Tuple2('strns', '¯'),
			_Utils_Tuple2('sub', '⊂'),
			_Utils_Tuple2('Sub', '⋐'),
			_Utils_Tuple2('subdot', '⪽'),
			_Utils_Tuple2('subE', '⫅'),
			_Utils_Tuple2('sube', '⊆'),
			_Utils_Tuple2('subedot', '⫃'),
			_Utils_Tuple2('submult', '⫁'),
			_Utils_Tuple2('subnE', '⫋'),
			_Utils_Tuple2('subne', '⊊'),
			_Utils_Tuple2('subplus', '⪿'),
			_Utils_Tuple2('subrarr', '⥹'),
			_Utils_Tuple2('subset', '⊂'),
			_Utils_Tuple2('Subset', '⋐'),
			_Utils_Tuple2('subseteq', '⊆'),
			_Utils_Tuple2('subseteqq', '⫅'),
			_Utils_Tuple2('SubsetEqual', '⊆'),
			_Utils_Tuple2('subsetneq', '⊊'),
			_Utils_Tuple2('subsetneqq', '⫋'),
			_Utils_Tuple2('subsim', '⫇'),
			_Utils_Tuple2('subsub', '⫕'),
			_Utils_Tuple2('subsup', '⫓'),
			_Utils_Tuple2('succapprox', '⪸'),
			_Utils_Tuple2('succ', '≻'),
			_Utils_Tuple2('succcurlyeq', '≽'),
			_Utils_Tuple2('Succeeds', '≻'),
			_Utils_Tuple2('SucceedsEqual', '⪰'),
			_Utils_Tuple2('SucceedsSlantEqual', '≽'),
			_Utils_Tuple2('SucceedsTilde', '≿'),
			_Utils_Tuple2('succeq', '⪰'),
			_Utils_Tuple2('succnapprox', '⪺'),
			_Utils_Tuple2('succneqq', '⪶'),
			_Utils_Tuple2('succnsim', '⋩'),
			_Utils_Tuple2('succsim', '≿'),
			_Utils_Tuple2('SuchThat', '∋'),
			_Utils_Tuple2('sum', '∑'),
			_Utils_Tuple2('Sum', '∑'),
			_Utils_Tuple2('sung', '♪'),
			_Utils_Tuple2('sup1', '¹'),
			_Utils_Tuple2('sup2', '²'),
			_Utils_Tuple2('sup3', '³'),
			_Utils_Tuple2('sup', '⊃'),
			_Utils_Tuple2('Sup', '⋑'),
			_Utils_Tuple2('supdot', '⪾'),
			_Utils_Tuple2('supdsub', '⫘'),
			_Utils_Tuple2('supE', '⫆'),
			_Utils_Tuple2('supe', '⊇'),
			_Utils_Tuple2('supedot', '⫄'),
			_Utils_Tuple2('Superset', '⊃'),
			_Utils_Tuple2('SupersetEqual', '⊇'),
			_Utils_Tuple2('suphsol', '⟉'),
			_Utils_Tuple2('suphsub', '⫗'),
			_Utils_Tuple2('suplarr', '⥻'),
			_Utils_Tuple2('supmult', '⫂'),
			_Utils_Tuple2('supnE', '⫌'),
			_Utils_Tuple2('supne', '⊋'),
			_Utils_Tuple2('supplus', '⫀'),
			_Utils_Tuple2('supset', '⊃'),
			_Utils_Tuple2('Supset', '⋑'),
			_Utils_Tuple2('supseteq', '⊇'),
			_Utils_Tuple2('supseteqq', '⫆'),
			_Utils_Tuple2('supsetneq', '⊋'),
			_Utils_Tuple2('supsetneqq', '⫌'),
			_Utils_Tuple2('supsim', '⫈'),
			_Utils_Tuple2('supsub', '⫔'),
			_Utils_Tuple2('supsup', '⫖'),
			_Utils_Tuple2('swarhk', '⤦'),
			_Utils_Tuple2('swarr', '↙'),
			_Utils_Tuple2('swArr', '⇙'),
			_Utils_Tuple2('swarrow', '↙'),
			_Utils_Tuple2('swnwar', '⤪'),
			_Utils_Tuple2('szlig', 'ß'),
			_Utils_Tuple2('Tab', '\t'),
			_Utils_Tuple2('target', '⌖'),
			_Utils_Tuple2('Tau', 'Τ'),
			_Utils_Tuple2('tau', 'τ'),
			_Utils_Tuple2('tbrk', '⎴'),
			_Utils_Tuple2('Tcaron', 'Ť'),
			_Utils_Tuple2('tcaron', 'ť'),
			_Utils_Tuple2('Tcedil', 'Ţ'),
			_Utils_Tuple2('tcedil', 'ţ'),
			_Utils_Tuple2('Tcy', 'Т'),
			_Utils_Tuple2('tcy', 'т'),
			_Utils_Tuple2('tdot', '⃛'),
			_Utils_Tuple2('telrec', '⌕'),
			_Utils_Tuple2('Tfr', '\uD835\uDD17'),
			_Utils_Tuple2('tfr', '\uD835\uDD31'),
			_Utils_Tuple2('there4', '∴'),
			_Utils_Tuple2('therefore', '∴'),
			_Utils_Tuple2('Therefore', '∴'),
			_Utils_Tuple2('Theta', 'Θ'),
			_Utils_Tuple2('theta', 'θ'),
			_Utils_Tuple2('thetasym', 'ϑ'),
			_Utils_Tuple2('thetav', 'ϑ'),
			_Utils_Tuple2('thickapprox', '≈'),
			_Utils_Tuple2('thicksim', '∼'),
			_Utils_Tuple2('ThickSpace', '\u205F\u200A'),
			_Utils_Tuple2('ThinSpace', '\u2009'),
			_Utils_Tuple2('thinsp', '\u2009'),
			_Utils_Tuple2('thkap', '≈'),
			_Utils_Tuple2('thksim', '∼'),
			_Utils_Tuple2('THORN', 'Þ'),
			_Utils_Tuple2('thorn', 'þ'),
			_Utils_Tuple2('tilde', '˜'),
			_Utils_Tuple2('Tilde', '∼'),
			_Utils_Tuple2('TildeEqual', '≃'),
			_Utils_Tuple2('TildeFullEqual', '≅'),
			_Utils_Tuple2('TildeTilde', '≈'),
			_Utils_Tuple2('timesbar', '⨱'),
			_Utils_Tuple2('timesb', '⊠'),
			_Utils_Tuple2('times', '×'),
			_Utils_Tuple2('timesd', '⨰'),
			_Utils_Tuple2('tint', '∭'),
			_Utils_Tuple2('toea', '⤨'),
			_Utils_Tuple2('topbot', '⌶'),
			_Utils_Tuple2('topcir', '⫱'),
			_Utils_Tuple2('top', '⊤'),
			_Utils_Tuple2('Topf', '\uD835\uDD4B'),
			_Utils_Tuple2('topf', '\uD835\uDD65'),
			_Utils_Tuple2('topfork', '⫚'),
			_Utils_Tuple2('tosa', '⤩'),
			_Utils_Tuple2('tprime', '‴'),
			_Utils_Tuple2('trade', '™'),
			_Utils_Tuple2('TRADE', '™'),
			_Utils_Tuple2('triangle', '▵'),
			_Utils_Tuple2('triangledown', '▿'),
			_Utils_Tuple2('triangleleft', '◃'),
			_Utils_Tuple2('trianglelefteq', '⊴'),
			_Utils_Tuple2('triangleq', '≜'),
			_Utils_Tuple2('triangleright', '▹'),
			_Utils_Tuple2('trianglerighteq', '⊵'),
			_Utils_Tuple2('tridot', '◬'),
			_Utils_Tuple2('trie', '≜'),
			_Utils_Tuple2('triminus', '⨺'),
			_Utils_Tuple2('TripleDot', '⃛'),
			_Utils_Tuple2('triplus', '⨹'),
			_Utils_Tuple2('trisb', '⧍'),
			_Utils_Tuple2('tritime', '⨻'),
			_Utils_Tuple2('trpezium', '⏢'),
			_Utils_Tuple2('Tscr', '\uD835\uDCAF'),
			_Utils_Tuple2('tscr', '\uD835\uDCC9'),
			_Utils_Tuple2('TScy', 'Ц'),
			_Utils_Tuple2('tscy', 'ц'),
			_Utils_Tuple2('TSHcy', 'Ћ'),
			_Utils_Tuple2('tshcy', 'ћ'),
			_Utils_Tuple2('Tstrok', 'Ŧ'),
			_Utils_Tuple2('tstrok', 'ŧ'),
			_Utils_Tuple2('twixt', '≬'),
			_Utils_Tuple2('twoheadleftarrow', '↞'),
			_Utils_Tuple2('twoheadrightarrow', '↠'),
			_Utils_Tuple2('Uacute', 'Ú'),
			_Utils_Tuple2('uacute', 'ú'),
			_Utils_Tuple2('uarr', '↑'),
			_Utils_Tuple2('Uarr', '↟'),
			_Utils_Tuple2('uArr', '⇑'),
			_Utils_Tuple2('Uarrocir', '⥉'),
			_Utils_Tuple2('Ubrcy', 'Ў'),
			_Utils_Tuple2('ubrcy', 'ў'),
			_Utils_Tuple2('Ubreve', 'Ŭ'),
			_Utils_Tuple2('ubreve', 'ŭ'),
			_Utils_Tuple2('Ucirc', 'Û'),
			_Utils_Tuple2('ucirc', 'û'),
			_Utils_Tuple2('Ucy', 'У'),
			_Utils_Tuple2('ucy', 'у'),
			_Utils_Tuple2('udarr', '⇅'),
			_Utils_Tuple2('Udblac', 'Ű'),
			_Utils_Tuple2('udblac', 'ű'),
			_Utils_Tuple2('udhar', '⥮'),
			_Utils_Tuple2('ufisht', '⥾'),
			_Utils_Tuple2('Ufr', '\uD835\uDD18'),
			_Utils_Tuple2('ufr', '\uD835\uDD32'),
			_Utils_Tuple2('Ugrave', 'Ù'),
			_Utils_Tuple2('ugrave', 'ù'),
			_Utils_Tuple2('uHar', '⥣'),
			_Utils_Tuple2('uharl', '↿'),
			_Utils_Tuple2('uharr', '↾'),
			_Utils_Tuple2('uhblk', '▀'),
			_Utils_Tuple2('ulcorn', '⌜'),
			_Utils_Tuple2('ulcorner', '⌜'),
			_Utils_Tuple2('ulcrop', '⌏'),
			_Utils_Tuple2('ultri', '◸'),
			_Utils_Tuple2('Umacr', 'Ū'),
			_Utils_Tuple2('umacr', 'ū'),
			_Utils_Tuple2('uml', '¨'),
			_Utils_Tuple2('UnderBar', '_'),
			_Utils_Tuple2('UnderBrace', '⏟'),
			_Utils_Tuple2('UnderBracket', '⎵'),
			_Utils_Tuple2('UnderParenthesis', '⏝'),
			_Utils_Tuple2('Union', '⋃'),
			_Utils_Tuple2('UnionPlus', '⊎'),
			_Utils_Tuple2('Uogon', 'Ų'),
			_Utils_Tuple2('uogon', 'ų'),
			_Utils_Tuple2('Uopf', '\uD835\uDD4C'),
			_Utils_Tuple2('uopf', '\uD835\uDD66'),
			_Utils_Tuple2('UpArrowBar', '⤒'),
			_Utils_Tuple2('uparrow', '↑'),
			_Utils_Tuple2('UpArrow', '↑'),
			_Utils_Tuple2('Uparrow', '⇑'),
			_Utils_Tuple2('UpArrowDownArrow', '⇅'),
			_Utils_Tuple2('updownarrow', '↕'),
			_Utils_Tuple2('UpDownArrow', '↕'),
			_Utils_Tuple2('Updownarrow', '⇕'),
			_Utils_Tuple2('UpEquilibrium', '⥮'),
			_Utils_Tuple2('upharpoonleft', '↿'),
			_Utils_Tuple2('upharpoonright', '↾'),
			_Utils_Tuple2('uplus', '⊎'),
			_Utils_Tuple2('UpperLeftArrow', '↖'),
			_Utils_Tuple2('UpperRightArrow', '↗'),
			_Utils_Tuple2('upsi', 'υ'),
			_Utils_Tuple2('Upsi', 'ϒ'),
			_Utils_Tuple2('upsih', 'ϒ'),
			_Utils_Tuple2('Upsilon', 'Υ'),
			_Utils_Tuple2('upsilon', 'υ'),
			_Utils_Tuple2('UpTeeArrow', '↥'),
			_Utils_Tuple2('UpTee', '⊥'),
			_Utils_Tuple2('upuparrows', '⇈'),
			_Utils_Tuple2('urcorn', '⌝'),
			_Utils_Tuple2('urcorner', '⌝'),
			_Utils_Tuple2('urcrop', '⌎'),
			_Utils_Tuple2('Uring', 'Ů'),
			_Utils_Tuple2('uring', 'ů'),
			_Utils_Tuple2('urtri', '◹'),
			_Utils_Tuple2('Uscr', '\uD835\uDCB0'),
			_Utils_Tuple2('uscr', '\uD835\uDCCA'),
			_Utils_Tuple2('utdot', '⋰'),
			_Utils_Tuple2('Utilde', 'Ũ'),
			_Utils_Tuple2('utilde', 'ũ'),
			_Utils_Tuple2('utri', '▵'),
			_Utils_Tuple2('utrif', '▴'),
			_Utils_Tuple2('uuarr', '⇈'),
			_Utils_Tuple2('Uuml', 'Ü'),
			_Utils_Tuple2('uuml', 'ü'),
			_Utils_Tuple2('uwangle', '⦧'),
			_Utils_Tuple2('vangrt', '⦜'),
			_Utils_Tuple2('varepsilon', 'ϵ'),
			_Utils_Tuple2('varkappa', 'ϰ'),
			_Utils_Tuple2('varnothing', '∅'),
			_Utils_Tuple2('varphi', 'ϕ'),
			_Utils_Tuple2('varpi', 'ϖ'),
			_Utils_Tuple2('varpropto', '∝'),
			_Utils_Tuple2('varr', '↕'),
			_Utils_Tuple2('vArr', '⇕'),
			_Utils_Tuple2('varrho', 'ϱ'),
			_Utils_Tuple2('varsigma', 'ς'),
			_Utils_Tuple2('varsubsetneq', '⊊︀'),
			_Utils_Tuple2('varsubsetneqq', '⫋︀'),
			_Utils_Tuple2('varsupsetneq', '⊋︀'),
			_Utils_Tuple2('varsupsetneqq', '⫌︀'),
			_Utils_Tuple2('vartheta', 'ϑ'),
			_Utils_Tuple2('vartriangleleft', '⊲'),
			_Utils_Tuple2('vartriangleright', '⊳'),
			_Utils_Tuple2('vBar', '⫨'),
			_Utils_Tuple2('Vbar', '⫫'),
			_Utils_Tuple2('vBarv', '⫩'),
			_Utils_Tuple2('Vcy', 'В'),
			_Utils_Tuple2('vcy', 'в'),
			_Utils_Tuple2('vdash', '⊢'),
			_Utils_Tuple2('vDash', '⊨'),
			_Utils_Tuple2('Vdash', '⊩'),
			_Utils_Tuple2('VDash', '⊫'),
			_Utils_Tuple2('Vdashl', '⫦'),
			_Utils_Tuple2('veebar', '⊻'),
			_Utils_Tuple2('vee', '∨'),
			_Utils_Tuple2('Vee', '⋁'),
			_Utils_Tuple2('veeeq', '≚'),
			_Utils_Tuple2('vellip', '⋮'),
			_Utils_Tuple2('verbar', '|'),
			_Utils_Tuple2('Verbar', '‖'),
			_Utils_Tuple2('vert', '|'),
			_Utils_Tuple2('Vert', '‖'),
			_Utils_Tuple2('VerticalBar', '∣'),
			_Utils_Tuple2('VerticalLine', '|'),
			_Utils_Tuple2('VerticalSeparator', '❘'),
			_Utils_Tuple2('VerticalTilde', '≀'),
			_Utils_Tuple2('VeryThinSpace', '\u200A'),
			_Utils_Tuple2('Vfr', '\uD835\uDD19'),
			_Utils_Tuple2('vfr', '\uD835\uDD33'),
			_Utils_Tuple2('vltri', '⊲'),
			_Utils_Tuple2('vnsub', '⊂⃒'),
			_Utils_Tuple2('vnsup', '⊃⃒'),
			_Utils_Tuple2('Vopf', '\uD835\uDD4D'),
			_Utils_Tuple2('vopf', '\uD835\uDD67'),
			_Utils_Tuple2('vprop', '∝'),
			_Utils_Tuple2('vrtri', '⊳'),
			_Utils_Tuple2('Vscr', '\uD835\uDCB1'),
			_Utils_Tuple2('vscr', '\uD835\uDCCB'),
			_Utils_Tuple2('vsubnE', '⫋︀'),
			_Utils_Tuple2('vsubne', '⊊︀'),
			_Utils_Tuple2('vsupnE', '⫌︀'),
			_Utils_Tuple2('vsupne', '⊋︀'),
			_Utils_Tuple2('Vvdash', '⊪'),
			_Utils_Tuple2('vzigzag', '⦚'),
			_Utils_Tuple2('Wcirc', 'Ŵ'),
			_Utils_Tuple2('wcirc', 'ŵ'),
			_Utils_Tuple2('wedbar', '⩟'),
			_Utils_Tuple2('wedge', '∧'),
			_Utils_Tuple2('Wedge', '⋀'),
			_Utils_Tuple2('wedgeq', '≙'),
			_Utils_Tuple2('weierp', '℘'),
			_Utils_Tuple2('Wfr', '\uD835\uDD1A'),
			_Utils_Tuple2('wfr', '\uD835\uDD34'),
			_Utils_Tuple2('Wopf', '\uD835\uDD4E'),
			_Utils_Tuple2('wopf', '\uD835\uDD68'),
			_Utils_Tuple2('wp', '℘'),
			_Utils_Tuple2('wr', '≀'),
			_Utils_Tuple2('wreath', '≀'),
			_Utils_Tuple2('Wscr', '\uD835\uDCB2'),
			_Utils_Tuple2('wscr', '\uD835\uDCCC'),
			_Utils_Tuple2('xcap', '⋂'),
			_Utils_Tuple2('xcirc', '◯'),
			_Utils_Tuple2('xcup', '⋃'),
			_Utils_Tuple2('xdtri', '▽'),
			_Utils_Tuple2('Xfr', '\uD835\uDD1B'),
			_Utils_Tuple2('xfr', '\uD835\uDD35'),
			_Utils_Tuple2('xharr', '⟷'),
			_Utils_Tuple2('xhArr', '⟺'),
			_Utils_Tuple2('Xi', 'Ξ'),
			_Utils_Tuple2('xi', 'ξ'),
			_Utils_Tuple2('xlarr', '⟵'),
			_Utils_Tuple2('xlArr', '⟸'),
			_Utils_Tuple2('xmap', '⟼'),
			_Utils_Tuple2('xnis', '⋻'),
			_Utils_Tuple2('xodot', '⨀'),
			_Utils_Tuple2('Xopf', '\uD835\uDD4F'),
			_Utils_Tuple2('xopf', '\uD835\uDD69'),
			_Utils_Tuple2('xoplus', '⨁'),
			_Utils_Tuple2('xotime', '⨂'),
			_Utils_Tuple2('xrarr', '⟶'),
			_Utils_Tuple2('xrArr', '⟹'),
			_Utils_Tuple2('Xscr', '\uD835\uDCB3'),
			_Utils_Tuple2('xscr', '\uD835\uDCCD'),
			_Utils_Tuple2('xsqcup', '⨆'),
			_Utils_Tuple2('xuplus', '⨄'),
			_Utils_Tuple2('xutri', '△'),
			_Utils_Tuple2('xvee', '⋁'),
			_Utils_Tuple2('xwedge', '⋀'),
			_Utils_Tuple2('Yacute', 'Ý'),
			_Utils_Tuple2('yacute', 'ý'),
			_Utils_Tuple2('YAcy', 'Я'),
			_Utils_Tuple2('yacy', 'я'),
			_Utils_Tuple2('Ycirc', 'Ŷ'),
			_Utils_Tuple2('ycirc', 'ŷ'),
			_Utils_Tuple2('Ycy', 'Ы'),
			_Utils_Tuple2('ycy', 'ы'),
			_Utils_Tuple2('yen', '¥'),
			_Utils_Tuple2('Yfr', '\uD835\uDD1C'),
			_Utils_Tuple2('yfr', '\uD835\uDD36'),
			_Utils_Tuple2('YIcy', 'Ї'),
			_Utils_Tuple2('yicy', 'ї'),
			_Utils_Tuple2('Yopf', '\uD835\uDD50'),
			_Utils_Tuple2('yopf', '\uD835\uDD6A'),
			_Utils_Tuple2('Yscr', '\uD835\uDCB4'),
			_Utils_Tuple2('yscr', '\uD835\uDCCE'),
			_Utils_Tuple2('YUcy', 'Ю'),
			_Utils_Tuple2('yucy', 'ю'),
			_Utils_Tuple2('yuml', 'ÿ'),
			_Utils_Tuple2('Yuml', 'Ÿ'),
			_Utils_Tuple2('Zacute', 'Ź'),
			_Utils_Tuple2('zacute', 'ź'),
			_Utils_Tuple2('Zcaron', 'Ž'),
			_Utils_Tuple2('zcaron', 'ž'),
			_Utils_Tuple2('Zcy', 'З'),
			_Utils_Tuple2('zcy', 'з'),
			_Utils_Tuple2('Zdot', 'Ż'),
			_Utils_Tuple2('zdot', 'ż'),
			_Utils_Tuple2('zeetrf', 'ℨ'),
			_Utils_Tuple2('ZeroWidthSpace', '\u200B'),
			_Utils_Tuple2('Zeta', 'Ζ'),
			_Utils_Tuple2('zeta', 'ζ'),
			_Utils_Tuple2('zfr', '\uD835\uDD37'),
			_Utils_Tuple2('Zfr', 'ℨ'),
			_Utils_Tuple2('ZHcy', 'Ж'),
			_Utils_Tuple2('zhcy', 'ж'),
			_Utils_Tuple2('zigrarr', '⇝'),
			_Utils_Tuple2('zopf', '\uD835\uDD6B'),
			_Utils_Tuple2('Zopf', 'ℤ'),
			_Utils_Tuple2('Zscr', '\uD835\uDCB5'),
			_Utils_Tuple2('zscr', '\uD835\uDCCF'),
			_Utils_Tuple2('zwj', '\u200D'),
			_Utils_Tuple2('zwnj', '\u200C')
		]));
var $hecrj$html_parser$Html$Parser$namedCharacterReference = A2(
	$elm$parser$Parser$map,
	function (reference) {
		return A2(
			$elm$core$Maybe$withDefault,
			'&' + (reference + ';'),
			A2($elm$core$Dict$get, reference, $hecrj$html_parser$Html$Parser$NamedCharacterReferences$dict));
	},
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($elm$core$Char$isAlpha)));
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Char$fromCode = _Char_fromCode;
var $elm$core$Basics$pow = _Basics_pow;
var $rtfeldman$elm_hex$Hex$fromStringHelp = F3(
	function (position, chars, accumulated) {
		fromStringHelp:
		while (true) {
			if (!chars.b) {
				return $elm$core$Result$Ok(accumulated);
			} else {
				var _char = chars.a;
				var rest = chars.b;
				switch (_char) {
					case '0':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated;
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '1':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + A2($elm$core$Basics$pow, 16, position);
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '2':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (2 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '3':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (3 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '4':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (4 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '5':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (5 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '6':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (6 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '7':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (7 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '8':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (8 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '9':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (9 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'a':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (10 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'b':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (11 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'c':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (12 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'd':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (13 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'e':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (14 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'f':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (15 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					default:
						var nonHex = _char;
						return $elm$core$Result$Err(
							$elm$core$String$fromChar(nonHex) + ' is not a valid hexadecimal character.');
				}
			}
		}
	});
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $rtfeldman$elm_hex$Hex$fromString = function (str) {
	if ($elm$core$String$isEmpty(str)) {
		return $elm$core$Result$Err('Empty strings are not valid hexadecimal strings.');
	} else {
		var result = function () {
			if (A2($elm$core$String$startsWith, '-', str)) {
				var list = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(
						$elm$core$String$toList(str)));
				return A2(
					$elm$core$Result$map,
					$elm$core$Basics$negate,
					A3(
						$rtfeldman$elm_hex$Hex$fromStringHelp,
						$elm$core$List$length(list) - 1,
						list,
						0));
			} else {
				return A3(
					$rtfeldman$elm_hex$Hex$fromStringHelp,
					$elm$core$String$length(str) - 1,
					$elm$core$String$toList(str),
					0);
			}
		}();
		var formatError = function (err) {
			return A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					['\"' + (str + '\"'), 'is not a valid hexadecimal string because', err]));
		};
		return A2($elm$core$Result$mapError, formatError, result);
	}
};
var $elm$core$Char$isHexDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return ((48 <= code) && (code <= 57)) || (((65 <= code) && (code <= 70)) || ((97 <= code) && (code <= 102)));
};
var $hecrj$html_parser$Html$Parser$hexadecimal = A2(
	$elm$parser$Parser$andThen,
	function (hex) {
		var _v0 = $rtfeldman$elm_hex$Hex$fromString(
			$elm$core$String$toLower(hex));
		if (!_v0.$) {
			var value = _v0.a;
			return $elm$parser$Parser$succeed(value);
		} else {
			var error = _v0.a;
			return $elm$parser$Parser$problem(error);
		}
	},
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($elm$core$Char$isHexDigit)));
var $elm$parser$Parser$ExpectingInt = {$: 1};
var $elm$parser$Parser$Advanced$consumeBase = _Parser_consumeBase;
var $elm$parser$Parser$Advanced$consumeBase16 = _Parser_consumeBase16;
var $elm$parser$Parser$Advanced$bumpOffset = F2(
	function (newOffset, s) {
		return {bZ: s.bZ + (newOffset - s.b), dp: s.dp, c: s.c, b: newOffset, cH: s.cH, a: s.a};
	});
var $elm$parser$Parser$Advanced$chompBase10 = _Parser_chompBase10;
var $elm$parser$Parser$Advanced$isAsciiCode = _Parser_isAsciiCode;
var $elm$parser$Parser$Advanced$consumeExp = F2(
	function (offset, src) {
		if (A3($elm$parser$Parser$Advanced$isAsciiCode, 101, offset, src) || A3($elm$parser$Parser$Advanced$isAsciiCode, 69, offset, src)) {
			var eOffset = offset + 1;
			var expOffset = (A3($elm$parser$Parser$Advanced$isAsciiCode, 43, eOffset, src) || A3($elm$parser$Parser$Advanced$isAsciiCode, 45, eOffset, src)) ? (eOffset + 1) : eOffset;
			var newOffset = A2($elm$parser$Parser$Advanced$chompBase10, expOffset, src);
			return _Utils_eq(expOffset, newOffset) ? (-newOffset) : newOffset;
		} else {
			return offset;
		}
	});
var $elm$parser$Parser$Advanced$consumeDotAndExp = F2(
	function (offset, src) {
		return A3($elm$parser$Parser$Advanced$isAsciiCode, 46, offset, src) ? A2(
			$elm$parser$Parser$Advanced$consumeExp,
			A2($elm$parser$Parser$Advanced$chompBase10, offset + 1, src),
			src) : A2($elm$parser$Parser$Advanced$consumeExp, offset, src);
	});
var $elm$parser$Parser$Advanced$finalizeInt = F5(
	function (invalid, handler, startOffset, _v0, s) {
		var endOffset = _v0.a;
		var n = _v0.b;
		if (handler.$ === 1) {
			var x = handler.a;
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				true,
				A2($elm$parser$Parser$Advanced$fromState, s, x));
		} else {
			var toValue = handler.a;
			return _Utils_eq(startOffset, endOffset) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				_Utils_cmp(s.b, startOffset) < 0,
				A2($elm$parser$Parser$Advanced$fromState, s, invalid)) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				toValue(n),
				A2($elm$parser$Parser$Advanced$bumpOffset, endOffset, s));
		}
	});
var $elm$core$String$toFloat = _String_toFloat;
var $elm$parser$Parser$Advanced$finalizeFloat = F6(
	function (invalid, expecting, intSettings, floatSettings, intPair, s) {
		var intOffset = intPair.a;
		var floatOffset = A2($elm$parser$Parser$Advanced$consumeDotAndExp, intOffset, s.a);
		if (floatOffset < 0) {
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				true,
				A4($elm$parser$Parser$Advanced$fromInfo, s.cH, s.bZ - (floatOffset + s.b), invalid, s.dp));
		} else {
			if (_Utils_eq(s.b, floatOffset)) {
				return A2(
					$elm$parser$Parser$Advanced$Bad,
					false,
					A2($elm$parser$Parser$Advanced$fromState, s, expecting));
			} else {
				if (_Utils_eq(intOffset, floatOffset)) {
					return A5($elm$parser$Parser$Advanced$finalizeInt, invalid, intSettings, s.b, intPair, s);
				} else {
					if (floatSettings.$ === 1) {
						var x = floatSettings.a;
						return A2(
							$elm$parser$Parser$Advanced$Bad,
							true,
							A2($elm$parser$Parser$Advanced$fromState, s, invalid));
					} else {
						var toValue = floatSettings.a;
						var _v1 = $elm$core$String$toFloat(
							A3($elm$core$String$slice, s.b, floatOffset, s.a));
						if (_v1.$ === 1) {
							return A2(
								$elm$parser$Parser$Advanced$Bad,
								true,
								A2($elm$parser$Parser$Advanced$fromState, s, invalid));
						} else {
							var n = _v1.a;
							return A3(
								$elm$parser$Parser$Advanced$Good,
								true,
								toValue(n),
								A2($elm$parser$Parser$Advanced$bumpOffset, floatOffset, s));
						}
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$number = function (c) {
	return function (s) {
		if (A3($elm$parser$Parser$Advanced$isAsciiCode, 48, s.b, s.a)) {
			var zeroOffset = s.b + 1;
			var baseOffset = zeroOffset + 1;
			return A3($elm$parser$Parser$Advanced$isAsciiCode, 120, zeroOffset, s.a) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.eu,
				c.cd,
				baseOffset,
				A2($elm$parser$Parser$Advanced$consumeBase16, baseOffset, s.a),
				s) : (A3($elm$parser$Parser$Advanced$isAsciiCode, 111, zeroOffset, s.a) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.eu,
				c.cr,
				baseOffset,
				A3($elm$parser$Parser$Advanced$consumeBase, 8, baseOffset, s.a),
				s) : (A3($elm$parser$Parser$Advanced$isAsciiCode, 98, zeroOffset, s.a) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.eu,
				c.bT,
				baseOffset,
				A3($elm$parser$Parser$Advanced$consumeBase, 2, baseOffset, s.a),
				s) : A6(
				$elm$parser$Parser$Advanced$finalizeFloat,
				c.eu,
				c.b6,
				c.ch,
				c.b9,
				_Utils_Tuple2(zeroOffset, 0),
				s)));
		} else {
			return A6(
				$elm$parser$Parser$Advanced$finalizeFloat,
				c.eu,
				c.b6,
				c.ch,
				c.b9,
				A3($elm$parser$Parser$Advanced$consumeBase, 10, s.b, s.a),
				s);
		}
	};
};
var $elm$parser$Parser$Advanced$int = F2(
	function (expecting, invalid) {
		return $elm$parser$Parser$Advanced$number(
			{
				bT: $elm$core$Result$Err(invalid),
				b6: expecting,
				b9: $elm$core$Result$Err(invalid),
				cd: $elm$core$Result$Err(invalid),
				ch: $elm$core$Result$Ok($elm$core$Basics$identity),
				eu: invalid,
				cr: $elm$core$Result$Err(invalid)
			});
	});
var $elm$parser$Parser$int = A2($elm$parser$Parser$Advanced$int, $elm$parser$Parser$ExpectingInt, $elm$parser$Parser$ExpectingInt);
var $hecrj$html_parser$Html$Parser$numericCharacterReference = function () {
	var codepoint = $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompIf(
						function (c) {
							return (c === 'x') || (c === 'X');
						})),
				$hecrj$html_parser$Html$Parser$hexadecimal),
				A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompWhile(
						$elm$core$Basics$eq('0'))),
				$elm$parser$Parser$int)
			]));
	return A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq('#'))),
		A2(
			$elm$parser$Parser$map,
			A2($elm$core$Basics$composeR, $elm$core$Char$fromCode, $elm$core$String$fromChar),
			codepoint));
}();
var $hecrj$html_parser$Html$Parser$characterReference = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('&'))),
	$elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$backtrackable($hecrj$html_parser$Html$Parser$namedCharacterReference),
				$hecrj$html_parser$Html$Parser$chompSemicolon),
				A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$backtrackable($hecrj$html_parser$Html$Parser$numericCharacterReference),
				$hecrj$html_parser$Html$Parser$chompSemicolon),
				$elm$parser$Parser$succeed('&')
			])));
var $hecrj$html_parser$Html$Parser$tagAttributeQuotedValue = function (quote) {
	var isQuotedValueChar = function (c) {
		return (!_Utils_eq(c, quote)) && (c !== '&');
	};
	return A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq(quote))),
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$map,
				$elm$core$String$join(''),
				$hecrj$html_parser$Html$Parser$many(
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$elm$parser$Parser$getChompedString(
								$hecrj$html_parser$Html$Parser$chompOneOrMore(isQuotedValueChar)),
								$hecrj$html_parser$Html$Parser$characterReference
							])))),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq(quote))));
};
var $hecrj$html_parser$Html$Parser$oneOrMore = F2(
	function (type_, parser_) {
		return A2(
			$elm$parser$Parser$loop,
			_List_Nil,
			function (list) {
				return $elm$parser$Parser$oneOf(
					_List_fromArray(
						[
							A2(
							$elm$parser$Parser$map,
							function (_new) {
								return $elm$parser$Parser$Loop(
									A2($elm$core$List$cons, _new, list));
							},
							parser_),
							$elm$core$List$isEmpty(list) ? $elm$parser$Parser$problem('expecting at least one ' + type_) : $elm$parser$Parser$succeed(
							$elm$parser$Parser$Done(
								$elm$core$List$reverse(list)))
						]));
			});
	});
var $hecrj$html_parser$Html$Parser$tagAttributeUnquotedValue = function () {
	var isUnquotedValueChar = function (c) {
		return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && ((c !== '\"') && ((c !== '\'') && ((c !== '=') && ((c !== '<') && ((c !== '>') && ((c !== '`') && (c !== '&')))))));
	};
	return A2(
		$elm$parser$Parser$map,
		$elm$core$String$join(''),
		A2(
			$hecrj$html_parser$Html$Parser$oneOrMore,
			'attribute value',
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$getChompedString(
						$hecrj$html_parser$Html$Parser$chompOneOrMore(isUnquotedValueChar)),
						$hecrj$html_parser$Html$Parser$characterReference
					]))));
}();
var $hecrj$html_parser$Html$Parser$tagAttributeValue = $elm$parser$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('='))),
				$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$hecrj$html_parser$Html$Parser$tagAttributeUnquotedValue,
						$hecrj$html_parser$Html$Parser$tagAttributeQuotedValue('\"'),
						$hecrj$html_parser$Html$Parser$tagAttributeQuotedValue('\'')
					]))),
			$elm$parser$Parser$succeed('')
		]));
var $hecrj$html_parser$Html$Parser$tagAttribute = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Tuple$pair),
		A2(
			$elm$parser$Parser$ignorer,
			$hecrj$html_parser$Html$Parser$tagAttributeName,
			$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter))),
	A2(
		$elm$parser$Parser$ignorer,
		$hecrj$html_parser$Html$Parser$tagAttributeValue,
		$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)));
var $hecrj$html_parser$Html$Parser$tagAttributes = $hecrj$html_parser$Html$Parser$many($hecrj$html_parser$Html$Parser$tagAttribute);
var $hecrj$html_parser$Html$Parser$tagName = A2(
	$elm$parser$Parser$map,
	$elm$core$String$toLower,
	$elm$parser$Parser$getChompedString(
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$chompIf($elm$core$Char$isAlphaNum),
			$elm$parser$Parser$chompWhile(
				function (c) {
					return $elm$core$Char$isAlphaNum(c) || (c === '-');
				}))));
var $hecrj$html_parser$Html$Parser$Text = function (a) {
	return {$: 0, a: a};
};
var $hecrj$html_parser$Html$Parser$text = A2(
	$elm$parser$Parser$map,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$String$join(''),
		$hecrj$html_parser$Html$Parser$Text),
	A2(
		$hecrj$html_parser$Html$Parser$oneOrMore,
		'text element',
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$getChompedString(
					$hecrj$html_parser$Html$Parser$chompOneOrMore(
						function (c) {
							return (c !== '<') && (c !== '&');
						})),
					$hecrj$html_parser$Html$Parser$characterReference
				]))));
function $hecrj$html_parser$Html$Parser$cyclic$node() {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				$hecrj$html_parser$Html$Parser$text,
				$hecrj$html_parser$Html$Parser$comment,
				$hecrj$html_parser$Html$Parser$cyclic$element()
			]));
}
function $hecrj$html_parser$Html$Parser$cyclic$element() {
	return A2(
		$elm$parser$Parser$andThen,
		function (_v0) {
			var name = _v0.a;
			var attributes = _v0.b;
			return $hecrj$html_parser$Html$Parser$isVoidElement(name) ? A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A3($hecrj$html_parser$Html$Parser$Element, name, attributes, _List_Nil)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$elm$parser$Parser$chompIf(
								$elm$core$Basics$eq('/')),
								$elm$parser$Parser$succeed(0)
							]))),
				$elm$parser$Parser$chompIf(
					$elm$core$Basics$eq('>'))) : A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A2($hecrj$html_parser$Html$Parser$Element, name, attributes)),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('>'))),
				A2(
					$elm$parser$Parser$ignorer,
					$hecrj$html_parser$Html$Parser$many(
						$elm$parser$Parser$backtrackable(
							$hecrj$html_parser$Html$Parser$cyclic$node())),
					$hecrj$html_parser$Html$Parser$closingTag(name)));
		},
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Tuple$pair),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('<'))),
				A2(
					$elm$parser$Parser$ignorer,
					$hecrj$html_parser$Html$Parser$tagName,
					$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter))),
			$hecrj$html_parser$Html$Parser$tagAttributes));
}
var $hecrj$html_parser$Html$Parser$node = $hecrj$html_parser$Html$Parser$cyclic$node();
$hecrj$html_parser$Html$Parser$cyclic$node = function () {
	return $hecrj$html_parser$Html$Parser$node;
};
var $hecrj$html_parser$Html$Parser$element = $hecrj$html_parser$Html$Parser$cyclic$element();
$hecrj$html_parser$Html$Parser$cyclic$element = function () {
	return $hecrj$html_parser$Html$Parser$element;
};
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {bZ: col, cx: problem, cH: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.cH, p.bZ, p.cx);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{bZ: 1, dp: _List_Nil, c: 1, b: 0, cH: 1, a: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $hecrj$html_parser$Html$Parser$run = function (str) {
	return $elm$core$String$isEmpty(str) ? $elm$core$Result$Ok(_List_Nil) : A2(
		$elm$parser$Parser$run,
		A2($hecrj$html_parser$Html$Parser$oneOrMore, 'node', $hecrj$html_parser$Html$Parser$node),
		str);
};
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $hecrj$html_parser$Html$Parser$Util$toAttribute = function (_v0) {
	var name = _v0.a;
	var value = _v0.b;
	return A2($elm$html$Html$Attributes$attribute, name, value);
};
var $hecrj$html_parser$Html$Parser$Util$toVirtualDom = function (nodes) {
	return A2($elm$core$List$map, $hecrj$html_parser$Html$Parser$Util$toVirtualDomEach, nodes);
};
var $hecrj$html_parser$Html$Parser$Util$toVirtualDomEach = function (node) {
	switch (node.$) {
		case 1:
			var name = node.a;
			var attrs = node.b;
			var children = node.c;
			return A3(
				$elm$html$Html$node,
				name,
				A2($elm$core$List$map, $hecrj$html_parser$Html$Parser$Util$toAttribute, attrs),
				$hecrj$html_parser$Html$Parser$Util$toVirtualDom(children));
		case 0:
			var s = node.a;
			return $elm$html$Html$text(s);
		default:
			return $elm$html$Html$text('');
	}
};
var $author$project$HtmlUtils$textHtml = function (str) {
	var _v0 = $hecrj$html_parser$Html$Parser$run(str);
	if (!_v0.$) {
		var nodes = _v0.a;
		return $hecrj$html_parser$Html$Parser$Util$toVirtualDom(nodes);
	} else {
		return _List_fromArray(
			[
				$elm$html$Html$text('Error parsing: ' + str)
			]);
	}
};
var $author$project$View$CourseStatusModal$modal = F2(
	function (_v0, model) {
		var translate = _v0.fX;
		var _v1 = model.H;
		if (_v1.$ === 3) {
			var data = _v1.a;
			var para = $author$project$HtmlUtils$textHtml(
				A3(
					$author$project$StringUtils$replaceNamedToken,
					'courseEndDate',
					data.fO,
					translate(
						function () {
							var _v2 = data.e1;
							switch (_v2) {
								case 0:
									return $author$project$Blurbs$blurbs.bo;
								case 1:
									return $author$project$Blurbs$blurbs.bm;
								default:
									return $author$project$Blurbs$blurbs.aX;
							}
						}())));
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('c-course-status')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h3,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('c-course-status__title')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								translate(
									$author$project$Data$progressStatusToBlurb(data.e1)))
							])),
						A2(
						$elm$html$Html$p,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('c-course-status__para')
							]),
						para),
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$author$project$Button$button,
								_List_fromArray(
									[
										$author$project$Button$class('c-course-status__dismiss'),
										$author$project$Button$onClick($author$project$Data$CloseModal),
										$author$project$Button$dark,
										$author$project$Button$qa('course_status_close')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										translate($author$project$Blurbs$blurbs.aT))
									]))
							]))
					]));
		} else {
			return $author$project$HtmlUtils$empty;
		}
	});
var $author$project$Scaffold$NoEffect = {$: 4};
var $author$project$EffectUtils$noEffect = $author$project$Scaffold$NoEffect;
var $author$project$Scaffold$OpenModal = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $author$project$EffectUtils$openModal = A2($author$project$Scaffold$OpenModal, false, false);
var $author$project$DateUtils$posixToFloat = A2($elm$core$Basics$composeR, $elm$time$Time$posixToMillis, $elm$core$Basics$toFloat);
var $author$project$Data$shouldGetNextGroupClass = F2(
	function (now, maybeClass) {
		if (maybeClass.$ === 1) {
			return false;
		} else {
			var _class = maybeClass.a;
			return _class.c4 ? false : (_Utils_cmp(
				_class.fU.dj,
				$author$project$DateUtils$posixToFloat(now)) < 1);
		}
	});
var $author$project$ServerTime$adjustCurrentTime = F2(
	function (now, delta) {
		var adjusted = $elm$time$Time$posixToMillis(now) + delta;
		return $elm$time$Time$millisToPosix(adjusted);
	});
var $author$project$ServerTime$update = F4(
	function (errorTitle, errorMsg, msg, model) {
		if (msg.$ === 1) {
			var now = msg.a;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						R: A2($author$project$ServerTime$adjustCurrentTime, now, model.aE)
					}),
				$elm$core$Platform$Cmd$none);
		} else {
			if (!msg.a.$) {
				var _v1 = msg.a.a;
				var timeDelta = _v1.a;
				var now = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							R: A2($author$project$ServerTime$adjustCurrentTime, now, timeDelta),
							aE: timeDelta
						}),
					$elm$core$Platform$Cmd$none);
			} else {
				var err = msg.a.a;
				return _Utils_Tuple2(
					model,
					$author$project$Ports$bubbleError(
						A4(
							$author$project$Messages$httpErrorMessage,
							errorTitle,
							$elm$core$Maybe$Just(errorMsg),
							'GetServerTime',
							err)));
			}
		}
	});
var $author$project$Messages$webDataErrorMessage = F4(
	function (displayTitle, displayMessage, context, data) {
		if (data.$ === 2) {
			var err = data.a;
			return $elm$core$Maybe$Just(
				A4($author$project$Messages$httpErrorMessage, displayTitle, displayMessage, context, err));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$LicensedFeature$withDefault = F2(
	function (def, feature) {
		if (feature.$ === 1) {
			return def;
		} else {
			var f = feature.a;
			return f;
		}
	});
var $krisajenkins$remotedata$RemoteData$withDefault = F2(
	function (_default, data) {
		if (data.$ === 3) {
			var x = data.a;
			return x;
		} else {
			return _default;
		}
	});
var $author$project$Control$update = F3(
	function (external, msg, model) {
		var translate = external.fX;
		var paths = external.e9;
		switch (msg.$) {
			case 0:
				var data = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{H: data, J: false}),
					function () {
						if (data.$ === 2) {
							return A2(
								$author$project$EffectUtils$fromCmd,
								$author$project$Data$BubbleErrorEffect,
								$author$project$Ports$maybeBubbleError(
									A4(
										$author$project$Messages$webDataErrorMessage,
										translate($author$project$Blurbs$blurbs.a1),
										$elm$core$Maybe$Just(
											translate($author$project$Blurbs$blurbs.a7)),
										'GetHomePageData',
										data)));
						} else {
							return $author$project$EffectUtils$noEffect;
						}
					}());
			case 8:
				var url = msg.a;
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$EffectUtils$fromCmd,
						$author$project$Data$ChangeUrlEffect(url),
						$author$project$Ports$changeUrl(url)));
			case 10:
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$EffectUtils$fromCmd,
						$author$project$Data$ChangeUrlEffect(paths.aQ),
						$author$project$Ports$changeUrl(paths.aQ)));
			case 1:
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$EffectUtils$fromCmd,
						$author$project$Data$ChangeUrlEffect(paths.b$),
						$author$project$Ports$changeUrl(paths.b$)));
			case 2:
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$EffectUtils$fromCmd,
						$author$project$Data$ChangeUrlEffect(paths.bw),
						$author$project$Ports$changeUrl(paths.bw)));
			case 14:
				var subMsg = msg.a;
				var hasGLCredit = $author$project$Student$hasGrantOrRemainingCredit(external.fF.ec.ef);
				var _v2 = A4(
					$author$project$ServerTime$update,
					translate($author$project$Blurbs$blurbs.a1),
					translate($author$project$Blurbs$blurbs.bC),
					subMsg,
					model.aB);
				var subModel = _v2.a;
				var subCmd = _v2.b;
				var _v3 = (model.J || (!hasGLCredit)) ? _Utils_Tuple2(model, $author$project$EffectUtils$noEffect) : A2(
					$krisajenkins$remotedata$RemoteData$withDefault,
					_Utils_Tuple2(model, $author$project$EffectUtils$noEffect),
					A2(
						$krisajenkins$remotedata$RemoteData$map,
						function (data) {
							return A2(
								$author$project$LicensedFeature$withDefault,
								_Utils_Tuple2(model, $author$project$EffectUtils$noEffect),
								A2(
									$author$project$LicensedFeature$map,
									function (getNext) {
										return getNext ? _Utils_Tuple2(
											_Utils_update(
												model,
												{J: true}),
											A2(
												$author$project$EffectUtils$fromCmd,
												$author$project$Data$NextGroupClassEffect,
												$author$project$Api$getNextGroupClass($author$project$Data$GroupClassReceived))) : _Utils_Tuple2(model, $author$project$EffectUtils$noEffect);
									},
									A2(
										$author$project$LicensedFeature$map,
										function (_v4) {
											var groupClass = _v4.ed;
											return A2(
												$author$project$Data$shouldGetNextGroupClass,
												$author$project$ServerTime$correctedTime(subModel),
												groupClass);
										},
										data.ee)));
						},
						model.H));
				var updatedModel = _v3.a;
				var nextGroupClassEff = _v3.b;
				return _Utils_Tuple2(
					_Utils_update(
						updatedModel,
						{aB: subModel}),
					$author$project$EffectUtils$batchEffect(
						_List_fromArray(
							[
								A2(
								$author$project$EffectUtils$fromCmd,
								$author$project$Data$ServerTimeEffect,
								A2($elm$core$Platform$Cmd$map, $author$project$Data$ServerTimeMsg, subCmd)),
								nextGroupClassEff
							])));
			case 9:
				if (!msg.a.$) {
					var groupClass = msg.a.a;
					var updatedData = A2(
						$krisajenkins$remotedata$RemoteData$map,
						function (data) {
							var groupClasses = A2(
								$author$project$LicensedFeature$map,
								function (groupClassState) {
									return _Utils_update(
										groupClassState,
										{ed: groupClass});
								},
								data.ee);
							return _Utils_update(
								data,
								{ee: groupClasses});
						},
						model.H);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{H: updatedData, J: false}),
						$author$project$EffectUtils$noEffect);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: false}),
						A2(
							$author$project$EffectUtils$fromCmd,
							$author$project$Data$BubbleErrorEffect,
							$author$project$Ports$bubbleError(
								A4(
									$author$project$Messages$httpErrorMessage,
									translate($author$project$Blurbs$blurbs.a1),
									$elm$core$Maybe$Just(
										translate($author$project$Blurbs$blurbs.bh)),
									'GetNextGroupClass',
									err))));
				}
			case 3:
				var url = msg.a;
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$EffectUtils$fromCmd,
						$author$project$Data$ChangeUrlEffect(url),
						$author$project$Ports$changeUrl(url)));
			case 4:
				var _class = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{P: true}),
					A2(
						$author$project$EffectUtils$fromCmd,
						$author$project$Data$GetClassroomUrlEffect,
						A3($author$project$Api$getPrivateClassClassroomUrl, external, _class, $author$project$Data$ClassroomUrlReceived)));
			case 5:
				var _class = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{P: true}),
					A2(
						$author$project$EffectUtils$fromCmd,
						$author$project$Data$GetTechCheckUrlEffect,
						A2($author$project$Api$getGroupClassTechCheckUrl, _class, $author$project$Data$TechCheckUrlReceived)));
			case 6:
				if (!msg.a.$) {
					var url = msg.a.a;
					var pathObject = {bU: false, b7: true, aG: url};
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$EffectUtils$fromCmd,
							$author$project$Data$ChangeUrlEffect(pathObject),
							$author$project$Ports$changeUrl(pathObject)));
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{P: false}),
						A2(
							$author$project$EffectUtils$fromCmd,
							$author$project$Data$BubbleErrorEffect,
							$author$project$Ports$bubbleError(
								A4(
									$author$project$Messages$httpErrorMessage,
									translate($author$project$Blurbs$blurbs.a1),
									$elm$core$Maybe$Just(
										translate($author$project$Blurbs$blurbs.a0)),
									'EnterClass',
									err))));
				}
			case 7:
				if (!msg.a.$) {
					var url = msg.a.a;
					var pathObject = {bU: false, b7: true, aG: url};
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$EffectUtils$fromCmd,
							$author$project$Data$ChangeUrlEffect(pathObject),
							$author$project$Ports$changeUrl(pathObject)));
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{P: false}),
						A2(
							$author$project$EffectUtils$fromCmd,
							$author$project$Data$BubbleErrorEffect,
							$author$project$Ports$bubbleError(
								A4(
									$author$project$Messages$httpErrorMessage,
									translate($author$project$Blurbs$blurbs.a1),
									$elm$core$Maybe$Just(
										translate($author$project$Blurbs$blurbs.a0)),
									'EnterClass',
									err))));
				}
			case 12:
				return _Utils_Tuple2(
					model,
					$author$project$EffectUtils$openModal($author$project$View$CourseStatusModal$modal));
			case 11:
				return _Utils_Tuple2(model, $author$project$EffectUtils$closeModal);
			default:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{H: $krisajenkins$remotedata$RemoteData$Loading}),
					A2(
						$author$project$EffectUtils$fromCmd,
						$author$project$Data$LoadDataEffect,
						$author$project$Api$loadData($author$project$Data$DataReceived)));
		}
	});
var $author$project$Data$BookClass = function (a) {
	return {$: 3, a: a};
};
var $author$project$Data$ChangeCourse = {$: 10};
var $author$project$Data$ContinueStudying = {$: 1};
var $author$project$View$Card$CtaBookUnitReview = function (a) {
	return {$: 2, a: a};
};
var $author$project$View$Card$CtaChangeCourse = {$: 4};
var $author$project$View$Card$CtaContinueStudying = {$: 0};
var $author$project$View$Card$CtaEnterUnitReview = function (a) {
	return {$: 1, a: a};
};
var $author$project$View$Card$CtaTakeLevelTest = function (a) {
	return {$: 3, a: a};
};
var $author$project$Data$EnterPrivateClass = function (a) {
	return {$: 4, a: a};
};
var $author$project$Data$TakeLevelTest = function (a) {
	return {$: 8, a: a};
};
var $elm$url$Url$Builder$QueryParameter = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $elm$url$Url$Builder$string = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$url$Url$percentEncode(value));
	});
var $author$project$Paths$addQueryString = F2(
	function (params, path) {
		var query = $elm$url$Url$Builder$toQuery(
			A2(
				$elm$core$List$map,
				function (_v0) {
					var k = _v0.a;
					var v = _v0.b;
					return A2($elm$url$Url$Builder$string, k, v);
				},
				params));
		return _Utils_update(
			path,
			{
				aG: _Utils_ap(path.aG, query)
			});
	});
var $author$project$ListUtils$appendMaybe = F2(
	function (x, xs) {
		if (!x.$) {
			var x_ = x.a;
			return _Utils_ap(
				xs,
				_List_fromArray(
					[x_]));
		} else {
			return xs;
		}
	});
var $author$project$Button$NullProp = {$: 1};
var $author$project$Button$boolProp = F2(
	function (p, b) {
		return b ? p : $author$project$Button$NullProp;
	});
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $author$project$Button$disabled = $author$project$Button$boolProp(
	$author$project$Button$ButtonProp(
		$elm$html$Html$Attributes$disabled(true)));
var $elm$core$String$fromFloat = _String_fromNumber;
var $author$project$Data$CP20 = 1;
var $author$project$Data$NoPrivateClass = {$: 3};
var $author$project$Data$PrivateClassImminent = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Data$PrivateClassPending = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Data$PrivateClassStarted = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $author$project$Data$PrivateClassType = 0;
var $author$project$Data$classImageUrl = F2(
	function (classType, mediaUrl) {
		return A2(
			$elm$core$Basics$composeR,
			$elm$core$Maybe$map(
				function (url) {
					if (A2($elm$core$String$startsWith, 'http', url)) {
						return url;
					} else {
						if (!classType) {
							return mediaUrl('_imgs/evc/pl/topic/238x238/' + url);
						} else {
							return mediaUrl('_imgs/evc/gl/topic/460x288/' + url);
						}
					}
				}),
			$elm$core$Maybe$withDefault('/assets/img/default-image.png'));
	});
var $author$project$DateUtils$secondMS = 1000;
var $author$project$DateUtils$differenceInSeconds = F2(
	function (a, b) {
		return (($elm$time$Time$posixToMillis(a) - $elm$time$Time$posixToMillis(b)) / $author$project$DateUtils$secondMS) | 0;
	});
var $elm$core$Basics$ge = _Utils_ge;
var $elm$core$Basics$modBy = _Basics_modBy;
var $author$project$DateUtils$formatTimeFromUntil = F2(
	function (from, until) {
		var totalSeconds = A2($author$project$DateUtils$differenceInSeconds, until, from);
		var remainingSeconds = A2($elm$core$Basics$modBy, 3600, totalSeconds);
		var s = $elm$core$String$fromInt(
			A2($elm$core$Basics$modBy, 60, remainingSeconds)) + 's';
		var m = $elm$core$String$fromInt((remainingSeconds / 60) | 0) + 'm ';
		var h = (totalSeconds / 3600) | 0;
		return (h > 0) ? ($elm$core$String$fromInt(h) + ('h ' + (m + s))) : ((remainingSeconds >= 60) ? _Utils_ap(m, s) : s);
	});
var $author$project$DateUtils$minuteMS = $author$project$DateUtils$secondMS * 60;
var $author$project$DateUtils$hourMS = $author$project$DateUtils$minuteMS * 60;
var $author$project$StringUtils$replaceAllNamedTokens = $elm$core$List$foldl(
	F2(
		function (_v0, rest) {
			var name = _v0.a;
			var val = _v0.b;
			return A3($author$project$StringUtils$replaceNamedToken, name, val, rest);
		}));
var $author$project$Data$startedBlurb = function (classType) {
	if (!classType) {
		return $author$project$Blurbs$blurbs.br;
	} else {
		return $author$project$Blurbs$blurbs.bM;
	}
};
var $author$project$Data$startsInBlurb = function (classType) {
	if (!classType) {
		return $author$project$Blurbs$blurbs.bs;
	} else {
		return $author$project$Blurbs$blurbs.bN;
	}
};
var $author$project$Data$startsOnBlurb = function (classType) {
	if (!classType) {
		return $author$project$Blurbs$blurbs.bt;
	} else {
		return $author$project$Blurbs$blurbs.bO;
	}
};
var $author$project$Data$getPrivateClassStatus = F4(
	function (_v0, nowPosix, classType, _class) {
		var translate = _v0.fX;
		var mediaUrl = _v0.cn;
		var now = $author$project$DateUtils$posixToFloat(nowPosix);
		var imageUrl = A3($author$project$Data$classImageUrl, 0, mediaUrl, _class.a8);
		return (_Utils_cmp(_class.fU.dN.fe, now) < 1) ? $author$project$Data$NoPrivateClass : ((_Utils_cmp(_class.fU.dj, now) < 1) ? $author$project$Data$NoPrivateClass : ((_Utils_cmp(_class.fU.fA.fe, now) < 1) ? A3(
			$author$project$Data$PrivateClassStarted,
			imageUrl,
			_class,
			A2(
				$author$project$StringUtils$replaceAllNamedTokens,
				translate(
					$author$project$Data$startedBlurb(classType)),
				_List_fromArray(
					[
						_Utils_Tuple2('privateClassTopicName', _class.bf),
						_Utils_Tuple2(
						'countdownToClassClosing',
						A2(
							$author$project$DateUtils$formatTimeFromUntil,
							nowPosix,
							$author$project$DateUtils$floatToPosix(_class.fU.dj))),
						_Utils_Tuple2(
						'countdownToClassClose',
						A2(
							$author$project$DateUtils$formatTimeFromUntil,
							nowPosix,
							$author$project$DateUtils$floatToPosix(_class.fU.dj)))
					]))) : ((_Utils_cmp(_class.fU.fA.fe - now, $author$project$DateUtils$hourMS) < 0) ? A2(
			$author$project$Data$PrivateClassImminent,
			imageUrl,
			A2(
				$author$project$StringUtils$replaceAllNamedTokens,
				translate(
					$author$project$Data$startsInBlurb(classType)),
				_List_fromArray(
					[
						_Utils_Tuple2('privateClassTopicName', _class.bf),
						_Utils_Tuple2(
						'classStartTimeCountdown',
						A2(
							$author$project$DateUtils$formatTimeFromUntil,
							nowPosix,
							$author$project$DateUtils$floatToPosix(_class.fU.fA.fe))),
						_Utils_Tuple2(
						'countdownToReviewStart',
						A2(
							$author$project$DateUtils$formatTimeFromUntil,
							nowPosix,
							$author$project$DateUtils$floatToPosix(_class.fU.fA.fe)))
					]))) : A2(
			$author$project$Data$PrivateClassPending,
			imageUrl,
			A2(
				$author$project$StringUtils$replaceAllNamedTokens,
				translate(
					$author$project$Data$startsOnBlurb(classType)),
				_List_fromArray(
					[
						_Utils_Tuple2('privateClassTopicName', _class.bf),
						_Utils_Tuple2('classStartTimeCountdown', _class.fU.fA.d1 + (' @ ' + _class.fU.fA.d2)),
						_Utils_Tuple2('dateAndHourOfUnitReview', _class.fU.fA.d1 + (' @ ' + _class.fU.fA.d2))
					]))))));
	});
var $author$project$Data$getUnitReviewStatus = F3(
	function (external, nowPosix, _class) {
		return A4($author$project$Data$getPrivateClassStatus, external, nowPosix, 1, _class);
	});
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $elm$html$Html$h4 = _VirtualDom_node('h4');
var $author$project$Button$loading = $author$project$Button$boolProp(
	$author$project$Button$buttonProp('-is-loading'));
var $author$project$View$Card$loadingMain = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('c-card-main is-loading'),
			$author$project$CssUtils$qa('card_main_loading')
		]),
	_List_Nil);
var $author$project$Button$primary = $author$project$Button$buttonProp('-primary');
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $author$project$Button$submit = $author$project$Button$ButtonProp(
	$elm$html$Html$Attributes$type_('submit'));
var $author$project$View$Card$cardMain = F2(
	function (external, model) {
		var _v0 = model.H;
		switch (_v0.$) {
			case 0:
				return $author$project$View$Card$loadingMain;
			case 1:
				return $author$project$View$Card$loadingMain;
			case 2:
				return $author$project$View$Card$loadingMain;
			default:
				var data = _v0.a;
				var now = $author$project$ServerTime$correctedTime(model.aB);
				var focusUnit = data.fs.d_;
				var imgBackground = A2(
					$elm$core$Maybe$map,
					function (imgUrl) {
						return A2($elm$html$Html$Attributes$style, 'background-image', 'url(' + (imgUrl + ')'));
					},
					focusUnit.c5);
				var ctaProps = _List_fromArray(
					[
						$author$project$Button$submit,
						$author$project$Button$primary,
						$author$project$Button$class('c-card-main__button'),
						$author$project$Button$qa('card_main_cta')
					]);
				var _v1 = external;
				var translate = _v1.fX;
				var stepStr = A2(
					$author$project$StringUtils$replaceAllNamedTokens,
					translate($author$project$Blurbs$blurbs.bE),
					_List_fromArray(
						[
							_Utils_Tuple2(
							'currentStep',
							$elm$core$String$fromFloat(focusUnit.fE)),
							_Utils_Tuple2(
							'totalNumberSteps',
							$elm$core$String$fromFloat(focusUnit.fW))
						]));
				var _v2 = function () {
					var _v5 = focusUnit.dc;
					switch (_v5.$) {
						case 1:
							var unitReview = _v5.a;
							var _v6 = A3($author$project$Data$getUnitReviewStatus, external, now, unitReview);
							switch (_v6.$) {
								case 3:
									return _Utils_Tuple2(
										_Utils_Tuple2(focusUnit.f0, stepStr),
										_Utils_Tuple2(
											$elm$core$Maybe$Just(focusUnit.fD),
											$author$project$View$Card$CtaContinueStudying));
								case 2:
									var cls = _v6.b;
									var txt = _v6.c;
									return _Utils_Tuple2(
										_Utils_Tuple2(focusUnit.f0, txt),
										_Utils_Tuple2(
											$elm$core$Maybe$Nothing,
											$author$project$View$Card$CtaEnterUnitReview(
												$elm$core$Maybe$Just(cls))));
								case 1:
									var txt = _v6.b;
									return _Utils_Tuple2(
										_Utils_Tuple2(focusUnit.f0, txt),
										_Utils_Tuple2(
											$elm$core$Maybe$Nothing,
											$author$project$View$Card$CtaEnterUnitReview($elm$core$Maybe$Nothing)));
								default:
									var txt = _v6.b;
									return _Utils_Tuple2(
										_Utils_Tuple2(focusUnit.f0, txt),
										_Utils_Tuple2($elm$core$Maybe$Nothing, $author$project$View$Card$CtaContinueStudying));
							}
						case 0:
							return _Utils_Tuple2(
								_Utils_Tuple2(focusUnit.f0, stepStr),
								_Utils_Tuple2(
									$elm$core$Maybe$Just(focusUnit.fD),
									$author$project$View$Card$CtaContinueStudying));
						case 2:
							var unitTemplateId = _v5.a.f$;
							return _Utils_Tuple2(
								_Utils_Tuple2(focusUnit.f0, stepStr),
								_Utils_Tuple2(
									$elm$core$Maybe$Just(
										translate($author$project$Blurbs$blurbs.f_)),
									$author$project$View$Card$CtaBookUnitReview(unitTemplateId)));
						case 3:
							var levelTemplateId = _v5.a.eG;
							return _Utils_Tuple2(
								_Utils_Tuple2(
									translate($author$project$Blurbs$blurbs.ba),
									translate($author$project$Blurbs$blurbs.bH)),
								_Utils_Tuple2(
									$elm$core$Maybe$Nothing,
									$author$project$View$Card$CtaTakeLevelTest(levelTemplateId)));
						default:
							return _Utils_Tuple2(
								_Utils_Tuple2(
									focusUnit.f0,
									translate($author$project$Blurbs$blurbs.aU)),
								_Utils_Tuple2($elm$core$Maybe$Nothing, $author$project$View$Card$CtaChangeCourse));
					}
				}();
				var _v3 = _v2.a;
				var title = _v3.a;
				var copyOne = _v3.b;
				var _v4 = _v2.b;
				var copyTwo = _v4.a;
				var cta = _v4.b;
				var copyTwoMarkup = A2(
					$elm$core$Maybe$map,
					function (txt) {
						return A2(
							$elm$html$Html$h4,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-card-main__copy-two'),
									$author$project$CssUtils$qa('card_main_copy_two')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(txt)
								]));
					},
					copyTwo);
				return A2(
					$elm$html$Html$div,
					A2(
						$author$project$ListUtils$appendMaybe,
						imgBackground,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('c-card-main'),
								$author$project$CssUtils$qa('card_main')
							])),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-card-main__title-w')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h2,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('c-card-main__pretitle'),
											$author$project$CssUtils$qa('card_main_pretitle')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(focusUnit.fZ)
										])),
									A2(
									$elm$html$Html$h1,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('c-card-main__title'),
											$author$project$CssUtils$qa('card_main_title')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(title)
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-card-main__content')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('c-card-main__copy-w')
										]),
									A2(
										$author$project$ListUtils$appendMaybe,
										copyTwoMarkup,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$h4,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('c-card-main__copy-one'),
														$author$project$CssUtils$qa('card_main_copy_one')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(copyOne)
													]))
											]))),
									function () {
									switch (cta.$) {
										case 4:
											return A2(
												$author$project$Button$button,
												A2(
													$elm$core$List$cons,
													$author$project$Button$onClick($author$project$Data$ChangeCourse),
													ctaProps),
												_List_fromArray(
													[
														$elm$html$Html$text(
														translate($author$project$Blurbs$blurbs.aQ))
													]));
										case 3:
											var templateId = cta.a;
											return A2(
												$author$project$Button$button,
												A2(
													$elm$core$List$cons,
													$author$project$Button$onClick(
														$author$project$Data$TakeLevelTest(
															A2(
																$author$project$Paths$addQueryString,
																_List_fromArray(
																	[
																		_Utils_Tuple2('testid', templateId)
																	]),
																external.e9.ba))),
													ctaProps),
												_List_fromArray(
													[
														$elm$html$Html$text(
														translate($author$project$Blurbs$blurbs.bI))
													]));
										case 2:
											var templateId = cta.a;
											return A2(
												$author$project$Button$button,
												A2(
													$elm$core$List$cons,
													$author$project$Button$onClick(
														$author$project$Data$BookClass(
															A2(
																$author$project$Paths$addQueryString,
																_List_fromArray(
																	[
																		_Utils_Tuple2('templateUnitId', templateId)
																	]),
																external.e9.ff))),
													ctaProps),
												_List_fromArray(
													[
														$elm$html$Html$text(
														translate($author$project$Blurbs$blurbs.aP))
													]));
										case 0:
											return A2(
												$author$project$Button$button,
												A2(
													$elm$core$List$cons,
													$author$project$Button$onClick($author$project$Data$ContinueStudying),
													ctaProps),
												_List_fromArray(
													[
														$elm$html$Html$text(
														translate($author$project$Blurbs$blurbs.aW))
													]));
										default:
											if (cta.a.$ === 1) {
												var _v8 = cta.a;
												return A2(
													$author$project$Button$button,
													A2(
														$elm$core$List$cons,
														$author$project$Button$disabled(true),
														ctaProps),
													_List_fromArray(
														[
															$elm$html$Html$text(
															translate($author$project$Blurbs$blurbs.a$))
														]));
											} else {
												var cls = cta.a.a;
												return A2(
													$author$project$Button$button,
													A2(
														$elm$core$List$cons,
														$author$project$Button$disabled(model.P),
														A2(
															$elm$core$List$cons,
															$author$project$Button$loading(model.P),
															A2(
																$elm$core$List$cons,
																$author$project$Button$onClick(
																	$author$project$Data$EnterPrivateClass(cls)),
																ctaProps))),
													_List_fromArray(
														[
															$elm$html$Html$text(
															translate($author$project$Blurbs$blurbs.a$))
														]));
											}
									}
								}()
								]))
						]));
		}
	});
var $author$project$View$Card$loadingSecondary = function (showImage) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('c-card-secondary is-loading'),
				$author$project$CssUtils$qa('card_secondary_loading'),
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('-with-image', showImage)
					]))
			]),
		_List_Nil);
};
var $author$project$View$Card$cardSecondary = F2(
	function (showImage, webContent) {
		if (webContent.$ === 3) {
			var _v1 = webContent.a;
			var imgUrl = _v1.a;
			var content = _v1.b;
			var _v2 = A2(
				$elm$core$Maybe$withDefault,
				_Utils_Tuple2('-without-image', $author$project$HtmlUtils$empty),
				A2(
					$elm$core$Maybe$map,
					function (url) {
						return _Utils_Tuple2(
							'-with-image',
							A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('c-card-secondary__img'),
										A2($elm$html$Html$Attributes$style, 'background-image', 'url(' + (url + ')'))
									]),
								_List_Nil));
					},
					imgUrl));
			var type_ = _v2.a;
			var cardImg = _v2.b;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('c-card-secondary ' + type_),
						$author$project$CssUtils$qa('card_secondary')
					]),
				_List_fromArray(
					[
						cardImg,
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('c-card-secondary__content')
							]),
						content)
					]));
		} else {
			return $author$project$View$Card$loadingSecondary(showImage);
		}
	});
var $author$project$Data$EnterGroupClass = function (a) {
	return {$: 5, a: a};
};
var $author$project$Data$GroupClassAttended = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Data$GroupClassImminent = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Data$GroupClassStarted = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$Data$GroupClassType = 1;
var $author$project$Data$NoGroupClass = function (a) {
	return {$: 3, a: a};
};
var $author$project$Data$getGroupClassStatus = F5(
	function (_v0, targetMet, hasCredit, nowPosix, maybeClass) {
		var translate = _v0.fX;
		var mediaUrl = _v0.cn;
		if (maybeClass.$ === 1) {
			return $author$project$Data$NoGroupClass(
				translate($author$project$Blurbs$blurbs.bi));
		} else {
			var _class = maybeClass.a;
			var now = $author$project$DateUtils$posixToFloat(nowPosix);
			var imageUrl = A3($author$project$Data$classImageUrl, 1, mediaUrl, _class.a8);
			return (!hasCredit) ? (targetMet ? $author$project$Data$NoGroupClass(
				translate($author$project$Blurbs$blurbs.a5)) : $author$project$Data$NoGroupClass(
				translate($author$project$Blurbs$blurbs.bj))) : (_class.c4 ? A2(
				$author$project$Data$GroupClassAttended,
				imageUrl,
				A3(
					$author$project$StringUtils$replaceNamedToken,
					'groupClassTopic',
					_class.bf,
					translate($author$project$Blurbs$blurbs.a2))) : ((_Utils_cmp(_class.fU.dj, now) < 1) ? $author$project$Data$NoGroupClass(
				translate($author$project$Blurbs$blurbs.bi)) : ((_Utils_cmp(_class.fU.fA.fe, now) < 1) ? A3(
				$author$project$Data$GroupClassStarted,
				imageUrl,
				_class,
				A2(
					$author$project$StringUtils$replaceAllNamedTokens,
					translate($author$project$Blurbs$blurbs.a3),
					_List_fromArray(
						[
							_Utils_Tuple2('groupClassTopic', _class.bf),
							_Utils_Tuple2(
							'countdownToClassClosing',
							A2(
								$author$project$DateUtils$formatTimeFromUntil,
								nowPosix,
								$author$project$DateUtils$floatToPosix(_class.fU.dj)))
						]))) : A2(
				$author$project$Data$GroupClassImminent,
				imageUrl,
				A2(
					$author$project$StringUtils$replaceAllNamedTokens,
					translate($author$project$Blurbs$blurbs.a4),
					_List_fromArray(
						[
							_Utils_Tuple2('groupClassTopic', _class.bf),
							_Utils_Tuple2(
							'classStartTimeCountdown',
							A2(
								$author$project$DateUtils$formatTimeFromUntil,
								nowPosix,
								$author$project$DateUtils$floatToPosix(_class.fU.fA.fe)))
						]))))));
		}
	});
var $elm$svg$Svg$defs = $elm$svg$Svg$trustedNode('defs');
var $elm$svg$Svg$Attributes$fill = _VirtualDom_attribute('fill');
var $elm$svg$Svg$Attributes$fillRule = _VirtualDom_attribute('fill-rule');
var $elm$svg$Svg$Attributes$id = _VirtualDom_attribute('id');
var $elm$svg$Svg$linearGradient = $elm$svg$Svg$trustedNode('linearGradient');
var $elm$svg$Svg$Attributes$offset = _VirtualDom_attribute('offset');
var $elm$svg$Svg$stop = $elm$svg$Svg$trustedNode('stop');
var $elm$svg$Svg$Attributes$stopColor = _VirtualDom_attribute('stop-color');
var $elm$svg$Svg$Attributes$transform = _VirtualDom_attribute('transform');
var $elm$svg$Svg$Attributes$x1 = _VirtualDom_attribute('x1');
var $elm$svg$Svg$Attributes$x2 = _VirtualDom_attribute('x2');
var $elm$svg$Svg$Attributes$y1 = _VirtualDom_attribute('y1');
var $elm$svg$Svg$Attributes$y2 = _VirtualDom_attribute('y2');
var $author$project$Icons$GoalIconAchieved$icon = A2(
	$elm$svg$Svg$svg,
	_List_fromArray(
		[
			$elm$svg$Svg$Attributes$viewBox('0 0 12 16'),
			$author$project$CssUtils$qa('goal_icon_achieved')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$defs,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$linearGradient,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$x1('34.3%'),
							$elm$svg$Svg$Attributes$y1('0%'),
							$elm$svg$Svg$Attributes$x2('63.8%'),
							$elm$svg$Svg$Attributes$y2('100%'),
							$elm$svg$Svg$Attributes$id('a')
						]),
					_List_fromArray(
						[
							A2(
							$elm$svg$Svg$stop,
							_List_fromArray(
								[
									$elm$svg$Svg$Attributes$stopColor('#009EE8'),
									$elm$svg$Svg$Attributes$offset('0%')
								]),
							_List_Nil),
							A2(
							$elm$svg$Svg$stop,
							_List_fromArray(
								[
									$elm$svg$Svg$Attributes$stopColor('#06B4EA'),
									$elm$svg$Svg$Attributes$offset('100%')
								]),
							_List_Nil)
						]))
				])),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M1153.9 278.4l-1.1-1.2.1-1.7c0-.2-.1-.4-.3-.5l-1.6-.3-.9-1.5a.5.5 0 00-.6-.2l-1.5.7-1.5-.7a.5.5 0 00-.6.2l-.9 1.5-1.6.3c-.2 0-.4.3-.4.5l.2 1.7-1 1.2c-.2.2-.2.5 0 .7l1 1.2-.2 1.6c0 .3.2.5.4.6l1 .2v5.5c0 .2 0 .4.2.5h.5l2.9-1.8 2.7 1.8a.5.5 0 00.8-.5v-5.5l1-.2c.3-.1.5-.3.5-.6l-.2-1.6 1-1.2c.2-.2.2-.5 0-.7z'),
					$elm$svg$Svg$Attributes$transform('translate(-1142 -273)'),
					$elm$svg$Svg$Attributes$fill('url(#a)'),
					$elm$svg$Svg$Attributes$fillRule('evenodd')
				]),
			_List_Nil)
		]));
var $elm$svg$Svg$g = $elm$svg$Svg$trustedNode('g');
var $elm$svg$Svg$Attributes$height = _VirtualDom_attribute('height');
var $elm$svg$Svg$rect = $elm$svg$Svg$trustedNode('rect');
var $elm$svg$Svg$Attributes$rx = _VirtualDom_attribute('rx');
var $elm$svg$Svg$Attributes$stroke = _VirtualDom_attribute('stroke');
var $elm$svg$Svg$Attributes$width = _VirtualDom_attribute('width');
var $elm$svg$Svg$Attributes$x = _VirtualDom_attribute('x');
var $elm$svg$Svg$Attributes$y = _VirtualDom_attribute('y');
var $author$project$Icons$GoalIconNoTarget$icon = A2(
	$elm$svg$Svg$svg,
	_List_fromArray(
		[
			$elm$svg$Svg$Attributes$viewBox('0 0 21 20'),
			$author$project$CssUtils$qa('goal_icon_no_target')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$transform('translate(1.7)'),
					$elm$svg$Svg$Attributes$fill('#191919'),
					$elm$svg$Svg$Attributes$fillRule('evenodd')
				]),
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$path,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('M15.2 7.4L14 6.2l.1-1.6c0-.3-.1-.5-.3-.6l-1.6-.3-.9-1.4a.5.5 0 00-.6-.2l-1.5.6-1.5-.6a.5.5 0 00-.6.2l-.9 1.4-1.6.3c-.2 0-.4.3-.4.6l.2 1.6-1 1.2c-.2.2-.2.5 0 .7l1 1.2-.2 1.6c0 .3.2.5.4.6l1 .2v5.6c0 .1 0 .3.2.4h.5l2.9-1.8 2.7 1.8a.5.5 0 00.8-.4v-5.6l1-.2c.3 0 .5-.3.4-.6l-.1-1.6 1-1.2c.2-.2.2-.5 0-.7zm-2.4 1.1l-.3.3v.4l.2 1-1 .2-.4.1-.2.4-.5.8-1-.4-.3-.1-.4.1-.9.4-.5-.8-.2-.4-.4-.1-1-.2.1-1v-.4l-.2-.3-.7-.8.7-.7.2-.3V5.3l.9-.2.4-.1.2-.4.5-.8 1 .4.3.1.4-.1.9-.4.5.8.2.4h.4l1 .3-.1 1v.4l.2.3.7.8-.7.7zm-3 6l-.5-.4-.5.4-1.6 1v-2.3c.1.2.4.3.6.2l1.5-.6 1.5.6a.5.5 0 00.5 0v2l-1.5-.9z')
						]),
					_List_Nil),
					A2(
					$elm$svg$Svg$rect,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$stroke('#FFF'),
							$elm$svg$Svg$Attributes$transform('rotate(-45 9 9.8)'),
							$elm$svg$Svg$Attributes$x('-2'),
							$elm$svg$Svg$Attributes$y('8.3'),
							$elm$svg$Svg$Attributes$width('22'),
							$elm$svg$Svg$Attributes$height('3'),
							$elm$svg$Svg$Attributes$rx('1.5')
						]),
					_List_Nil)
				]))
		]));
var $author$project$Button$secondary = $author$project$Button$buttonProp('-secondary');
var $author$project$View$GroupClass$content = F3(
	function (external, showImg, model) {
		return A2(
			$krisajenkins$remotedata$RemoteData$map,
			function (data) {
				var _v0 = _Utils_Tuple2(data.ee, model.J);
				if (_v0.a.$ === 1) {
					var _v1 = _v0.a;
					return _Utils_Tuple2(
						$elm$core$Maybe$Nothing,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h3,
								_List_fromArray(
									[
										$author$project$CssUtils$qa('group_class_unlicensed')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Unlicensed')
									]))
							]));
				} else {
					if (_v0.b) {
						return _Utils_Tuple2(
							$elm$core$Maybe$Nothing,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('c-card-secondary__spinner')
										]),
									_List_Nil)
								]));
					} else {
						var progress = _v0.a.a.bv;
						var groupClass = _v0.a.a.ed;
						var now = $author$project$ServerTime$correctedTime(model.aB);
						var _v2 = external;
						var translate = _v2.fX;
						var student = _v2.fF;
						var _v3 = student;
						var grantsAndCredits = _v3.ec;
						var studyTargets = _v3.fH;
						var hasCredit = $author$project$Student$hasGrantOrRemainingCredit(grantsAndCredits.ef);
						var isCatalyst = $author$project$Student$hasGrantOrRemainingCredit(student.ec.de);
						var targetMet = ((studyTargets.eg > 0) && (_Utils_cmp(progress.dn, progress.fN) > -1)) && (!isCatalyst);
						var tooltip = F2(
							function (icon, blurb) {
								return A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('c-card-secondary__tooltip-w'),
											$author$project$CssUtils$qa('group_class_tooltip')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('c-card-secondary__tooltip-trigger')
												]),
											_List_fromArray(
												[icon])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('c-card-secondary__tooltip-content c-tooltip -large')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													translate(blurb))
												]))
										]));
							});
						var _v4 = function () {
							var _v5 = A5($author$project$Data$getGroupClassStatus, external, targetMet, hasCredit, now, groupClass);
							switch (_v5.$) {
								case 3:
									var txt = _v5.a;
									return _Utils_Tuple3(
										A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$author$project$CssUtils$qa('group_class_message')
												]),
											$author$project$HtmlUtils$textHtml(txt)),
										$elm$core$Maybe$Nothing,
										'/assets/img/default-image.png');
								case 1:
									var img_ = _v5.a;
									var cls = _v5.b;
									var txt = _v5.c;
									return _Utils_Tuple3(
										A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$author$project$CssUtils$qa('group_class_message')
												]),
											$author$project$HtmlUtils$textHtml(txt)),
										$elm$core$Maybe$Just(cls),
										img_);
								case 0:
									var img_ = _v5.a;
									var txt = _v5.b;
									return _Utils_Tuple3(
										A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$author$project$CssUtils$qa('group_class_message')
												]),
											$author$project$HtmlUtils$textHtml(txt)),
										$elm$core$Maybe$Nothing,
										img_);
								default:
									var img_ = _v5.a;
									var txt = _v5.b;
									return _Utils_Tuple3(
										A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$author$project$CssUtils$qa('group_class_message')
												]),
											$author$project$HtmlUtils$textHtml(txt)),
										$elm$core$Maybe$Nothing,
										img_);
							}
						}();
						var para = _v4.a;
						var enterableClass = _v4.b;
						var img = _v4.c;
						return _Utils_Tuple2(
							showImg ? $elm$core$Maybe$Just(img) : $elm$core$Maybe$Nothing,
							_List_fromArray(
								[
									(targetMet && hasCredit) ? A2(tooltip, $author$project$Icons$GoalIconAchieved$icon, $author$project$Blurbs$blurbs.aR) : ((hasCredit && (!studyTargets.eg)) ? A2(tooltip, $author$project$Icons$GoalIconNoTarget$icon, $author$project$Blurbs$blurbs.aS) : $author$project$HtmlUtils$empty),
									A2(
									$elm$html$Html$h3,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											translate($author$project$Blurbs$blurbs.ee))
										])),
									para,
									function () {
									if (enterableClass.$ === 1) {
										return A2(
											$author$project$Button$button,
											_List_fromArray(
												[
													$author$project$Button$submit,
													(!_Utils_eq($elm$core$Maybe$Nothing, student.ec.de)) ? $author$project$Button$secondary : $author$project$Button$dark,
													$author$project$Button$disabled(true),
													$author$project$Button$class('c-card-secondary__button'),
													$author$project$Button$qa('enter_group_class')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													translate($author$project$Blurbs$blurbs.a$))
												]));
									} else {
										var cls = enterableClass.a;
										return A2(
											$author$project$Button$button,
											_List_fromArray(
												[
													$author$project$Button$submit,
													(!_Utils_eq($elm$core$Maybe$Nothing, student.ec.de)) ? $author$project$Button$secondary : $author$project$Button$dark,
													$author$project$Button$loading(model.P),
													$author$project$Button$disabled(model.P),
													$author$project$Button$class('c-card-secondary__button'),
													$author$project$Button$onClick(
													$author$project$Data$EnterGroupClass(cls)),
													$author$project$Button$qa('enter_group_class')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													translate($author$project$Blurbs$blurbs.a$))
												]));
									}
								}()
								]));
					}
				}
			},
			model.H);
	});
var $author$project$View$PrivateClass$CtaBookClass = {$: 0};
var $author$project$View$PrivateClass$CtaEnterClass = function (a) {
	return {$: 1, a: a};
};
var $author$project$View$PrivateClass$NoTarget = {$: 2};
var $author$project$View$PrivateClass$TargetMet = {$: 0};
var $author$project$View$PrivateClass$TargetNotMet = function (a) {
	return {$: 1, a: a};
};
var $author$project$Data$PL40 = 0;
var $author$project$Data$getNextPrivateClassStatus = F3(
	function (external, nowPosix, classes) {
		getNextPrivateClassStatus:
		while (true) {
			if (!classes.b) {
				return $author$project$Data$NoPrivateClass;
			} else {
				var _class = classes.a;
				var tail = classes.b;
				var _v1 = A4($author$project$Data$getPrivateClassStatus, external, nowPosix, 0, _class);
				if (_v1.$ === 3) {
					var $temp$external = external,
						$temp$nowPosix = nowPosix,
						$temp$classes = tail;
					external = $temp$external;
					nowPosix = $temp$nowPosix;
					classes = $temp$classes;
					continue getNextPrivateClassStatus;
				} else {
					var status = _v1;
					return status;
				}
			}
		}
	});
var $author$project$View$PrivateClass$content = F3(
	function (external, showImg, model) {
		return A2(
			$krisajenkins$remotedata$RemoteData$map,
			function (data) {
				var _v0 = data.cw;
				if (_v0.$ === 1) {
					return _Utils_Tuple2(
						$elm$core$Maybe$Nothing,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h3,
								_List_fromArray(
									[
										$author$project$CssUtils$qa('private_class_unlicensed')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Unlicensed')
									]))
							]));
				} else {
					var progress = _v0.a.bv;
					var privateClasses = _v0.a.cw;
					var now = $author$project$ServerTime$correctedTime(model.aB);
					var _v1 = external;
					var translate = _v1.fX;
					var student = _v1.fF;
					var _v2 = student;
					var grantsAndCredits = _v2.ec;
					var studyTargets = _v2.fH;
					var grantOrCredit = $author$project$Student$getGrantOrRemainingCredit(grantsAndCredits.fg);
					var isCatalyst = $author$project$Student$hasGrantOrRemainingCredit(student.ec.de);
					var targetStatus = ((studyTargets.fh <= 0) || isCatalyst) ? $author$project$View$PrivateClass$NoTarget : ((_Utils_cmp(progress.dn, progress.fN) > -1) ? $author$project$View$PrivateClass$TargetMet : $author$project$View$PrivateClass$TargetNotMet(progress.fN - progress.dn));
					var tooltip = F2(
						function (icon, blurb) {
							return A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('c-card-secondary__tooltip-w'),
										$author$project$CssUtils$qa('private_class_tooltip')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('c-card-secondary__tooltip-trigger')
											]),
										_List_fromArray(
											[icon])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('c-card-secondary__tooltip-content c-tooltip -large')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												translate(blurb))
											]))
									]));
						});
					var _v3 = function () {
						var _v4 = A3($author$project$Data$getNextPrivateClassStatus, external, now, privateClasses);
						switch (_v4.$) {
							case 3:
								return _Utils_Tuple3(
									function () {
										switch (targetStatus.$) {
											case 0:
												return A2(
													$elm$html$Html$p,
													_List_fromArray(
														[
															$author$project$CssUtils$qa('private_class_message')
														]),
													$author$project$HtmlUtils$textHtml(
														translate($author$project$Blurbs$blurbs.bu)));
											case 1:
												var shortfall = targetStatus.a;
												return (!_Utils_eq(grantOrCredit, $author$project$Student$NoGrantOrCredit)) ? A2(
													$elm$html$Html$p,
													_List_fromArray(
														[
															$author$project$CssUtils$qa('private_class_message')
														]),
													$author$project$HtmlUtils$textHtml(
														A3(
															$author$project$StringUtils$replaceNamedToken,
															'privateClassesRemaining',
															$elm$core$String$fromFloat(shortfall),
															translate($author$project$Blurbs$blurbs.aO)))) : A2(
													$elm$html$Html$p,
													_List_fromArray(
														[
															$author$project$CssUtils$qa('private_class_message')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															translate($author$project$Blurbs$blurbs.bk))
														]));
											default:
												switch (grantOrCredit.$) {
													case 2:
														return A2(
															$elm$html$Html$p,
															_List_fromArray(
																[
																	$author$project$CssUtils$qa('private_class_message')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	translate($author$project$Blurbs$blurbs.bk))
																]));
													case 1:
														var remaining = grantOrCredit.a;
														return A2(
															$elm$html$Html$p,
															_List_fromArray(
																[
																	$author$project$CssUtils$qa('private_class_message')
																]),
															$author$project$HtmlUtils$textHtml(
																A3(
																	$author$project$StringUtils$replaceNamedToken,
																	'privateClassesRemaining',
																	$elm$core$String$fromFloat(remaining),
																	translate($author$project$Blurbs$blurbs.aM))));
													default:
														return A2(
															$elm$html$Html$p,
															_List_fromArray(
																[
																	$author$project$CssUtils$qa('private_class_message')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	translate($author$project$Blurbs$blurbs.aN))
																]));
												}
										}
									}(),
									$author$project$View$PrivateClass$CtaBookClass,
									'/assets/img/default-image.png');
							case 2:
								var img_ = _v4.a;
								var cls = _v4.b;
								var txt = _v4.c;
								return _Utils_Tuple3(
									A2(
										$elm$html$Html$p,
										_List_fromArray(
											[
												$author$project$CssUtils$qa('private_class_message')
											]),
										$author$project$HtmlUtils$textHtml(txt)),
									$author$project$View$PrivateClass$CtaEnterClass(
										$elm$core$Maybe$Just(cls)),
									img_);
							case 1:
								var img_ = _v4.a;
								var txt = _v4.b;
								return _Utils_Tuple3(
									A2(
										$elm$html$Html$p,
										_List_fromArray(
											[
												$author$project$CssUtils$qa('private_class_message')
											]),
										$author$project$HtmlUtils$textHtml(txt)),
									$author$project$View$PrivateClass$CtaEnterClass($elm$core$Maybe$Nothing),
									img_);
							default:
								var img_ = _v4.a;
								var txt = _v4.b;
								return _Utils_Tuple3(
									A2(
										$elm$html$Html$p,
										_List_fromArray(
											[
												$author$project$CssUtils$qa('private_class_message')
											]),
										$author$project$HtmlUtils$textHtml(txt)),
									$author$project$View$PrivateClass$CtaEnterClass($elm$core$Maybe$Nothing),
									img_);
						}
					}();
					var para = _v3.a;
					var cta = _v3.b;
					var img = _v3.c;
					return _Utils_Tuple2(
						showImg ? $elm$core$Maybe$Just(img) : $elm$core$Maybe$Nothing,
						_List_fromArray(
							[
								function () {
								switch (targetStatus.$) {
									case 0:
										return (!_Utils_eq(grantOrCredit, $author$project$Student$NoGrantOrCredit)) ? A2(tooltip, $author$project$Icons$GoalIconAchieved$icon, $author$project$Blurbs$blurbs.aR) : $author$project$HtmlUtils$empty;
									case 1:
										return $author$project$HtmlUtils$empty;
									default:
										return ((!_Utils_eq(grantOrCredit, $author$project$Student$NoGrantOrCredit)) && (!isCatalyst)) ? A2(tooltip, $author$project$Icons$GoalIconNoTarget$icon, $author$project$Blurbs$blurbs.aS) : $author$project$HtmlUtils$empty;
								}
							}(),
								A2(
								$elm$html$Html$h3,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										translate($author$project$Blurbs$blurbs.cw))
									])),
								para,
								function () {
								if (!cta.$) {
									return A2(
										$author$project$Button$button,
										_List_fromArray(
											[
												$author$project$Button$submit,
												$author$project$Button$secondary,
												$author$project$Button$disabled(
												_Utils_eq(grantOrCredit, $author$project$Student$NoGrantOrCredit)),
												$author$project$Button$class('c-card-secondary__button'),
												$author$project$Button$onClick(
												$author$project$Data$BookClass(external.e9.ff)),
												$author$project$Button$qa('book_private_class')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												translate($author$project$Blurbs$blurbs.aL))
											]));
								} else {
									if (cta.a.$ === 1) {
										var _v9 = cta.a;
										return A2(
											$author$project$Button$button,
											_List_fromArray(
												[
													$author$project$Button$submit,
													$author$project$Button$secondary,
													$author$project$Button$disabled(true),
													$author$project$Button$class('c-card-secondary__button'),
													$author$project$Button$qa('enter_private_class')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													translate($author$project$Blurbs$blurbs.a$))
												]));
									} else {
										var cls = cta.a.a;
										return A2(
											$author$project$Button$button,
											_List_fromArray(
												[
													$author$project$Button$submit,
													$author$project$Button$secondary,
													$author$project$Button$loading(model.P),
													$author$project$Button$disabled(model.P),
													$author$project$Button$class('c-card-secondary__button'),
													$author$project$Button$onClick(
													$author$project$Data$EnterPrivateClass(cls)),
													$author$project$Button$qa('enter_private_class')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													translate($author$project$Blurbs$blurbs.a$))
												]));
									}
								}
							}()
							]));
				}
			},
			model.H);
	});
var $author$project$View$Progress$loadingView = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('c-progress is-loading'),
			$author$project$CssUtils$qa('progress_loading')
		]),
	_List_Nil);
var $author$project$Data$GoToProgressAndGoals = {$: 2};
var $author$project$ListUtils$appendIf = F3(
	function (pred, x, xs) {
		return pred ? _Utils_ap(
			xs,
			_List_fromArray(
				[x])) : xs;
	});
var $elm$svg$Svg$Attributes$points = _VirtualDom_attribute('points');
var $elm$svg$Svg$polygon = $elm$svg$Svg$trustedNode('polygon');
var $author$project$Icons$Arrow$icon = A2(
	$elm$svg$Svg$svg,
	_List_fromArray(
		[
			$elm$svg$Svg$Attributes$viewBox('0 0 512 512'),
			$elm$svg$Svg$Attributes$width('13px'),
			$elm$svg$Svg$Attributes$height('13px')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$g,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$polygon,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$points('382.5,223.9 251.6,98.9 295.6,52.4 512,255.8 295.6,459.6 251.6,413.1 382.5,287.9 0,287.9 0,223.9 ')
						]),
					_List_Nil)
				]))
		]));
var $author$project$Link$link = F3(
	function (withArrow, linkText, props) {
		return A2(
			$elm$html$Html$button,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('c-link'),
						$elm$html$Html$Attributes$type_('button')
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var attr = _v0;
						return attr;
					},
					props)),
			A3(
				$author$project$ListUtils$appendIf,
				withArrow,
				$author$project$Icons$Arrow$icon,
				_List_fromArray(
					[linkText])));
	});
var $author$project$Link$LinkProp = $elm$core$Basics$identity;
var $author$project$Link$onClick = function (msg) {
	return $elm$html$Html$Events$onClick(msg);
};
var $author$project$Data$OpenCourseStatusModal = {$: 12};
var $author$project$View$Progress$completionMessage = F2(
	function (_v0, _v1) {
		var translate = _v0.fX;
		var targetCompletionDate = _v1.fO;
		return A3(
			$author$project$StringUtils$replaceNamedToken,
			'courseEndDate',
			targetCompletionDate,
			translate($author$project$Blurbs$blurbs.aY));
	});
var $author$project$Link$qa = A2($elm$core$Basics$composeR, $author$project$CssUtils$qa, $elm$core$Basics$identity);
var $author$project$View$Progress$onTrackProgress = F2(
	function (external, data) {
		var _v0 = external;
		var translate = _v0.fX;
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$author$project$CssUtils$qa('ontrack_progress')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-ontrack__progress'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('-ontrack', (!data.e1) || (data.e1 === 2)),
									_Utils_Tuple2('-notontrack', data.e1 === 1)
								]))
						]),
					_List_fromArray(
						[
							A3(
							$author$project$Link$link,
							false,
							$elm$html$Html$text(
								translate(
									$author$project$Data$progressStatusToBlurb(data.e1))),
							_List_fromArray(
								[
									$author$project$Link$qa('course_status_modal_trigger'),
									$author$project$Link$onClick($author$project$Data$OpenCourseStatusModal)
								]))
						])),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-ontrack__progress-info')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							A2($author$project$View$Progress$completionMessage, external, data))
						]))
				]));
	});
var $author$project$ProgressBar$MiniProgressBar = 2;
var $elm$html$Html$h5 = _VirtualDom_node('h5');
var $author$project$ProgressBar$MediumProgressBar = 1;
var $author$project$ProgressBar$attributesByPropType = function (fn) {
	return A2(
		$elm$core$List$foldr,
		F2(
			function (p, attrs) {
				return A2(fn, p, attrs);
			}),
		_List_Nil);
};
var $author$project$ProgressBar$barProp = F2(
	function (prop, attrs) {
		if (!prop.$) {
			var attr = prop.a;
			return A2($elm$core$List$cons, attr, attrs);
		} else {
			return attrs;
		}
	});
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $author$project$ProgressBar$meterProp = F2(
	function (prop, attrs) {
		if (prop.$ === 1) {
			var attr = prop.a;
			return A2($elm$core$List$cons, attr, attrs);
		} else {
			return attrs;
		}
	});
var $elm$html$Html$Attributes$title = $elm$html$Html$Attributes$stringProperty('title');
var $author$project$ProgressBar$progressBar = F3(
	function (props, mode, percentComplete) {
		var percentClamped = A3($elm$core$Basics$clamp, 0, 100, percentComplete);
		var widthCss = (percentComplete > 0) ? ('calc(' + ($elm$core$String$fromFloat(percentClamped) + '% + 2px)')) : ($elm$core$String$fromFloat(percentClamped) + '%');
		var widthStr = $elm$core$String$fromInt(
			$elm$core$Basics$round(percentClamped)) + '%';
		var invert = percentComplete >= 70;
		var _v0 = function () {
			switch (mode) {
				case 0:
					return _Utils_Tuple2(
						'c-progress-bar',
						$elm$core$Maybe$Just('c-progress-bar__value'));
				case 1:
					return _Utils_Tuple2('c-progress-bar c-progress-bar--medium', $elm$core$Maybe$Nothing);
				default:
					return _Utils_Tuple2('c-progress-bar c-progress-bar--mini', $elm$core$Maybe$Nothing);
			}
		}();
		var barCls = _v0.a;
		var valueCls = _v0.b;
		var valueNode = A2(
			$elm$core$Maybe$withDefault,
			$author$project$HtmlUtils$empty,
			A2(
				$elm$core$Maybe$map,
				function (cls) {
					return A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class(cls),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('-inverted', invert)
									]))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(widthStr)
							]));
				},
				valueCls));
		return A2(
			$elm$html$Html$div,
			_Utils_ap(
				A2($author$project$ProgressBar$attributesByPropType, $author$project$ProgressBar$barProp, props),
				A3(
					$author$project$ListUtils$appendIf,
					(mode === 2) || (mode === 1),
					$elm$html$Html$Attributes$title(widthStr),
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(barCls),
							$author$project$CssUtils$qa('progress_bar')
						]))),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_Utils_ap(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('c-progress-bar__meter'),
								A2($elm$html$Html$Attributes$style, 'width', widthCss),
								$author$project$CssUtils$qa('progress_bar_meter')
							]),
						A2($author$project$ProgressBar$attributesByPropType, $author$project$ProgressBar$meterProp, props)),
					_List_fromArray(
						[
							invert ? valueNode : $author$project$HtmlUtils$empty
						])),
					invert ? $author$project$HtmlUtils$empty : valueNode
				]));
	});
var $author$project$View$Progress$progressSection = F4(
	function (qa, title, progress, progressPct) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-progress__bar'),
					$author$project$CssUtils$qa(qa)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h5,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-progress__bar-title')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						])),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-progress__bar-value')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(progress)
						])),
					A3($author$project$ProgressBar$progressBar, _List_Nil, 2, progressPct)
				]));
	});
var $author$project$View$Progress$progressString = function (progress) {
	return $elm$core$String$fromFloat(progress.dn) + ('/' + $elm$core$String$fromFloat(progress.fN));
};
var $author$project$View$Progress$multiFeatureProgress = F2(
	function (external, data) {
		var selfStudyProg = data.fs.bv;
		var extractLicensedFeature = F2(
			function (feature, target) {
				var _v1 = _Utils_Tuple2(
					feature,
					$elm$core$Basics$round(target));
				_v1$0:
				while (true) {
					if (!_v1.a.$) {
						if (!_v1.b) {
							break _v1$0;
						} else {
							var progress = _v1.a.a.bv;
							return $elm$core$Maybe$Just(progress);
						}
					} else {
						if (!_v1.b) {
							break _v1$0;
						} else {
							var _v2 = _v1.a;
							return $elm$core$Maybe$Nothing;
						}
					}
				}
				return $elm$core$Maybe$Nothing;
			});
		var _v0 = external;
		var translate = _v0.fX;
		var student = _v0.fF;
		var groupProg = A2(extractLicensedFeature, data.ee, student.fH.eg);
		var privateProg = A2(extractLicensedFeature, data.cw, student.fH.fh);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-progress'),
					$author$project$CssUtils$qa('progress')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-progress__overview c-ontrack')
						]),
					_List_fromArray(
						[
							A2($author$project$View$Progress$onTrackProgress, external, data),
							A3(
							$author$project$Link$link,
							true,
							$elm$html$Html$text(
								translate($author$project$Blurbs$blurbs.bP)),
							_List_fromArray(
								[
									$author$project$Link$onClick($author$project$Data$GoToProgressAndGoals),
									$author$project$Link$qa('progress_and_goals_link')
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-progress__bar-w'),
							$author$project$CssUtils$qa('progress_panel')
						]),
					A2(
						$author$project$ListUtils$appendMaybe,
						A2(
							$elm$core$Maybe$map,
							function (gp) {
								return A4(
									$author$project$View$Progress$progressSection,
									'group_class_progress',
									translate($author$project$Blurbs$blurbs.ee),
									$author$project$View$Progress$progressString(gp),
									gp.fa);
							},
							groupProg),
						A2(
							$author$project$ListUtils$appendMaybe,
							A2(
								$elm$core$Maybe$map,
								function (pp) {
									return A4(
										$author$project$View$Progress$progressSection,
										'private_class_progress',
										translate($author$project$Blurbs$blurbs.cw),
										$author$project$View$Progress$progressString(pp),
										pp.fa);
								},
								privateProg),
							_List_fromArray(
								[
									A4(
									$author$project$View$Progress$progressSection,
									'overall_progress',
									translate($author$project$Blurbs$blurbs.e7),
									$elm$core$String$fromFloat(data.e7) + '%',
									data.e7),
									A4(
									$author$project$View$Progress$progressSection,
									'self_study_progress',
									translate($author$project$Blurbs$blurbs.bB),
									$author$project$View$Progress$progressString(selfStudyProg),
									selfStudyProg.fa)
								]))))
				]));
	});
var $author$project$Student$onlySelfStudyTarget = function (_v0) {
	var studyTargets = _v0.fH;
	var _v1 = studyTargets;
	var privateLessons = _v1.fh;
	var groupLessons = _v1.eg;
	var generalEnglishUnits = _v1.d7;
	var industryEnglishUnits = _v1.er;
	var businessEnglishUnits = _v1.db;
	return (privateLessons <= 0) && ((groupLessons <= 0) && (((generalEnglishUnits + industryEnglishUnits) + businessEnglishUnits) >= 0));
};
var $author$project$View$Progress$selfStudyProgress = F2(
	function (external, data) {
		var selfStudyProg = data.fs.bv;
		var _v0 = external;
		var translate = _v0.fX;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-progress'),
					$author$project$CssUtils$qa('progress')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-progress__bar-w -spread'),
							$author$project$CssUtils$qa('progress_panel')
						]),
					_List_fromArray(
						[
							A2($author$project$View$Progress$onTrackProgress, external, data),
							A4(
							$author$project$View$Progress$progressSection,
							'self_study_progress',
							translate($author$project$Blurbs$blurbs.bB),
							$author$project$View$Progress$progressString(selfStudyProg),
							selfStudyProg.fa),
							A3(
							$author$project$Link$link,
							true,
							$elm$html$Html$text(
								translate($author$project$Blurbs$blurbs.bP)),
							_List_fromArray(
								[
									$author$project$Link$onClick($author$project$Data$GoToProgressAndGoals),
									$author$project$Link$qa('progress_and_goals_link')
								]))
						]))
				]));
	});
var $author$project$View$Progress$studyPlanViewProgress = F2(
	function (external, data) {
		return $author$project$Student$onlySelfStudyTarget(external.fF) ? A2($author$project$View$Progress$selfStudyProgress, external, data) : A2($author$project$View$Progress$multiFeatureProgress, external, data);
	});
var $author$project$View$Progress$viewProgress = F2(
	function (external, webData) {
		if (webData.$ === 3) {
			var data = webData.a;
			return $author$project$Student$hasGrantOrRemainingCredit(external.fF.ec.de) ? $author$project$HtmlUtils$empty : A2($author$project$View$Progress$studyPlanViewProgress, external, data);
		} else {
			return $author$project$Student$hasGrantOrRemainingCredit(external.fF.ec.de) ? $author$project$HtmlUtils$empty : $author$project$View$Progress$loadingView;
		}
	});
var $author$project$View$View$allClasses = F2(
	function (external, model) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2($author$project$View$Progress$viewProgress, external, model.H),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('c-homepage__gap-course-header', true),
									_Utils_Tuple2(
									'is-loading',
									_Utils_eq(model.H, $krisajenkins$remotedata$RemoteData$Loading))
								]))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							external.fX($author$project$Blurbs$blurbs.bQ))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-homepage__cards')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-homepage__cards-primary-w')
								]),
							_List_fromArray(
								[
									A2($author$project$View$Card$cardMain, external, model)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-homepage__cards-secondary-w')
								]),
							_List_fromArray(
								[
									A2(
									$author$project$View$Card$cardSecondary,
									false,
									A3($author$project$View$PrivateClass$content, external, false, model)),
									A2(
									$author$project$View$Card$cardSecondary,
									false,
									A3($author$project$View$GroupClass$content, external, false, model))
								]))
						]))
				]));
	});
var $author$project$View$Card$cardSecondaryPlain = function (content) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('c-card-secondary -with-image'),
				$author$project$CssUtils$qa('card_secondary')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('c-card-secondary__img'),
						A2($elm$html$Html$Attributes$style, 'background-image', 'url(/assets/img/default-image.png)')
					]),
				_List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('c-card-secondary__content')
					]),
				content)
			]));
};
var $author$project$View$ProgressAndGoals$content = function (external) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$h3,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text(
					external.fX($author$project$Blurbs$blurbs.bw))
				])),
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text(
					external.fX($author$project$Blurbs$blurbs.by))
				])),
			A2(
			$author$project$Button$button,
			_List_fromArray(
				[
					$author$project$Button$submit,
					$author$project$Button$secondary,
					$author$project$Button$class('c-card-secondary__button'),
					$author$project$Button$onClick($author$project$Data$GoToProgressAndGoals),
					$author$project$Button$qa('go_to_progress_and_goals')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					external.fX($author$project$Blurbs$blurbs.bx))
				]))
		]);
};
var $author$project$View$View$gapNoClasses = F2(
	function (external, model) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('c-homepage__gap-course-header', true),
									_Utils_Tuple2(
									'is-loading',
									_Utils_eq(model.H, $krisajenkins$remotedata$RemoteData$Loading))
								]))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							external.fX($author$project$Blurbs$blurbs.bQ))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-homepage__cards')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-homepage__cards-primary-w')
								]),
							_List_fromArray(
								[
									A2($author$project$View$Card$cardMain, external, model)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-homepage__cards-secondary-w')
								]),
							_List_fromArray(
								[
									$author$project$View$Card$cardSecondaryPlain(
									$author$project$View$ProgressAndGoals$content(external))
								]))
						]))
				]));
	});
var $author$project$View$View$oneClassType = F3(
	function (secondaryCardContent, external, model) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2($author$project$View$Progress$viewProgress, external, model.H),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('c-homepage__gap-course-header', true),
									_Utils_Tuple2(
									'is-loading',
									_Utils_eq(model.H, $krisajenkins$remotedata$RemoteData$Loading))
								]))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							external.fX($author$project$Blurbs$blurbs.bQ))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-homepage__cards')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-homepage__cards-primary-w')
								]),
							_List_fromArray(
								[
									A2($author$project$View$Card$cardMain, external, model)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-homepage__cards-secondary-w')
								]),
							_List_fromArray(
								[
									A2($author$project$View$Card$cardSecondary, true, secondaryCardContent)
								]))
						]))
				]));
	});
var $author$project$View$View$groupClassesOnly = F2(
	function (external, model) {
		return A3(
			$author$project$View$View$oneClassType,
			A3($author$project$View$GroupClass$content, external, true, model),
			external,
			model);
	});
var $author$project$View$View$cardOnTrackContent = function (external) {
	return $krisajenkins$remotedata$RemoteData$map(
		function (data) {
			var targetMet = _Utils_cmp(data.fs.bv.dn, data.fs.bv.fN) > -1;
			var _v0 = external;
			var translate = _v0.fX;
			var onTrackStr = translate(
				$author$project$Data$progressStatusToBlurb(data.e1));
			return _Utils_Tuple2(
				$elm$core$Maybe$Nothing,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h3,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('c-ontrack__progress'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('-ontrack', (!data.e1) || (data.e1 === 2)),
										_Utils_Tuple2('-notontrack', data.e1 === 1)
									]))
							]),
						_List_fromArray(
							[
								A3(
								$author$project$Link$link,
								false,
								$elm$html$Html$text(onTrackStr),
								_List_fromArray(
									[
										$author$project$Link$qa('course_status_modal_trigger'),
										$author$project$Link$onClick($author$project$Data$OpenCourseStatusModal)
									]))
							])),
						targetMet ? A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								A3(
									$author$project$StringUtils$replaceNamedToken,
									'courseEndDate',
									data.fO,
									translate($author$project$Blurbs$blurbs.bJ)))
							])) : A2(
						$elm$html$Html$p,
						_List_Nil,
						$author$project$HtmlUtils$textHtml(
							A2(
								$author$project$StringUtils$replaceAllNamedTokens,
								translate($author$project$Blurbs$blurbs.aK),
								_List_fromArray(
									[
										_Utils_Tuple2('studentStatus', onTrackStr),
										_Utils_Tuple2('courseEndDate', data.fO)
									]))))
					]));
		});
};
var $author$project$View$View$cardProgressContent = function (_v0) {
	var translate = _v0.fX;
	return $krisajenkins$remotedata$RemoteData$map(
		function (data) {
			var progress = data.fs.bv;
			var progressStr = $elm$core$String$fromFloat(progress.dn) + ('/' + $elm$core$String$fromFloat(progress.fN));
			return _Utils_Tuple2(
				$elm$core$Maybe$Nothing,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h3,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								translate($author$project$Blurbs$blurbs.e7))
							])),
						A4(
						$author$project$View$Progress$progressSection,
						'self_study_progress',
						translate($author$project$Blurbs$blurbs.bB),
						progressStr,
						progress.fa),
						A3(
						$author$project$Link$link,
						true,
						$elm$html$Html$text(
							translate($author$project$Blurbs$blurbs.bP)),
						_List_fromArray(
							[
								$author$project$Link$onClick($author$project$Data$GoToProgressAndGoals),
								$author$project$Link$qa('progress_and_goals_link')
							]))
					]));
		});
};
var $author$project$View$View$noClasses = F2(
	function (external, model) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('c-homepage__gap-course-header', true),
									_Utils_Tuple2(
									'is-loading',
									_Utils_eq(model.H, $krisajenkins$remotedata$RemoteData$Loading))
								]))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							external.fX($author$project$Blurbs$blurbs.bQ))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-homepage__cards')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-homepage__cards-primary-w')
								]),
							_List_fromArray(
								[
									A2($author$project$View$Card$cardMain, external, model)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-homepage__cards-secondary-w')
								]),
							_List_fromArray(
								[
									A2(
									$author$project$View$Card$cardSecondary,
									false,
									A2($author$project$View$View$cardOnTrackContent, external, model.H)),
									A2(
									$author$project$View$Card$cardSecondary,
									false,
									A2($author$project$View$View$cardProgressContent, external, model.H))
								]))
						]))
				]));
	});
var $author$project$View$View$privateClassesOnly = F2(
	function (external, model) {
		return A3(
			$author$project$View$View$oneClassType,
			A3($author$project$View$PrivateClass$content, external, true, model),
			external,
			model);
	});
var $author$project$View$View$renderData = F2(
	function (external, model) {
		var isSuccessAndCourseWithoutClasses = function () {
			var _v5 = model.H;
			if (_v5.$ === 3) {
				var data = _v5.a;
				return !data.fs.du;
			} else {
				return false;
			}
		}();
		var isCatalyst = $author$project$Student$hasGrantOrRemainingCredit(external.fF.ec.de);
		if (isSuccessAndCourseWithoutClasses) {
			return A2($author$project$View$View$gapNoClasses, external, model);
		} else {
			var _v0 = _Utils_Tuple2(external.fF.ec.fg, external.fF.ec.ef);
			if (!_v0.a.$) {
				if (!_v0.b.$) {
					return A2($author$project$View$View$allClasses, external, model);
				} else {
					var _v3 = _v0.b;
					return A2($author$project$View$View$privateClassesOnly, external, model);
				}
			} else {
				if (_v0.b.$ === 1) {
					var _v1 = _v0.a;
					var _v2 = _v0.b;
					return isCatalyst ? A2($author$project$View$View$gapNoClasses, external, model) : A2($author$project$View$View$noClasses, external, model);
				} else {
					var _v4 = _v0.a;
					return A2($author$project$View$View$groupClassesOnly, external, model);
				}
			}
		}
	});
var $author$project$StringUtils$stringFromBool = function (value) {
	return value ? 'true' : 'false';
};
var $author$project$View$View$view = F2(
	function (external, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-homepage'),
					A2(
					$elm$html$Html$Attributes$attribute,
					'data-gap-user',
					$author$project$StringUtils$stringFromBool(
						!_Utils_eq($elm$core$Maybe$Nothing, external.fF.ec.de)))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('l-container'),
							$author$project$CssUtils$qa('home-content')
						]),
					_List_fromArray(
						[
							A2($author$project$View$View$renderData, external, model)
						]))
				]));
	});
var $author$project$Main$main = A6($author$project$Scaffold$bootstrap, $author$project$Blurbs$allBlurbs, $author$project$View$View$view, $author$project$HtmlUtils$empty, $author$project$Control$init, $author$project$Control$update, $author$project$Control$subscriptions);
_Platform_export({'Main':{'init':$author$project$Main$main($elm$json$Json$Decode$value)(0)}});}(this));